import nest from "./nest"
import ws from "./ws"
import uav from "./uav"
import airway from "./airway"
import mount from "@/components/observe/mount"
import mqtt from "./mqtt"

export default {
    nest,
    ws,
    uav,
    airway,
    mount,
    mqtt
}