import API from "@/api"

let data_source = null
let point_entity_collection = []

export default {
    async add(options) {
        // if (!options.destination) {
        //     this.$message.warning('请选择目标位置')
        //     return
        // }
        let num = parseInt(JSON.parse(options.flightCourseJson).line.baseSpeed)
        let _this = this
        let situationDetail = this.situation_detail
        let optionsObj = Object.assign({}, options)
        let newData = JSON.parse(optionsObj.flightCourseJson)
        newData.line.baseSpeed = num;
        optionsObj.flightCourseJson = JSON.stringify(newData)
        let {
            status,
            message,
            data
        } = await API.AIRWAY.Add(optionsObj);

        situationDetail.flight_line_id = data
        this.$store.commit("situation/SITUATION_DETAIL", situationDetail)
        if (status == 1) {
            this.$el_message("创建成功", () => {
                this.isAirwayEdit = false
                // 运行监测关闭
                this.powerFlag =true
                this.nest.Videoflag = true
                this.ControlFlag=true
            if(this.$refs.TaskListRef){
                this.$refs.TaskListRef.init()
            }
            });
        } else if (status == 102) {
            this.$confirm('是否申请跨电子围栏空域飞行?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {
                    status
                } = await API.AIRWAY.ApplyFlightLineAuth({
                    id: data.flight_line_span_id,
                    status: 1
                });
                if (status == 0) {
                    this.$message({
                        type: 'success',
                        message: '申请成功!'
                    });
                }
               
            }).catch(async () => {
                let {
                    status
                } = await API.AIRWAY.ApplyFlightLineAuth({
                    id: data.flight_line_span_id,
                    status: 0
                });
                this.airway.add.visible = false
                this.airway_list()
                if (status == 1) {
                    // this.$message('取消申请,航线未规划');
                }
                // if (this.is_from_situation_edit) {
                //     this.airway.add.visible = false
                //     this.change_flightTask_view()
                // }
            });
        } else if (status == 101) {
            this.$message.error(message);
        }
    },
    async init_data_source() {
        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer()
        data_source = new Cesium.CustomDataSource("OBSERVE_KERNEL_AIRWAY_LAYER")
        await viewer.dataSources.add(data_source)
    },

    destroy_data_source() {
        if (data_source) {
            let cesium_layer = this.g_cesium_layer();
            let viewer = cesium_layer.viewer()
            data_source.entities.removeAll()
            viewer.dataSources.remove(data_source, true)
            data_source = null
        }
    },

    drd() {
        return API.NEST.DrdAirway()
    },

    change(airwayId) {
        if (!airwayId) {
            return this.$el_message("请选择航线", null, "error")
        }
        this.ws_send({
            type: 200,
            cmdControlType: 114,
            data: airwayId
        }, true)
    },

    async view(airway) {
        // 重新选择航线的时候，初始化航线上传数据
        this.airway.upload = null

        if (!data_source) {
            await this.airway_init_data_source()
        }

        let data = JSON.parse(airway.flightCourseJson)
        // await API.NEST.GetAirway(airwayId);

        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();

        let entities = data_source.entities
        let positions = Cesium.Cartesian3.fromDegreesArrayHeights(data.points.map(item => [item.lon, item.lat, item.alt]).reduce((total, item) => total.concat(item), []))
        // const position = Cesium.BoundingSphere.fromPoints(positions).center

        let entity_id = 'airway_view_polyline'
        let entity = entities.getById(entity_id)
        if (!entity) {
            let ent = cesium_layer.add_polyline(entities, {
                entity_id,
                positions,
                options: {
                    width: 4,
                    material: Cesium.Color.ORANGE,
                }
            })
        } else {
            entity.polyline.positions = positions
        }

        if (point_entity_collection && point_entity_collection.length) {
            point_entity_collection.forEach(item => {
                entities.remove(item)
            })
        }

        positions.reduce((total, item, index) => {
            let distance = index > 0 ? Cesium.Cartesian3.distance(positions[index - 1], item) : 0
            total += distance
            point_entity_collection.push(entities.add({
                position: item,
                label: {
                    text: `${total.toFixed(2)} m`,
                    font: "12px bold MicrosoftYaHei",
                    fillColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -15)
                },
                point: {
                    show: true,
                    pixelSize: 13,
                    color: Cesium.Color.ORANGE,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    outlineWidth: 0,
                    outlineColor: Cesium.Color.ORANGE,
                }
            }))

            return total
        }, 0)
        viewer.flyTo(data_source)
    },



}