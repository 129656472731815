<template>
  <div class="cpt-player-webrtc">
    <video id="rtc_media_player" ref="webrtc" controls autoplay></video>
  </div>
</template>

<script>
let sdk = null

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      curr_data: {},
    };
  },
  methods: {
    pause(){
      if(this.$refs["webrtc"]){
        this.$refs["webrtc"].pause()
      }
    },
    play(){
  if(this.$refs["webrtc"]){
        this.$refs["webrtc"].play()
      }
    },
    init() {
      let _this = this
      if(this.$refs["webrtc"]) {
       
        let { curr_data } = this;
        if (sdk) {
          sdk.close();
        }
        // eslint-disable-next-line no-undef
        sdk = new SrsRtcPlayerAsync();
        this.$refs["webrtc"].srcObject = sdk.stream;
        sdk.play(curr_data.vUrl).then(function(session){
          console.log(session,111111111111111111);
        }).catch(function (reason) {
          sdk.close();
          _this.init()
        });
      }
    },
  },
  watch: {
    data: {
      handler(value) {
        if (value && value.vUrl) {
          if (this.curr_data.vUrl != value.vUrl) {
            this.curr_data = value;
            this.$nextTick(() => {
              this.init();
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>