<template>
  <div class="cpt-app-uav-list">
    <div class="uav-item-box">
      <div class="uav-item-inner" @click="data.collapse = !data.collapse">
        <div class="title-box">
          <span class="el-icon-caret-right" :class="{ collapse: data.collapse }"></span>
          <img class="level-icon" src="~@/assets/images/uav_item1_1.png" v-if="level === 1" />
          <img class="level-icon" src="~@/assets/images/uav_item2.svg" v-if="level === 2" />
          <img class="level-icon" src="~@/assets/images/uav_item3.svg" v-if="level === 3" />
          <div class="org-name" :title="data.name">{{ data.orgName }}</div>
          <i class="refresh-icon el-icon-refresh-right" v-if="level === 1" @click.stop="$emit('refresh')" />
        </div>
        <div class="online-info">
          （共 {{data.online + data.offline}} 架
          <span
            class="ml10"
            :class="{ online: data.online }"
          >{{ data.online }} 在线</span>
          /
          {{ data.offline }} 离线）
        </div>
      </div>
      <div class="uav-item-child-box" :class="{ collapse: data.collapse }">
        <Item v-for="child in data.child" :key="child.id" :data="child" :level="level+1" />
        <div
          class="device-item-box"
          :class="{ online: device.reportStatus == 1 }"
          v-for="device in data.devices"
          :key="`device_${device.id}`"
        >
           
          <div class="device-name jcsb" >
              <span style="margin-right:10px" :class="device.reportStatus==1&&device.flightState==1? 'blue' : device.reportStatus==1&&device.flightState!=1 ?'yellow':'' "  :title="device.deviceName">{{device.deviceName}}</span>
              <span style="color:#31DB24 " class="dib" v-if=" device.reportStatus == 1 ">(在线)</span>
              <span v-else class="dib">(离线)</span>
              <div class="symbol-icon-box">
              <!-- goodsName: "入云龙II" -->
                <!-- <div
              :title="device.comment||'正常'" 
                v-if="device.state==1"
                style="color:#31DB24"
                class="iconfont icon-yichangliuchengbeifen"
              ></div> -->
              <div class="uav_version status-icon cp" :title="device.goodsName" v-if="['入云龙I', '入云龙II','插翅虎M9'].includes(device.goodsName)">
                <img src="~@/assets/images/I.svg" v-if="device.goodsName=='入云龙I'" />
                <template v-else-if="device.goodsName=='入云龙II'">
                  <img src="~@/assets/images/I.svg"/>
                  <img src="~@/assets/images/I.svg"/>
                </template>
                <img   v-else-if="device.goodsName=='插翅虎M9'" src="~@/assets/images/cq.svg" >
             
              </div>
              <div :title="device.comment||'异常'" v-if="device.state==2" style="color:red" class="status-icon iconfont icon-yichang1"></div>
              <div :title="device.comment||'维修'" v-if="device.state==3" class="status-icon iconfont icon-weixiu"></div>
              <div :title="device.comment||'保养'" v-if="device.state==5" class="status-icon iconfont icon-baoyang"></div>
          </div>
          </div>
          <!-- v-if="device.reportStatus == 1" -->
          <div class="device-fns">
            <!-- <div class="iconfont icon-dingwei" :class="{ active: device._location }" title="定位" @click="fn(1, device)">
            </div>
            <div class="iconfont icon-guiji" :class="{ active: device._airway }" title="轨迹" @click="fn(2, device)">
            </div>-->
            <div
              class="iconfont icon-luxiang_xianxing"
              :class="{ active: device._video }"
              title="视频"
              @click="fn(3, device,orgName=data.orgName)"
            ></div>

            <div
              v-if="device.reportBy !=1"
              class="iconfont icon-kongzhi_xianxing"
              :class="{ active: device._control }"
              title="控制面板"
              @click="fn(4, device,orgName=data.orgName); $store.commit('fckernel/SET_OBSTACLE', null)"
            ></div>
            <div
              class="jieg"
              title="接管无人机  "
              v-if="device.reportBy !=1"
              @click="fn(5, device,orgName=data.orgName)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";

export default {
  name: "Item",
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    level: {
      type: Number,
      default: -1
    }
  },
  inject: ["fn"],
     components: { SymbolIcon },
};
</script>

<style lang="scss" scoped>
.cpt-app-uav-list {
  .uav-item-box {
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #b3bbc5;
    font-weight: 400;

    .uav-item-inner {
      height: 30px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-box {
        display: flex;
        align-content: center;
        align-items: center;

        .el-icon-caret-right {
          cursor: pointer;
          transition: 0.3s;
          transform-origin: center;
          transform: rotate(90deg);
          margin-right: 4px;

          &.collapse {
            transform: rotate(0deg);
          }
        }

        .org-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
        }
      }

      .online-info {
        font-size: 12px;
        white-space: nowrap;

        .online {
          color: #31db24;
        }
      }
    }

    .uav-item-child-box {
      padding-left: 20px;

      max-height: 20000px;
      transition: 0.3s;
      overflow: hidden;
      position: relative;

      &::before {
        content: " ";
        display: block;
        height: 100%;
        width: 1px;
        background-color: #129c9c;
        position: absolute;
        left: 7px;
        top: 0;
      }

      &.collapse {
        max-height: 0;
      }

      .device-item-box {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        transition: 0.2s;

        .device-name {
          width: calc(100% - 150px);
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
        }

        &.online {
          .device-name {
            color: #00ffff;
          }
        }

        .device-fns {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .jieg {
            width: 20px;
            cursor: pointer;
            height: 20px;
            background: url("~@/assets/jieguan.svg") no-repeat;
            background-size: 100% 100%;
          }
          .iconfont {
            font-size: 24px;
            margin-right: 8px;
            cursor: pointer;

            &.active {
              color: #00ffff;
            }
          }
        }
      }
    }
  }
  .goodsName_two{
  background-image: url("~@/assets/images/goodsName_two.svg");
  background-size: 100% 100%; 
  }
    .goodsName_one{
  background-image: url("~@/assets/images/goodsName_one.svg");
  background-size: 100% 100%; 

  }
}
.green{
  color:#31DB24
}
.red{
  color:red
}
.item_items{
  align-items: center;
}
.level-icon{
  width: 16px;
  margin-right: 6px;
}
.refresh-icon{
  width: 16px;
  margin-left: 34px;
}
.jcsb{
  justify-content: left;
}
.status-icon{
  margin-left: 12px;
  font-size: 14px;
  color: RGBA(251, 192, 1, 1);
}
.uav_version{
  width: 14px;
  height: 12px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.symbol-icon-box{
  display: flex;
  align-items: center;

  img {
    height: 8px;
  }
}
</style>