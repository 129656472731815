<!-- 飞行速度 -->
<template lang="">
    <div class="nest_info_maxBox pr">
      <!--  -->
        <img  v-if='offline'  class='w25 h15 pa left10 top5' src='./img/1.png' />
        <div class="infoBox cf">
            <div class="dib title"> {{
                props_obj.relativeAlt ? props_obj.relativeAlt.toFixed(2) : 0
                }}</div>m
            <div class="cf f14"> 飞行高度</div>
        </div>
        <div class="infoBox cf">
            <div class="dib title">  {{
                props_obj.absoluteAlt ? props_obj.absoluteAlt.toFixed(2) : 0
                }}</div>m
            <div class="cf f14"> 海拔高度</div>
        </div>
        <div class="infoBox  cf">
            <div class="dib title "> {{
                props_obj.flyDistance ? props_obj.flyDistance.toFixed(2) : 0
                }}</div>m
            <div class="cf f14"> 飞行里程</div>
        </div>
        <div class="infoBox  cf">
            <div class="dib title "> {{
                uavData&&uavData.distanceToHome ?uavData.distanceToHome.toFixed(2) : 0
                }}</div>m
            <div class="cf f14">起点距离</div>
        </div>
        <div class="infoBox cf">
            <div class="dib title">{{
                props_obj.flyTime ? (props_obj.flyTime / 60).toFixed(2) : 0
                }}</div>min
            <div class="cf f14"> 飞行时间</div>
        </div>
        <div class="infoBox cf">
            <div class="dib title"> {{
                props_obj.groundSpeed ?     uavData.groundSpeed.toFixed(2) : 0
                }}</div>m/s
            <div class="cf f14"> 飞行速度</div>
        </div>
        <div class="infoBox cf">
            <div class="dib title" :style="up ? 'color: #ff2626':'color: #00f5ff'"> {{ props_obj.velocityZ ? props_obj.velocityZ.toFixed(2) : 0 }}</div>m/s
            <div class="cf f14"> 爬升率</div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    uavData: {
      type: Object,
      default: () => ({}),
    },
    offline: {
      type: Boolean,
      default: () => {
        false;
      },
    },
  },
  data() {
    return {
      up: false,
    };
  },
  watch: {
    // 飞行速度检视点
    uavData: function (vla, old) {
      // console.log(vla,'uavDatauavDatauavDatauavDatauavDatauavData');
    },
  },
  computed: {
    props_obj() {
      let { uavData } = this;
      // console.log(uavData,'uavDatauavDatauavData');
      //  uavData.locationCoordinate3D &&
      let flag = false
      if(uavData){
        if(uavData.rtk && uavData.rtk.type == 19 && uavData.rtk.isMainSensor){
          flag = true
        }else{
          flag = false
        }
      }
      if (uavData && uavData.attitude && uavData.locationCoordinate3D) {
        // console.log(uavData,'uavDatauavDatauavData');
        let sleep = 0;
        let x = uavData.velocityX * uavData.velocityX;
        let y = uavData.velocityY * uavData.velocityY;
        let z = uavData.velocityZ * uavData.velocityZ;
        sleep = Math.sqrt(x + y + z);
        if (uavData.velocityZ > 0) {
          this.up = true;
        } else {
          this.up = false;
        }
        let gps = {}
        if(flag){
          gps = uavData.rtk
        }else{
          gps = uavData.gps
        }
        //
        // console.log(uavData.groundSpeed,'``````````````````');
        return {
          ...uavData.attitude,
          ...gps,
          sleep,
          ...uavData.locationCoordinate3D,
          flyDistance: uavData.flyDistance,
          flyTime: uavData.flyTime / 1000,
          velocityX: uavData.velocityX,
          groundSpeed: uavData.groundSpeed,
          velocityY: uavData.velocityY,
          velocityZ: uavData.velocityZ,
        };
      }
      // console.log('返航停了返航停了返航停了返航停了返航停了');
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.nest_info_maxBox {
  width: 100%;
  height: 91px;
  display: flex;
  justify-content: space-around;

  .infoBox {
    text-align: center;
    margin-top: 22px;
    // width: 100px;
    // margin-left: 24px;
    white-space: nowrap;

    .title {
      font-family: UniDreamLED;
      font-size: 20px;
      color: #00f5ff;
      letter-spacing: 0;
      font-weight: 400;
    }
    .f14 {
      white-space: nowrap;
    }
  }
}
</style>