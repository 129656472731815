<template>
  <div :class="{'_box':isStatus}">
    <FloatPlayer
      :class="{'cpt-common-float-playerBox':isStatus}"
      @close="$emit('close')"
      type="8"
      :data="data"
      :device="device"
      :wsData="wsData"
    />
  </div>
</template>

<script>
import FloatPlayer from "@/components/common/float-player";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    device: {
      type: Object,
      default: () => ({})
    },
    wsData:{
      type: Object,
      default:() => {}
    }
  },
  data() {
    return {
      isStatus: false
    };
  },
  components: { FloatPlayer }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cpt-common-float-player {
    top: 0;
  }
}
</style>
<style lang="scss">
</style>