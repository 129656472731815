<!-- 飞控中心鹰巢 -->
<!-- 电池 -->
<template>
  <div class="cpt-observe-mspace-dashboard-battery jcsb" v-if="!wsShow">
    <div
        class="battery-inner pr"
        :class="setClass(index,batteryList.length)"
        v-for="(item,index) in batteryList"
        :key="index"
        :style="batteryList.length>1 && index==1 && batteryList[1].size > 0 ? 'left:-128px':batteryList.length>1 && index==1 && batteryList[1].size == 0  ? 'left:-110px':''"
      >
        <div class="dec mr9 f12">{{index+1}}</div>
        <div class="size-wrap ml20">
          <div class="size-box">
            <div class="size-inner">
              <div class="dianchigai"></div>
              <div
                v-if=" item.statusType !=3"
                class="size-inner-box"
                :style="`height: ${item.size || 0}%; ${setColor(item.size || 0)}`"
              ></div>
            </div>
          </div>
        </div>
        <span
          v-if=" item.statusType !=3"
          class="pa top32 f11 left45 cf"
        >{{item.size || 0}}%</span>
        <!-- <span v-if="ModeStatus =='离线'" class="pa top32 f11 left43 cf">{{"已关机"}}</span> -->
        <div class="ml12">
          <div class="voltage-box">
            <div class="voltage-value f12 ml10 jcsb">
              {{ item.voltage||0 }}
              <span class="cf ml2">v</span>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div v-else>
    <!-- v-if="capacity_percent" -->
    <div  class="mt10">
      <!-- 大疆机库返回的电池数据 -->
      <div
        class="cpt-observe-mspace-dashboard-battery h30 jcsb"
        v-for="(item,index) in capacity_percent"
        :key="index"
      >
        <div class="battery-inner pr" :class="setClass(1,1)">
          <div class="dec mr9 f12">{{item.index + 1}}</div>
          <div class="size-wrap ml20">
            <div class="size-box">
              <div class="size-inner">
                <div class="dianchigai"></div>
                <div
                  class="size-inner-box"
                  :style="`height: ${item.capacity_percent || 0}%; ${setColor(battery.capacity_percent || 0)}`"
                ></div>
              </div>
            </div>
          </div>
          <span class="pa top0 f11 left45 h30 lh30 cf">{{item.capacity_percent || 0}}%</span>
          <!-- <span v-if="ModeStatus =='离线'" class="pa top32 f11 left43 cf">{{"已关机"}}</span> -->
          <div class="ml12">
            <div class="voltage-box">
              <div class="voltage-value f12 ml10 jcsb">
                {{ setvoltage(item.voltage) }}
                <span class="cf ml2">v</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class="cpt-observe-mspace-dashboard-battery jcsb">
      <div class="battery-inner pr" :class="setClass(1,1)">
        <div class="dec mr9 f12">{{1}}</div>
        <div class="size-wrap ml20">
          <div class="size-box">
            <div class="size-inner">
              <div class="dianchigai"></div>
              <div
                class="size-inner-box"
                :style="`height: ${battery.size || 100}%; ${setColor(battery.size || 100)}`"
              ></div>
            </div>
          </div>
        </div>
        <span class="pa top33 f11 left45 cf">{{battery.size || 100}}%</span>
        <div class="ml12">
          <div class="voltage-box">
            <div class="voltage-value f12 ml10 jcsb">
              {{ battery.voltage||0 }}
              <span class="cf ml2">v</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      }
    },
    uavBattery: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      battery: {
        state: { label: "低电量", color: "#f73939" },
        size: 0
      },
      state: {
        1: { label: "满电", color: "#76c230" },
        2: { label: "良好", color: "#FFBD36" },
        3: { label: "低电量", color: "#f73939" }
      },
      // 大疆机库返回的电池数据
      capacity_percent: null,
      batteryList: [
        {
          id: 52,
          size:0
        },
        // {
        //   id: 53,
        //   size: 0
        // }
      ],//default
    };
  },
  methods: {
    setvoltage(val) {
      if (val) {
        return (val / 1000).toFixed(2);
      }
    },
    setColor(size) {
      if (size > 95) {
        return "background-color: #76c230";
      } else if (size > 30) {
        return " background: linear-gradient(to top,yellow,green);";
      } else {
        return "background-color: #f73939";
      }
    },
    setClass(index, num) {
      let str = "";
      if (num > 1 && index == 0) {
        str = str + " " + "top-10 ";
      } else if (num == 1 && index == 0) {
        str = str + " " + "top10";
      }
      if (num > 1 && index == 1) {
        str = str + " " + "top20 ";
      }
      return str;
    }
  },
  mounted() {},
  watch: {
    "$store.state.fckernel.drone_battery_maintenance_info": {
      // 低电量，良好，
      handler(value) {
        if (value) {
          // 如果返回 32767 代表数据获取错误
          if (value[0].capacity_percent != 32767) {
            this.capacity_percent = value;
          } 
          // else {
          //   this.capacity_percent = null;
          // }
        }
      },
      deep: true,
      immediate: true
    },
    uavBattery: {
      // 低电量，良好，
      handler(value) {
        let uavInfo = value;
        let size = null;
        if (!this.wsShow) {
          if (uavInfo.statusType != 3) {
            size = uavInfo.chargeRemaining;
          } else {
            size = "已关机";
          }
          // console.log('电池id：',value.id,'百分比:',size,"%，电压:",uavInfo.voltage.toFixed(1),'v')
          if (uavInfo.voltage) {
            let state = this.state["3"];
            let id = null;
            if (uavInfo.id) {
              id = uavInfo.id;
            }
            if (uavInfo.statusType != 3) {
                if (size > 40) {
                  state = this.state["1"];
                } else if (size <= 40) {
                  state = this.state["3"];
                } else if (size == -1) {
                  this.state(4);
                  size = "已关机";
                }
            } else {
              state = this.state["4"];
            }
            let statusType = uavInfo.statusType;
            let voltage = uavInfo.voltage.toFixed(1);
            let timeRemaining = uavInfo.timeRemaining;
            this.battery = {
              id,
              size,
              state,
              voltage, //: voltage <= 42 ? 42 : voltage,
              statusType,
              timeRemaining,
            };
            if (value.id) {
              if (this.batteryList.length > 0) {
                let arr = [];
                this.batteryList.forEach((val) => {
                  arr.push(val.id);
                });
                if (arr.indexOf(value.id) > -1) {
                  this.batteryList[arr.indexOf(value.id)] = this.battery;
                } else {
                  this.batteryList.push(this.battery);
                }
              } else {
                this.batteryList.push(this.battery);
              }
            } else if (value.id !== undefined) {
              this.batteryList = [];
              // this.batteryList.push(this.battery);
            }
            this.$forceUpdate();
          }
        } else {
          let state = null;
          let id = 52;
          size = uavInfo.battaryRemain;
          if (size > 40) {
            state = this.state["1"];
          } else if (size <= 40) {
            state = this.state["3"];
          }
          let voltage = (uavInfo.voltage && uavInfo.voltage.toFixed(1)) || 0;
          this.battery = {
            id,
            size,
            state,
            voltage //: voltage <= 42 ? 42 : voltage,
          };
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-observe-mspace-dashboard-battery {
  width: 135px;
  height: 100%;
  box-sizing: border-box;

  .battery-inner {
    display: flex;
    align-items: center;
    position: relative;
    .dec {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      line-height: 18px;
      color: #fff;
      border: 1px solid #50abff;
      background: #080c41;
    }

    .voltage-box {
      width: 100%;
      box-sizing: border-box;
      /* padding-left: 30px; */
      text-align: center;

      .voltage-value {
        font-size: 14px;
        color: #93d755;
        font-weight: 400;
        margin-top: 3px;
      }

      .voltage-unit {
        font-family: MicrosoftYaHei;
        font-size: 10px;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
      }
    }

    .status-wrap {
      /* padding-left: 40px; */
      .status-box {
        width: 44px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .size-wrap {
      // margin-top: 11px;
      transform: rotate(90deg);

      /* padding-left: 55px; */
      .size-box {
        box-sizing: border-box;
        width: 25px;
        height: 60px;

        .size-inner {
          margin-top: 8px;
          width: 100%;
          height: calc(100% - 8px);
          border: 1px solid #50abff;
          box-sizing: border-box;
          // border-radius: 4px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 2px;
          position: relative;
          .dianchigai {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 89%;
            height: calc(100% - 3px);
            // border: 1px solid red;
            background-image: linear-gradient(
              to right,
              #fff -14%,
              transparent 41%
            );
            // border-radius: 3px;
          }
          &::before {
            content: " ";
            display: block;
            position: absolute;
            top: -5px;
            left: 50%;
            width: 10px;
            height: 4px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border: 1px solid #50abff;
            border-bottom: 0px;
            transform: translateX(-50%);
          }

          .size-inner-box {
            width: 100%;
            // border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>