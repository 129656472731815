<!-- 总览页视频 -->
<template>
  <div class="cpt-player-webrtc">
    <video
      ref="webrtc"
      :controls="controls"
    >
      <source />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    controls: {
      type: Boolean,
      default: () => true
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      player: null,
      value: "",
      times: 0
    };
  },
  watch: {
    data: {
      handler(value) {
        if (value ) {
          this.$nextTick(() => {
            this.player = new JSWebrtc.Player(value.vUrl, {
              video: this.$refs["webrtc"],
              autoplay: true
            });
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handlerClick() {
      let video = this.$refs["webrtc"];
      if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
      } else if(video.requestFullscreen){
       video.requestFullscreen();
      }else if(video.mozRequestFullScreen)
      setTimeout(() => {
          video.play();
        }, 200);
    },
    exit() {
      let video = this.$refs["webrtc"];

      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    
      setTimeout(() => {
        video.play();
      }, 200);
    },
    init() {
      this.player = null;
      console.log(this.data);
      if (this.data && this.data.vUrl) {
        this.$nextTick(() => {
          this.player = new JSWebrtc.Player(this.data.vUrl, {
            video: this.$refs["webrtc"],
            autoplay: true
          });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  pointer-events: none;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: relative;

    .title {
      position: absolute;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #69ceff;
      letter-spacing: 1px;
      text-align: center;
      font-weight: 700;
      height: 42px;
      line-height: 42px;
      position: relative;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
    }
  }
}
</style>