import nest from "./nest"
import ws from "./ws"
import uav from "./uav"
import airway from "./airway"
import mqtt from "./mqtt"


function prefix(meta, prefix) {
    return Object.keys(meta).reduce((obj, key) => {
        obj[`${prefix}_${key}`] = meta[key]
        return obj
    }, {})
}

export default {
    ...prefix(nest, "nest"),
    ...prefix(ws, "ws"),
    ...prefix(uav, "uav"),
    ...prefix(airway, "airway"),
    ...prefix(mqtt, "mqtt"),

}