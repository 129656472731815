<template>
  <div class="page-observe-nest_v2">
    <!-- 头部 -->
    <Header
      :device="nest.data"
      :uav-data="uav.data"
      :wsShow="wsShow"
      :level="level"
      @nest_chenge="nestchange"
      @control_ws="uav_control_ws"
      @changeF="$emit('changeF')"
      :isSelectFlag="flag"
      :uav-battery="uav.battery"
      @fn="(data, type) => mqtt_fn(data, type)"
      :nest-data="nest.jcData"
      @ModeStatus="getModeStatus"
    ></Header>
    <div class="uav_yc_video" :class="{ collapse_video: collapseUavFlag }">
      <PlayerOuter
        v-if="nest.Videoflag && !flag"
        :ref="
          mapisStatus.type === 'yc' && mapisStatus.flag && mapisStatus.num == 0
            ? 'FloatPlayer2'
            : 'FloatPlayer1'
        "
        :class="
          mapisStatus.type === 'yc' && mapisStatus.flag && mapisStatus.num == 0
            ? 'full-screen-video _in_video'
            : 'left-video-uav'
        "
        @fn="(data, type) => mqtt_fn(data, type)"
        @imgUrl="getimgUrl"
        :scheduleData="scheduleData"
        :device="nest.data"
        :lineLableName="lineLableName"
        @AIDialog="(e, val) => getAIDialog(e, val)"
        @directive="mqtt_move_directive"
        @fun="(data) => uav_send_ws(data)"
        @close="uav_video_close('out')"
        :data="uav.video.out"
        :uavData="uav.data"
      />
    </div>
    <div
      class="nsetLeftBox"
      v-if="!isAirwayEdit"
      :class="{ w460: flag, collapse: collapseFlag }"
    >
      <img
        @click="collapseFlagfn"
        v-if="collapseFlag"
        style="transform: rotate(180deg)"
        src="~@/assets/images/observe/fckernel/hs.png"
        class="icon-collapse nsetLeftBox_btn"
        :class="nest.Videoflag && !flag ? 'nestbtn' : ''"
      />
      <img
        @click="collapseFlagfn"
        v-else
        src="~@/assets/images/observe/fckernel/collapse.png"
        class="icon-collapse nsetLeftBox_btn"
        :class="nest.Videoflag && !flag ? 'nestbtn' : ''"
      />
      <!-- 鹰巢列表 -->
      <NestList
        v-show="flag"
        @close="closeFn"
        v-model="nest.selected"
        :async-list="nest_list"
        :list="nest.list"
        @change="nest_change"
        @item-location="nest_location"
      />

      <!-- 展示视频 -->
      <div class="videouav" v-if="nest.Videoflag && !flag">
        <div class="left-video">
          <div class="left-video-header">
            <div class="changelist" @click="getnestList">鹰巢列表</div>
            <div class="nest-name">
              <span class="nest-name__text">{{ nest.data.name }}</span>
              <span class="nest-name__text">{{ nest.data.name }}</span>
            </div>
          </div>
          <div class="left-video-nest">
            <PlayerInner
              class="fl"
              v-show="!mapisStatus.flag || mapisStatus.num != 1"
              @close="uav_video_close('in')"
              v-if="uav.video.in"
              :device="nest.data"
              :data="uav.video.in"
              :wsData="uav.data"
            />
            <div v-else />
            <PlayerUav
              class="fr"
              v-show="!mapisStatus.flag || mapisStatus.num != 2"
              v-if="uav.video.uav"
              @close="uav_video_close('uav')"
              :data="uav.video.uav"
              :device="nest.data"
              :wsData="uav.data"
            />
            <div v-else />
          </div>
        </div>
      </div>
      <div class="xing" v-if="yun == true">
        <!-- 运行监测 -->
        <Power
          v-if="powerFlag && !flag && !wsShow"
          :wrjType="uav.wrjType"
          :jc-data="nest.jcData"
          :qxz-data="nest.qxzData"
          :nest-data="nest.jcData"
        />
        <PowerDJ
          v-if="powerFlag && !flag && wsShow"
          :wrjType="uav.wrjType"
          :jc-data="nest.jcData"
          :qxz-data="nest.qxzData"
          :nest-data="nest.jcData"
        />
      </div>
    </div>
    <!-- 全屏视频 start   -->
    <!-- <PlayerOuter
      ref="FloatPlayer2"
      class="full-screen-video _in_video"
      @imgUrl="getimgUrl"
      @fn="(data, type) => mqtt_fn(data, type)"
      :device="nest.data"
      @AIDialog="
        (e, val) => getAIDialog(e,val)
      "
      @fun="(data) => uav_send_ws(data)"
      @directive="mqtt_move_directive"
      v-if="uav.video.out&&
        nest.Videoflag &&
        mapisStatus.type === 'yc' &&
        mapisStatus.flag &&
        mapisStatus.num == 0
      "
      @close="uav_video_close('out')"
      :data="uav.video.out"
      :uavData="uav.data"
    /> -->
    <PlayerInner
      class="full-screen-video _in_video"
      :device="nest.data"
      @directive="mqtt_move_directive"
      v-if="uav.video.in"
      v-show="
        nest.Videoflag &&
        mapisStatus.type === 'yc' &&
        mapisStatus.flag &&
        mapisStatus.num == 1
      "
      @close="uav_video_close('in')"
      :data="uav.video.in"
      :wsData="uav.data"
    />
    <PlayerUav
      class="full-screen-video _in_video"
      :device="nest.data"
      @directive="mqtt_move_directive"
      v-if="uav.video.uav"
      v-show="
        nest.Videoflag &&
        mapisStatus.type === 'yc' &&
        mapisStatus.flag &&
        mapisStatus.num == 2
      "
      @close="uav_video_close('uav')"
      :data="uav.video.uav"
      :uavData="uav.data"
    />
    <!-- 全屏视频 end -->

    <div class="xing" v-if="yun == true">
      <!-- 运行监测 -->
      <Power
        v-if="powerFlag && !flag && !wsShow"
        :wrjType="uav.wrjType"
        :jc-data="nest.jcData"
        :qxz-data="nest.qxzData"
        :nest-data="nest.jcData"
      />
      <PowerDJ
        v-if="powerFlag && !flag && wsShow"
        :wrjType="uav.wrjType"
        :jc-data="nest.jcData"
        :qxz-data="nest.qxzData"
        :nest-data="nest.jcData"
      />
    </div>
    <Tools
      ref="ToolsRef"
      v-show="false"
      :video="nest.video"
      @video-play="uav_video_play"
    />
    <!-- 日志信息 -->
    <Logger
      :wrjType="uav.wrjType"
      :flag="flag"
      :jc-data="nest.jcData"
      @exit="loggerFlag = false"
      v-if="loggerFlag && !wsShow"
      :list="nest.msg_list"
      @clear-msg="nest.msg_list = []"
      @step="stepShow = true"
    ></Logger>
    <Step v-if="stepShow" :data="nest.data" @close="stepShow = false" />
    <!-- 鹰巢气温，湿度，烟感信息展示 -->
    <Temp
      :device="{ deviceName: nest.data.name }"
      :uav-data="uav.data"
      v-if="!nest.flag"
      :weather-data="nest.weather"
    />

    <!--底部数据状态栏 -->
    <Control
      :wsShow="wsShow"
      :data="uav.channelData"
      v-if="nest.data && ControlFlag"
      :uav-battery="uav.battery"
      :offline="uav.offline"
      :uav-data="uav.data"
      @fn="(data, type) => mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
    ></Control>
    <VideoTomap v-if="!flag" @showVideo="changeBtn(true)"></VideoTomap>
    <!--创建航线-->
    <AirwayEdit
      :data="nest.data"
      :flag="true"
      @add="airway_add"
      v-if="isAirwayEdit"
      :isShow="false"
      @quit="AirwayQuit"
    ></AirwayEdit>
    <!-- 任务列表-->
    <!-- @airway-display="uav_airway" -->
    <TaskList
      @Lsdom="Lsdom"
      @taskType="taskTypeFn"
      @iconShow="iconShow = true"
      :wsShow="wsShow"
      :data="nest.data"
      @changeLableName="(e) => (lineLableName = e)"
      ref="TaskListRef"
      @airway-display="uav_airway"
      @fn="(data, type) => mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
      @airway-view="airway_view"
      @craeteRoute="CraeteRoute"
      v-if="nest.controlMenuFlag"
      @createTaskClick="createTaskClick"
      @closeflyLog="loggerFlag = false"
      @close="(e) => (loggerFlag = e)"
    ></TaskList>
    <!-- 右边菜单栏 -->
    <ControlMenu
      @changeStatus="changeStatus"
      @Lsdom="Lsdom"
      :wsShow="wsShow"
      :keyFlag="keyFlag"
      :device="nest.data"
      :healthData="healthData"
      @clearId="uav_clearIdFn"
      @uav-location="uav_location"
      @imgUrl="getimgUrl"
      :uavData="uav.data"
      :batteryData="uav.battery"
      @airway-display="uav_airway"
      :taskType="taskType"
      :wrjType="uav.wrjType"
      :jcData="nest.jcData"
      :mount="mount"
      :offline="uav.offline"
      :uav-mounts="uav.mounts"
      :uav-mounts-payload="uav.mounts_payload"
      @yxrz="loggerFlag = !loggerFlag"
      :iconShow="iconShow"
      @fn="(data, type) => mqtt_fn(data, type)"
      @fun="(data) => uav_send_ws(data)"
      v-if="nest.data"
      @changeTask="onChangeTask"
    ></ControlMenu>
    <!-- 创建定时任务 -->
    <CreateTask
      @fn="(data, type) => mqtt_fn(data, type)"
      :CreateTaskType="CreateTaskType"
      v-if="CreateTaskFlag"
      @exit="exitTaskList()"
    ></CreateTask>
    <el-dialog
      title
      :visible.sync="endRenwu"
      width="30%"
      style="margin-top: 20vh"
    >
      <div class="endrenwu">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">检测到无人机即将到达站点， 是否需要结束任务？</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="endRenwu = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="queding">确定</div>
        </div>
      </div>
    </el-dialog>
    <div class="imgshowurl" v-if="imgshow">
      <div @click="imgshow = false" class="close">关闭</div>
      <div class="imgae">
        <canvas
          v-if="aiType >= 4 || aiType == 1 || aiType == 2 || aiType == 3"
          id="canvas"
          ref="canvas"
        ></canvas>
        <div v-else>
          <img :src="imgshowurl" />
        </div>
        <div class="aitotal" v-if="aiType == 3">发现人员：{{ aiTotal }}人</div>
      </div>
    </div>
    <Face :list="faceList" v-if="faceShow" @close="faceShow = false" />
    <Car :list="carList" v-if="carShow" @close="carShow = false" />
    <div class="swim" v-if="AIFlag">
      <div class="head">
        <div class="close" @click="AIFlag = false">关闭</div>
      </div>
      <div class="content">{{ aiTitle || "暂无" }}</div>
      <div class="btns">
        <div class="btn" @click="AIFlag = false">取消</div>
        <div class="btn rest" @click="resetSwim">继续飞行</div>
      </div>
    </div>
    <!-- <P3 ref="P3" class='mt200 mr200' v-show="p3Flag" v-if="nest.data&&nest.data.deviceList[0].deviceHardId" :PoliceKeyCode="nest.data.deviceList[0].deviceHardId" />  -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Face from "../face";
import Car from "../car";
import P3 from "@/components/PagerP1_New";
import AirwayEdit from "./components/airway-edit";
import Header from "./components/header/index";
import TaskList from "./components/taskList/index";
import NestList from "./components/list/index";
import Power from "./components/power/index";
import PowerDJ from "./components/power/index_DJ";
import Tools from "./components/tools";
import Temp from "./components/temp";
import Control from "./components/control/index";
import API from "@/api";
import ControlMenu from "./components/control/controlMenu/index";
import CreateTask from "./components/control/createTask";
import VideoTomap from "./components/control/videoTomap";
import Logger from "./components/control/logger/index";
import Step from "./components/control/step";
import PlayerInner from "./components/uav/video/inner";
import PlayerOuter from "./components/uav/video/outer";
import PlayerUav from "./components/uav/video/uav";
import { mapGetters } from "vuex";
import data from "./data";
import bus from "@/utils/bus";
import methods from "./methods";
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  components: {
    Face,
    Car,
    P3,
    Header,
    NestList,
    Power,
    PowerDJ,
    Tools,
    Temp,
    PlayerInner,
    PlayerOuter,
    PlayerUav,
    Control,
    Logger,
    Step,
    AirwayEdit,
    TaskList,
    ControlMenu,
    CreateTask,
    VideoTomap,
  },
  data() {
    return {
      ...data,
      collapseUavFlag: false,
      level: null,
      wsShow: false, //ws控制还是mqtt控制
      scheduleData: {}, //航线执行进度
      p3Flag: false,
      healthData: {},
      healthWaring: [],
      message: {},
      faceShow: false,
      faceList: [],
      carShow: false,
      carList: [],
      imgshow: false, //拍照图片展示
      imgshowurl: null,
      lineLableName: "", //当前航线标签
      AIFlag: false, //识别弹窗
      aiTitle: "", //ai信息
      aiTotal: 0,
      aiType: 0,
      endRenwu: false,
      flag: true, //true为展示鹰巢列表， false为展示鹰巢详情
      // 日志
      loggerFlag: false,
      //流程
      stepShow: false,
      // 运行监测
      powerFlag: true,
      // 创建航线窗口
      isAirwayEdit: false,
      // 创建任务
      CreateTaskFlag: false,
      // 左下角切换视频
      VideoTomapFlag: false,
      // 下方展示数据面板
      ControlFlag: true,
      iconShow: false,
      // 是否展示一键任务按钮 当 值为itemB 时不展示
      taskType: "",
      Model: "",
      CreateTaskType: "",
      collapseFlag: false,
      UAVvideo: false, //飞控 鹰巢 无人机视频刷新
      count: 0, // 飞控 鹰巢 刷新计数
      videoCount: 0, //飞控 鹰巢 刷新计数
      oneClicktask: false, //一键任务
      ShutdownCount: 0, //关机计时
      yun: false, // 运行状态开关
      keyFlag: false, //NX状态
      nxFlagData: null,//推流状态
      ModeStatus:null
    };
  },
  provide() {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
        }
      },
      mqtt_fn: this.mqtt_fn,
      uav: this.uav,
    };
  },
  watch: {
    healthData: function (newval, old) {
      this.healthWaring = [];
      if (JSON.stringify(old) == "{}") {
        for (let i in newval) {
          if (newval[i].warningLevel != "NORMAL") {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              deviceId: this.nest.data.deviceList[0].deviceId,
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i,
            };
            if (i != "OBS" && i != "CHUTE") {
              if (
                newval[i].warningLevel == "WARNING" ||
                newval[i].warningLevel == "SERIOUS_WARNING"
              ) {
                let arr = [];
                this.healthWaring.push(newval[i].title);
                for (let i in newval) {
                  arr.push(newval[i].warningLevel);
                }
                let headwaring = arr.some((val) => val != "NORMAL");
                this.$emit("healthWaringShow", this.healthWaring, headwaring);
              }
            }
            this.addMessage();
          }
        }
      }
      let arrKey = [];
      for (let i in newval) {
        arrKey.push(i);
        if (newval[i].warningLevel != "NORMAL") {
          if (old[i] && newval[i].code != old[i].code) {
            if (this.taskId) {
              this.message.taskId = this.taskId;
            }
            this.message = {
              deviceId: this.nest.data.deviceList[0].deviceId,
              exceptionTitle: newval[i].title,
              exceptionTypeSign: i,
            };
            this.addMessage();
          }
          if (i != "OBS" && i != "CHUTE") {
            if (
              newval[i].warningLevel == "WARNING" ||
              newval[i].warningLevel == "SERIOUS_WARNING"
            ) {
              this.healthWaring.push(newval[i].title);
            }
          }
        }
        let arr = [];
        for (let i in newval) {
          arr.push(newval[i].warningLevel);
        }
        let headwaring = arr.some((val) => val != "NORMAL");
        this.$emit("healthWaringShow", this.healthWaring, headwaring);
      }
      let flage = arrKey.some((val) => val == "NX");
      if (flage && newval["NX"].warningLevel == "NORMAL") {
        this.keyFlag = true;
      } else {
        this.keyFlag = false;
      }
      // 保存鹰巢无人机nx状态
      this.$store.commit("fckernel/set_state", {
        key: "nest_nx_status",
        value: this.keyFlag,
      });
    },
    "uav.data": {
      handler(uavData, oldVal) {
        if (!this.wsShow) {
          let { modeList } = this.uav;
          // 飛行模式
          let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
          // 离鹰巢距离
          let home = uavData?.distanceToHome?.toFixed(2);
          // 飞行高度
          try {
            let flag = false;
            if (uavData) {
              if (
                uavData.rtk &&
                uavData.rtk.type == 19 &&
                uavData.rtk.isMainSensor
              ) {
                flag = true;
              } else {
                flag = false;
              }
            }
            let gps = {};
            if (flag) {
              gps = uavData.rtk;
            } else {
              gps = uavData.gps;
            }
            let relativeAlt = gps?.relativeAlt.toFixed(2);
            let waypointList = JSON.parse(
              sessionStorage.getItem("waypointList")
            );
            // 当飞行模式为返航模式时，并且 飞行高度 低于一米、记录home点 距离低于一米 且有执行的任务 ，就提示是否需要结束任务
            if (
              flightMode?.label == "返航模式" &&
              relativeAlt < 0.2 &&
              home < 1 &&
              waypointList
            ) {
              this.endRenwu = true;
            }
          } catch (error) {}
        } else {
          if (uavData) {
            // 飛行模式
            let flightMode = uavData.flightMode;
            // 离鹰巢距离
            let home = uavData.distanceToHome.toFixed(2);
            // 飞行高度
            try {
              let relativeAlt = uavData.height.toFixed(2);
              let waypointList = JSON.parse(
                sessionStorage.getItem("waypointList")
              );
              // console.log(flightMode,home,relativeAlt,waypointList,"大疆");
              // 当飞行模式为返航模式时，并且 飞行高度 低于一米、记录home点 距离低于一米 且有执行的任务 ，就提示是否需要结束任务
              if (
                flightMode == "降落模式" &&
                relativeAlt < 2 &&
                home < 5 &&
                waypointList
              ) {
                this.endRenwu = true;
              }
            } catch (error) {}
          }
        }
      },
      deep: true,
    },

    "nest.data": {
      handler(newVal, oldVal) {
        this.uav_closeIconShow();
        this.lineLableName = "";
        this.yun = false;
        if (newVal) {
          this.$store.commit("device/SET_VIDEO", true);
          if (newVal.firmId == 2) {
            this.wsShow = true;
            this.uav_init_ws();
            this.mqtt_init();
          } else {
            this.wsShow = false;
            this.mqtt_init();
          }
        } else {
          //关闭旧飞机的mqtt连接
          this.uav.ws && this.uav.ws.close();
          this.uav.ws = null;
          this.mqtt_close();
          this.$store.commit("device/SET_VIDEO", false);
        }
      },
      deep: true,
    },
    "uav.channelData": {
      handler(value) {},
      deep: true,
    },
    collapseFlag(newVal) {
      // 搜索关键字：飞控鹰巢左侧任务框位置判定(侧边栏关闭状态)
      Bus.$emit("close_ce", newVal);
      if (
        this.$store.state.mapmanage.mapisStatus.flag &&
        this.$store.state.mapmanage.mapisStatus.type == "yc"
      ) {
        if (newVal) {
          this.$store.commit("mapmanage/SET_YC_MAP_COLLAPSE", newVal);
        } else {
          setTimeout(() => {
            this.$store.commit("mapmanage/SET_YC_MAP_COLLAPSE", newVal);
          }, 200);
        }
      }
    },
    flag(val) {
      if (val) {
        this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
          flag: false,
          type: "yc",
        });

        Bus.$emit("videos_flag", false);
      }
      // 搜索关键字：飞控鹰巢左侧任务框位置判定
      Bus.$emit("ren_task", val);
    },
    UAVvideo: function (vla, old) {
      // 监听mqtt的值，有258则表示开机
      if (vla == true) {
        console.log("已接收258");
        // 刷新视频
        // 关键字：视频刷新
        Bus.$emit("flushed_result");
      } else {
        Bus.$emit("flushed_result");
      }
    },
    // 鹰巢一键任务触发无人机定位
    "$store.state.fckernel.nset_fk_location"(val) {
      if (val) {
        console.log("触发无人机定位");
        this.uav_nset_fk_locationFn();
      }
    },
    ModeStatus: function (val) {
      if (val == "离线") {
        this.closeVideo();
      }
    },
    deep: true,
  },
  props: {
    weather: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["user_info", "situation_detail", "mapisStatus"]),
    taskId() {
      return this.$store.state.fckernel.taskId;
    },
    active_nestData() {
      // 1 代表ktb 2 代表大疆
      let data = this.$store.state.fckernel.active_nestData;
      if (data) {
        return data;
      }
      return null;
    },
  },
  mounted() {},
  methods: {
    ...methods,
    closeVideo() {
      this.$parent.close()
      this.healthData = {};
      this.uav.control.mounts = [];
      this.$emit("healthWaringShow", [], false);
    },
    getModeStatus(val) {
      // console.log(val,"ModeStatus");
      this.ModeStatus = val;
    },
    changeStatus() {
      this.$refs.TaskListRef.taskCate = 3;
    },
    getAIDialog(e, val) {
      this.AIFlag = e;
      this.aiTitle = val;
      setTimeout(() => {
        this.resetSwim();
      }, 3000);
      // this.$refs.P3.$refs.ItemA.text="盐城公安提醒您不私自下水游泳不擅自与他人结伴游泳不在无成年人带领的情况下游泳不到无安全设施无救援人员的水域游泳不到不熟悉的水域游泳不擅自下水施救"
      // this.$refs.P3.$refs.ItemA.handleSendTTSText()
    },
    //检测
    resetSwim() {
      let data = {
        data: "AUTO_MISSION",
        type: 513,
      };
      // this.mqtt_fn(data, "wrj");
      this.AIFlag = false;
      this.imgshow = false;

      if (!this.mapisStatus.flag || this.mapisStatus.num != 0) {
        this.$refs["FloatPlayer1"].detectionSwim(this.lineLableName);
      }
      if (
        this.nest.Videoflag &&
        this.mapisStatus.type === "yc" &&
        this.mapisStatus.flag &&
        this.mapisStatus.num == 0
      ) {
        this.$refs["FloatPlayer2"].detectionSwim(this.lineLableName);
      }
      // console.log(this.$refs["FloatPlayer"]);
      this.AIFlag = false;
    },
    getcanvas(val, item) {
      let data = null;
      if (item.jsonData) {
        data = JSON.parse(item.jsonData);
      } else {
        data = item;
      }
      // let targets = data.targets;
      setTimeout(() => {
        this.canvas = this.$refs.canvas;
        this.canvas.width = 1000;
        this.canvas.height = 500;
        this.image = new Image();
        this.image.src = val;
        this.image.onload = () => {
          if (this.aiType >= 4) {
            this.drawImage(data.targets);
          } else if (this.aiType == 1) {
            this.drawImage(data.detections);
          } else if (this.aiType == 2) {
            this.drawImage(data.aiPlateShiJuResultInfoDtoList);
          } else if (this.aiType == 3) {
            this.drawImage(data);
          }
        };
      }, 50);
    },
    drawImage(list) {
      this.ctx = this.canvas.getContext("2d");
      this.ctx.drawImage(
        this.image,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "red";
      if (this.aiType >= 4) {
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.bbox.box.left_top_x) /
            this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.bbox.box.left_top_y) /
            this.image.height;
          plate_detection.h =
            (this.canvas.height *
              (targets.bbox.box.right_bottom_y - targets.bbox.box.left_top_y)) /
            this.image.height;
          plate_detection.w =
            (this.canvas.width *
              (targets.bbox.box.right_bottom_x - targets.bbox.box.left_top_x)) /
            this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.8)";
          let text =
            targets.bbox.label +
            ":" +
            (targets.bbox.prob * 100).toFixed(2) +
            "%（相似度）";
          let offX = 0;
          let offY = 0;
          if (plate_detection.x + text.length * 11 > 1000) {
            offX = 1000 - text.length * 11;
          } else {
            offX = plate_detection.x - 5;
          }
          if (plate_detection.y - 20 < 0) {
            offY = plate_detection.y + plate_detection.h;
          } else {
            offY = plate_detection.y - 22;
          }
          this.ctx.fillRect(
            // plate_detection.x - 5,
            offX,
            offY,
            text.length * 11,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "16px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText(
            text,
            // plate_detection.x - 5,
            offX,
            offY + 15
          ); // 绘制文本
        });
      } else if (this.aiType == 1) {
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.x) / this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.y) / this.image.height;
          plate_detection.h =
            (this.canvas.height * targets.h) / this.image.height;
          plate_detection.w =
            (this.canvas.width * targets.w) / this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.5)";
          this.ctx.fillRect(
            plate_detection.x - 5,
            plate_detection.y - 22,
            20,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "12px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText("人", plate_detection.x - 3, plate_detection.y - 8); // 绘制文本
        });
      } else if (this.aiType == 2) {
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.yiSaPlateDetection.x) /
            this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.yiSaPlateDetection.y) /
            this.image.height;
          plate_detection.h =
            (this.canvas.height * targets.yiSaPlateDetection.h) /
            this.image.height;
          plate_detection.w =
            (this.canvas.width * targets.yiSaPlateDetection.w) /
            this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.5)";
          this.ctx.fillRect(
            plate_detection.x - 5,
            plate_detection.y - 22,
            60,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "12px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText(
            targets.plateNum,
            plate_detection.x - 3,
            plate_detection.y - 8
          ); // 绘制文本
        });
      } else if (this.aiType == 3) {
        list.forEach((targets) => {
          let plate_detection = {
            x: 0,
            y: 0,
            h: 0,
            w: 0,
          };
          plate_detection.x =
            (this.canvas.width * targets.bbox[0]) / this.image.width;
          plate_detection.y =
            (this.canvas.height * targets.bbox[1]) / this.image.height;
          plate_detection.h =
            (this.canvas.height * (targets.bbox[3] - targets.bbox[1])) /
            this.image.height;
          plate_detection.w =
            (this.canvas.width * (targets.bbox[2] - targets.bbox[0])) /
            this.image.width;

          this.ctx.strokeRect(
            plate_detection.x,
            plate_detection.y,
            plate_detection.w,
            plate_detection.h
          );
          this.ctx.fillStyle = "rgba(0,0,0,0.5)";
          this.ctx.fillRect(
            plate_detection.x - 5,
            plate_detection.y - 22,
            20,
            20
          );
          this.ctx.fillStyle = "white"; // 设置文本颜色
          this.ctx.font = "16px Arial"; // 设置文本字体大小和样式
          this.ctx.fillText("人", plate_detection.x - 3, plate_detection.y - 8); // 绘制文本
        });
      }
    },
    exitTaskList() {
      this.nest.controlMenuFlag = true;
      setTimeout(() => {
        this.$refs.TaskListRef.changeBtn("itemB");
        this.CreateTaskFlag = false;
      }, 0);
    },
    async addMessage() {
      let res = await API.FCKERNEL.exceptionAdd(this.message);
    },
    getnestList() {
      this.flag = true;
      this.nest_list();
    },
    async getimgUrl(val, aiType, item) {
      this.aiType = aiType;
      this.imgshow = true;
      this.imgshowurl = val;
      //人流
      if (aiType == 3) {
        this.aiTotal = item.detectPersonCount;
        this.getcanvas(val, item);
      }
      //游泳
      if (aiType >= 4) {
        this.getcanvas(val, item);
      }
      //人脸
      if (aiType == 1 || aiType == 2) {
        let data = {
          fileKey: item.visitUrl,
          aiType: aiType,
        };
        let res = await API.FCKERNEL.getFaceResultByFileKey(data);
        if (aiType == 1) {
          this.faceList = res.faceImageInfo?.faceResults;
          this.faceShow = true;
          this.getcanvas(val, res.faceImageInfo);
        } else {
          this.carList = res.aiPlateShiJuResultInfoDtoList;
          this.carShow = true;
          this.getcanvas(val, res);
        }
      }
    },

    // 结束任务确认框，确定结束任务
    async queding() {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (waypointList) {
        // 结束任务
        API.DEVICE.task_update({
          id: waypointList.flyTypeId,
          status: 4,
          deviceHardId: waypointList.uavDeviceId,
        });
        // this.$emit("fn", { type: 525 }, "wrj");
        // 飞控结束任务
        this.mqtt_fn(
          {
            type: 769,
          },
          "wrj"
        );
        // 修改鹰巢绑定任务
        API.FCKERNEL.updateTaskAndUserRelation({
          taskId: waypointList.flyTypeId,
        });
        this.uav_clearIdFn();
        sessionStorage.removeItem("waypointList");

        // this.$message.success("操作成功");
      } else {
        this.$message.warning("暂无飞行任务");
      }
      this.uav_clearIdFn();
      this.endRenwu = false;
    },
    //点击任务按钮
    onChangeTask(open) {
      if (open === undefined) {
        this.nest.controlMenuFlag = !this.nest.controlMenuFlag;
      } else {
        this.nest.controlMenuFlag = open;
      }
    },
    ...mapMutations("device", ["TOOLBAR_IS_SHOW", "IS_SHOW_FACTOR_ICONS"]),
    taskrenwu() {
      // 一键任务显示模型和轨迹
      // 关键词：一键任务
      // 调用显示无人机轨迹和定位的方法
      this.uav_one_click_task();
    },
    collapseFlagfn() {
      // 列表页面 关闭时 this.collapseFlag=false 并且 flag 是ture 代表视频页面没出来
      if (this.nest.Videoflag && this.flag) {
        this.flag = false;
        this.collapseFlag = false;
        if (!this.$store.state.mapmanage.mapisStatus.flag) {
          this.collapseUavFlag = false;
        }
        // flag flase 代表是视频详情页面
      } else if (this.nest.Videoflag && !this.flag) {
        this.collapseFlag = !this.collapseFlag;
        if (!this.$store.state.mapmanage.mapisStatus.flag) {
          this.collapseUavFlag = !this.collapseUavFlag;
        }
      } else {
        this.collapseFlag = !this.collapseFlag;
        this.collapseUavFlag = !this.collapseUavFlag;
      }
      // console.log(this.collapseFlag,"collapseFlag");
      // this.$store.commit("fckernel/SET_NEST_COLLAPSE", this.collapseFlag);
    },
    closeFn() {
      this.flag = false;
    },
    taskTypeFn(item) {
      this.taskType = item;
    },
    Lsdom(item) {
      this.$emit("Lsdom", item);
    },
    // 关闭航线创建列表
    AirwayQuit() {
      this.isAirwayEdit = false;
      // 运行监测关闭
      this.powerFlag = true;
      this.nest.Videoflag = true;
      this.ControlFlag = true;
      this.loggerFlag = true;
      this.collapseFlag = false;
      this.collapseUavFlag = false;
    },
    createTaskClick(item) {
      this.CreateTaskType = item;
      this.CreateTaskFlag = !this.CreateTaskFlag;
      if (!this.CreateTaskFlag) {
        this.loggerFlag = true;
      } else {
        this.loggerFlag = false;
      }
      this.nest.controlMenuFlag = false;
    },
    // 是否展示鹰巢
    nestchange(flag) {
      this.flag = flag;
      if (this.flag) {
        this.loggerFlag = false;
      }
      this.nest.controlMenuFlag = false;
    },
    // 创建航线
    CraeteRoute() {
      this.nest.controlMenuFlag = false;
      this.isAirwayEdit = true;
      // 运行监测关闭
      this.powerFlag = false;
      this.nest.Videoflag = false;
      this.ControlFlag = false;
      this.loggerFlag = false;
      this.collapseFlag = true;
      this.collapseUavFlag = true;
    },
    // 开关视频
    changeBtn(flag) {
      if (flag) {
        this.nest.Videoflag = true;
        this.uav_video_play("in");
        this.uav_video_play("uav");
        this.uav_video_play("out");
      } else {
        this.nest.Videoflag = false;
        this.uav_video_close("in");
        this.uav_video_close("uav");
        this.uav_video_close("out");
      }
    },
    auxiliary(data, type) {
      this.mqtt_fn(data, type);
    },
  },
  provide() {
    return {
      nest_this: this,
      async_nest_list: this.nest_list,
      nest_list: () => this.nest.list,
      nest_selected: () => this.nest.selected,
      async_airway_drd: this.airway_drd,
    };
  },
  inject: ["g_cesium_layer"],
  async created() {
    // 运行状态检测
    Bus.$on("yunxing", (status) => {
      if (status == true) {
        this.yun = true;
      } else {
        this.yun = false;
      }
    });
    await this.airway_init_data_source();
    // 清除轨迹模型
    // 关键词：飞控鹰巢任务结束
    Bus.$on("closeIconShow", () => {
      this.uav_closeIconShow();
    });
    // 一键任务显示模型和轨迹
    // 关键词：一键任务
    // 被调用的方法：oneClicktask变为true
    Bus.$on("onetask", () => {
      this.oneClicktask = true;
    });
    // 运行状态检测
    Bus.$on("yunxing", (status) => {
      if (status == true) {
        this.yun = true;
      } else {
        this.yun = false;
      }
    });
  },
  beforeDestroy() {
    this.healthWaring = [];
    this.healthData = {};
    this.$emit("healthWaringShow", this.healthWaring, false);
    this.airway_destroy_data_source();
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    this.ws_destroy();
    this.nest.data = null;
    sessionStorage.removeItem("waypointList");
    this.loggerFlag = false;
    this.powerFlag = true;
    this.isAirwayEdit = false;
    this.CreateTaskFlag = false;
    this.VideoTomapFlag = false;
    this.ControlFlag = true;
    this.iconShow = false;
    this.powerFlag = false;
    this.nest.Videoflag = false;
    this.nest.controlMenuFlag = false;
    this.nest.flag = true;
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: false,
      type: "yc",
    });
    Bus.$emit("videos_flag", false);
  },
};
</script>
<style scoped lang="scss">
.collapse {
  transform: translateX(-100%);
  transition: 0.3s;
}
.collapse_video {
  z-index: 11;
  transform: translateX(-380px);
  transition: 0.3s;
}
.uav_yc_video {
  width: 382px;
  margin-top: 17px;
  position: absolute;
  top: 720px;
  // z-index: 11;
  transition: 0.3s;
  left: 15px;
  .left-video-uav::v-deep {
    width: 382px;
    position: absolute;
    z-index: 11;
    // top: 730px;

    .cpt-common-float-player {
      .title-box {
        top: 0;

        .cfc {
          font-size: 12px !important;
        }
      }

      .box {
        width: auto;
        top: -35px;
        position: relative;

        .video-box {
          width: auto;
        }
      }

      .reset {
        bottom: 48px;
        right: 1px;
      }
      .infop {
        bottom: 48px;
      }
    }
  }
}
.nsetLeftBox {
  position: absolute;
  left: 0;
  top: 60px;
  height: 87vh;
  width: 382px;
  transition: 0.3s;
  border-radius: 10px 10px 0px 0px;
  z-index: 10;

  // background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70daf9;
  .nsetLeftBox_btn {
    position: absolute;
    cursor: pointer;
    right: -22px;
    top: 42%;
    margin-top: -90px;
    width: 22px;
    height: 72px;
    z-index: 99;
  }

  .nestbtn {
    top: 530px;
  }

  .videouav {
    width: 382px;
    height: 386px;
    position: absolute;
    left: 0px;
    top: 308px;
    z-index: 10;
    .left-video {
      width: 382px;
      height: 386px;
      position: absolute;
      // left: 0px;
      // top: 308px;
      padding: 48px 14px 16px;
      box-sizing: border-box;
      background: rgba(12, 34, 73, 0.7);
      border-radius: 10px;
      border: 1px solid rgba(26, 92, 246, 0.5);
      z-index: 10;

      .left-video-header {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 32px;
        background-image: linear-gradient(
          180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.22) 40%,
          #05091a 100%
        );
        border-radius: 10px 10px 0 0;
        // border: 1px solid #70daf9;
        box-shadow: inset 0 0 10px 2px #3f9dff;
        box-sizing: inset 0px 0px 10px 2px #3f9dff;
        background: url("~@/assets/img/line.png") no-repeat;
        background-position: 60%;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #70daf9;
        display: flex;
        // justify-content: center;
        align-items: center;
      }

      .left-video-nest::v-deep {
        // display: flex;
        box-sizing: border-box;

        & > div {
          position: relative;
          flex: 1;
        }

        & * {
          min-width: initial;
          min-height: initial;
        }

        .cpt-common-float-player {
          position: static;
          width: 170px;
          height: 100px;
          bottom: 0;

          .title-box {
            top: 0;

            .cfc {
              font-size: 12px !important;
            }
          }

          .box {
            width: auto;
            top: -35px;
            position: relative;

            .video-box {
              width: auto;
            }
          }

          .vjs-control {
            width: 34px;
          }

          .reset {
            bottom: 21px;
            right: 17px;
          }
        }
      }
    }
  }

  .videoBox {
    width: 350px;
    height: 65vh;
    position: absolute;
    left: 0px;
    top: 190px;
  }
}

.acitve {
  color: #70daf9;
}

.changeBtn {
  position: absolute;
  right: calc(50% - 350px);
  width: 140px;
  height: 40px;
  z-index: 10;
  background: center
    url("~@/assets/images/observe/fckernel/nest/btnDefault.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -8px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #70f6f9;
}

.changeBtn:hover {
  background: center
    url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
  background-size: contain;
  background-position-y: -8px;
  background-repeat: no-repeat;
  font-size: 14px;
  color: #ffe9ae;
  letter-spacing: 0;
  font-weight: 700;
}

.full-screen-video {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 50px;
  z-index: 9;

  ::v-deep .cpt-common-float-player {
    top: 24px !important;
  }
}

.xing {
  position: fixed;
  right: 460px;
  top: 12%;
}

.changelist {
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 16px;
  width: 86px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  background: url("~@/assets/img/nestlist.png");
  color: #fff;
}

.nest-name {
  margin-left: 30px;
  margin-right: 20px;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;

  .nest-name__text {
    display: inline-block;
    white-space: nowrap;
    animation: 15s scrollTitle linear infinite;
    min-width: 100%;
    padding-right: 50px;
    box-sizing: border-box;
    font-family: YouSheBiaoTiHei;
    background-image: -webkit-linear-gradient(
      right,
      #e3aa77,
      #f5cda9,
      #f9ecd3,
      #fcdbb1,
      #edb07a
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-weight: 100;
  }
}

@keyframes scrollTitle {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9;
  background: rgba(9, 32, 87, 0.7);
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}

.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}

.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}

/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}
.imgshowurl {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .image {
    position: relative;
  }
  .aitotal {
    // margin-left: 16px;
    // width: 1000px;
    padding: 4px 8px;
    line-height: 30px;
    position: absolute;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
  }
  img {
    width: 1000px;
    height: 600px;
  }
  .close {
    color: #fff;
    margin-left: 1000px;
    margin-bottom: 16px;
    // position: absolute;
    // right: 10px;
    // top: 10px;
  }
}
._in_video {
  /deep/ .cpt-common-float-player {
    width: 100% !important;
    position: fixed !important;
    top: 50px !important;
    right: 0 !important;
    z-index: 0 !important;
  }
  /deep/.vjs-big-play-button {
    display: none !important;
  }
  /deep/.video-box {
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
  }
  /deep/.title-box {
    width: 63%;
    padding-right: 20px;
    margin-left: 480px;
  }
  /deep/ .iconBG {
    right: 300px;
    height: 35px !important;
    padding-top: 5px;
    position: fixed;
    bottom: 5%;
    padding-top: 5px;
    border-radius: 20px;
    width: 80px;
    display: flex;
    justify-content: space-evenly;
    // background-image: url("./img/bg.png") !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background: #409eff;
    .reset {
      width: 18px;
      height: 18px;
      margin-top: 7px;
      background: url("./img/img3.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 0px;
    }
    .infop {
      width: 20px;
      height: 20px;
      z-index: 4;
      margin-left: 0px;
      margin-top: 7px;
      background: url("./img/img2.png") !important;
      background-size: cover !important;
      background-repeat: no-repeat;
    }
    .infop:hover {
      transform: scale(1.5);
    }
    .reset:hover {
      transform: scale(1.5);
    }
  }
}
.swim {
  padding: 10px;
  width: 531px;
  min-height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  // background: rgba(50, 2, 2, 0.7);
  background: url("~@/assets/images/warn/yj.png") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .head {
    display: flex;
    justify-content: end;
    color: #fff;
    .close {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .btns {
    align-self: center;
    display: flex;

    .btn {
      background: rgba(53, 3, 3, 0.25);
      box-shadow: inset 0 0 20px 0 rgba(255, 101, 56, 0.65);
      border-radius: 5px;
      color: #fff;
      padding: 10px;
    }
    .rest {
      margin-left: 20px;
    }
  }
}
</style>
