<template>
  <div>
    <div class="cpt-observe-nest-power">
      <!-- <div class="hd pr">
        <div class="f20 pa left60 fw700 top7 cf">{{active? '运行监测':'运行状态'}}</div>
        <div class="pa right0 h44 lh44 cp tc cf yxztDefault"  @click="active=!active">
          {{!active? '运行监测':'运行状态'}}</div>
      </div>-->
      <div class="titleBox">
        <div class="tab-active">{{ !active ? "运行监测" : "运行状态" }}</div>
        <div class="tab-no-active" @click="active = !active">{{ active ? "运行监测" : "运行状态" }}</div>
      </div>
      <div class="power-list wih100 mt14" v-if="active">
        <template v-for="item in list">
        <!-- {{item.status_map}} -->
          <!-- <el-tooltip
            :content="item.status_map[item.status]&&item.status_map[item.status].title"
            placement="top"
            :key="item.id"
          > -->
            <div class="power-item" :style="
                item.status_map[item.status] &&
                item.status_map[item.status].style
              "
              :key="item.id"
            >
              <div class="icon-box">
                <div :class="item.status_map[item.status]&&item.status_map[item.status].icon"></div>
              </div>
              <div class="text-box">{{ item.label }}</div>
            </div>
          <!-- </el-tooltip> -->
        </template>
      </div>
      <div class="power-list f14 power-list--monitor" v-if="!active">
        <!-- <div class="list grayscale">
          <div class="tc w50 h50">
            <span style="opacity:0">{{rtkPower}}</span>
            <SymbolIcon :icon="rtkType[rtkPower].data" />
            <div class="cf">{{ rtkType[rtkPower]&&rtkType[rtkPower].title }}</div>
          </div>
          <div class="tc w50 h50">
            <SymbolIcon :icon="wrjType.code == 10000 ? 'zhunfei2' : 'jinfei'" />
            <div class="cf">{{ wrjType.code == 10000 ? "准飞" : "禁飞" }}</div>
          </div>
          <div class="tc w50 h50">
            <SymbolIcon :icon="ycType[cover].data" />
            <div class="cf">{{ ycType[cover].title }}</div>
          </div>
          <div class="tc w50 h50">
            <SymbolIcon :icon="jjType[folder].data" />
            <div class="cf">{{ jjType[folder].title }}</div>
          </div>
          <div class="tc w50 h50">
            <SymbolIcon :icon="sjType[lifts].data" />
            <div class="cf">{{ sjType[lifts].title }}</div>
          </div>
        </div> -->
        <div class="wih100 mt10">
          <div class="wih100">
            <div class="jcsb wih100">
              <div class="power-list-data-item">
                <div class>
                  <span>内温</span>
                  <span class="cfc power-list-data-num">{{ hangarTemp }}</span>
                </div>
                <div class="power-list-data-progress">
                  <el-progress
                    class
                    :show-text="false"
                    :text-inside="true"
                    :percentage="hangarTemp"
                  ></el-progress>
                </div>
              </div>
              <div class="power-list-data-item">
                <div class>
                  <span>外温</span>
                  <span style="color: #ffbd36" class="power-list-data-num">{{ outhangarTemp }}</span>
                </div>
                <div class="power-list-data-progress">
                  <el-progress
                    class
                    :show-text="false"
                    :text-inside="true"
                    color="#FFBD36"
                    :percentage="jcData ? jcData.environment_temperature : 0"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
          <div class="wih100 mt20">
            <div class="jcsb wih100">
              <div class="power-list-data-item">
                <div class>
                  <span>内湿度</span>
                  <span style="color: #ffbd36" class="power-list-data-num">
                    {{
                    hangarHumid
                    }}
                  </span>
                </div>
                <div class="power-list-data-progress">
                  <el-progress
                    class="w120 h12"
                    color="#FFBD36"
                    :show-text="false"
                    :text-inside="true"
                    :percentage="hangarHumid"
                  ></el-progress>
                </div>
              </div>
              <div class="power-list-data-item">
                <div class>
                  <span>外湿度</span>
                  <span class="cfc power-list-data-num">
                    {{
                    qxzData.humidity || 0
                    }}
                  </span>
                </div>
                <div class="power-list-data-progress">
                  <el-progress
                    class="w120 h12"
                    :show-text="false"
                    :text-inside="true"
                    :percentage="qxzData ? qxzData.humidity : 0"
                  ></el-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/observe/fckernel/common/dialog";
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";

export default {
  props: {
    nestData: {
      type: Object,
      default: () => ({})
    },
    jcData: {
      type: Object,
      default: () => ({})
    },
    qxzData: {
      type: Object,
      default: () => ({})
    },
    wrjType: {
      type: Object,
      default: () => ({})
    }
  },
  components: { Dialog, SymbolIcon },
  data() {
    return {
      active: true,
      rtkType: [
        { data: "jizhanweiqiyong", title: "未知" },
        { data: "jizhanqiyong", title: "启用" },
        { data: "jizhanweiqiyong", title: "关闭" }
      ],

      ycType: [
        { data: "guanbi1", title: "未知" },
        { data: "guanbi1", title: "关闭" },
        { data: "dakai", title: "打开" },
        { data: "guanbi1", title: "正在关闭" },
        { data: "dakai", title: "正在打开" }
      ],
      jjType: [
        { data: "fangsong1", title: "未知" },
        { data: "fangsong1", title: "加紧" },
        { data: "yundong", title: "放松" },
        { data: "fangsong1", title: "夹紧过程" },
        { data: "yundong", title: "放松过程" }
      ],
      // lifts
      sjType: [
        { data: "weizhi", title: "未知" },
        { data: "xiajiang", title: "降下" },
        { data: "shangsheng", title: "升起" },
        { data: "xiajiang", title: "降下过程" },
        { data: "shangsheng", title: "升起过程" }
      ],
      list: [
        {
          id: 1,
          label: "鹰巢",
          prop: "hangarPower",
          status: 0,
          status_map: {
            // 0: {
            //   icon: "iconfont icon-weizhi",
            //   style: { color: "#eee", opacity: 0.3 },
            //   title: "未知",
            // },
            0: {
              icon: "iconfont icon-dianyuan1",
              style: { color: "#43DEFF", opacity: 1 },
              title: "打开"
            }
            // 2: {
            //   icon: "iconfont icon-dianyuan1",
            //   style: { color: "#FFBD36" },
            //   title: "关闭",
            // },
          }
        },
        // {
        //   id: 2,
        //   label: "射频",
        //   prop: "rF_Power",
        //   status: 0,
        //   status_map: {
        //     0: {
        //       icon: "iconfont icon-weizhi",
        //       style: { color: "#eee", opacity: 0.3 },
        //       title: "未知"
        //     },
        //     1: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#43DEFF", opacity: 1 },
        //       title: "打开"
        //     },
        //     2: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#FFBD36" },
        //       title: "关闭"
        //     }
        //   }
        // },
        {
          id: 3,
          label: "舱外摄像头",
          prop: "outCameraPower",
          status: 0,
          status_map: {
            // 0: {
            //   icon: "iconfont icon-weizhi",
            //   style: { color: "#eee", opacity: 0.3 },
            //   title: "未知",
            // },
            0: {
              icon: "iconfont icon-dianyuan1",
              style: { color: "#43DEFF", opacity: 1 },
              title: "打开"
            }
            // 2: {
            //   icon: "iconfont icon-dianyuan1",
            //   style: { color: "#FFBD36" },
            //   title: "关闭",
            // },
          }
        },
        // {
        //   id: 4,
        //   label: "舱内摄像头",
        //   prop: "inCameraPower",
        //   status: 0,
        //   status_map: {
        //     0: {
        //       icon: "iconfont icon-weizhi",
        //       style: { color: "#eee", opacity: 0.3 },
        //       title: "未知"
        //     },
        //     1: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#43DEFF", opacity: 1 },
        //       title: "打开"
        //     },
        //     2: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#FFBD36" },
        //       title: "关闭"
        //     }
        //   }
        // },
        // {
        //   id: 5,
        //   label: "RTK电源",
        //   prop: "rTK_Power",
        //   status: 0,
        //   status_map: {
        //     0: {
        //       icon: "iconfont icon-weizhi",
        //       style: { color: "#eee", opacity: 0.3 },
        //       title: "未知"
        //     },
        //     1: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#43DEFF", opacity: 1 },
        //       title: "打开"
        //     },
        //     2: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#FFBD36" },
        //       title: "关闭"
        //     }
        //   }
        // },
        // {
        //   id: 6,
        //   label: "UAV充电器",
        //   prop: "drone_charge_state",
        //   status: 0,
        //   status_map: {
        //     0: {
        //       icon: "iconfont icon-weizhi",
        //       style: { color: "#eee", opacity: 0.3 },
        //       title: "未知"
        //     },
        //     1: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#43DEFF", opacity: 1 },
        //       title: "打开"
        //     },
        //     2: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#FFBD36" },
        //       title: "关闭"
        //     }
        //   }
        // },
        // {
        //   id: 7,
        //   label: "内部灯",
        //   prop: "inLamp",
        //   status: 0,
        //   status_map: {
        //     0: {
        //       icon: "iconfont icon-weizhi",
        //       style: { color: "#eee", opacity: 0.3 },
        //       title: "未知"
        //     },
        //     1: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#43DEFF", opacity: 1 },
        //       title: "打开"
        //     },
        //     2: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#FFBD36" },
        //       title: "关闭"
        //     }
        //   }
        // },
        // {
        //   id: 8,
        //   label: "外部灯",
        //   prop: "supplement_light_state",
        //   status: 0,
        //   status_map: {
        //     0: {
        //       icon: "iconfont icon-weizhi1",
        //       style: { color: "#eee", opacity: 0.3 },
        //       title: "未知"
        //     },
        //     1: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#43DEFF", opacity: 1 },
        //       title: "打开"
        //     },
        //     2: {
        //       icon: "iconfont icon-dianyuan1",
        //       style: { color: "#FFBD36" },
        //       title: "关闭"
        //     }
        //   }
        // }
      ]
    };
  },
  mounted() {},
  computed: {
    rtkPower() {
      let { jcData } = this;
      if (jcData.rtkPower) {
        return jcData.rtkPower;
      }
      return 0;
    },
    folder() {
      let { jcData } = this;
      if (jcData.folder) {
        return jcData.folder;
      }
      return 0;
    },
    lifts() {
      let { jcData } = this;
      if (jcData.lifts) {
        return jcData.lifts;
      }
      return 0;
    },
    cover() {
      let { jcData } = this;
      if (jcData.cover) {
        return jcData.cover;
      }
      return 0;
    },
    env_temp() {
      let { qxzData } = this;
      return qxzData ? qxzData.temp : 0;
    },
    // 内温度
    hangarTemp() {
      let { jcData } = this;
      if (jcData.temperature) {
        return jcData.temperature;
      }
      return 0;
    },
    outhangarTemp() {
      let { jcData } = this;
      if (jcData.environment_temperature) {
        return jcData.environment_temperature;
      }
      return 0;
    },
    hangarHumid() {
      let { jcData } = this;
      if (jcData.humidity) {
        return jcData.humidity;
      }
      return 0;
    },
    weather_temp() {
      let { qxzData } = this;
      return qxzData ? qxzData.weatherTemp : 0;
    },
    env_humid() {
      let { qxzData } = this;
      return qxzData ? qxzData.humidity : 0;
    }
  },
  watch: {
    nestData: {
      handler(value) {
        this.list.forEach(item => {
          let prop = item.prop;
          if (prop) {
            if (prop == "drone_charge_state") {
              item.status = 0;
            } else {
              item.status = value && value[prop] ? value[prop] : 0;
            }
          }
        });
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-observe-nest-power {
  position: absolute;
  width: 382px;
  height: 203px;
  left: 0px;
  top: 110px;
  background: rgba(12, 34, 73, 1);
  border-radius: 10px;
  border: 1px solid raba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  // border: 1px solid #70daf9;
  z-index: 2;
  .titleBox {
    // background: rgba(4, 18, 50, 0.5);
    position: relative;
    color: #fff;
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 700;
    height: 40px;
    background-image: url("~@/assets/images/nest/power_tabs.svg");
    background-size: 100% 90%;
    background-position: 0px 5px;
    background-repeat: no-repeat;

    .tab-active {
      color: #fff;
      font-size: 19px;
      position: absolute;
      left: 71px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .tab-no-active {
      color: rgb(188, 233, 254);
      font-size: 17px;
      font-weight: 100;
      position: absolute;
      right: 8px;
      bottom: 0;
      width: 164px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("~@/assets/images/nest/power_tab.svg");
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .fz {
    transform: rotate(180deg);
  }

  .hd {
    background: center url("~@/assets/images/observe/fckernel/nest/yxjcbg.png");
    background-size: contain;
    height: 44px;

    .yxztDefault {
      width: 200px;
      background: center
        url("~@/assets/images/observe/fckernel/nest/yxztDefault.png");
      background-repeat: 100% 100%;
      background-repeat: no-repeat;
      background-position-x: 30px;
    }

    .yxztDefault:hover {
      width: 200px;
      background: center
        url("~@/assets/images/observe/fckernel/nest/yxztSelect.png");
      background-repeat: 100% 100%;
      background-repeat: no-repeat;
      background-position-x: 30px;
    }
    .yxztDefault:hover {
      width: 200px;
      background: center
        url("~@/assets/images/observe/fckernel/nest/yxztSelect.png");
      background-repeat: 100% 100%;
      background-repeat: no-repeat;
      background-position-x: 30px;
    }
    .title-box {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #43deff;
      letter-spacing: 0;
      font-weight: 700;
      line-height: 44px;
      margin-left: 20px;
    }
  }

  .power-list {
    display: flex;
    flex-wrap: wrap;
    // padding: 18px;

    &.power-list--monitor {
      padding: 28px 18px 0;
    }

    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .power-item {
      color: #19e210;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 25%;

      .icon-box {
        width: 22px;
        height: 22px;
        overflow: hidden;
        margin-bottom: 4px;

        .iconfont {
          font-size: 22px;
        }
      }

      .text-box {
        font-family: MicrosoftYaHei;
        font-size: 14px;

        font-weight: 400;
      }
    }

    .power-list-data-item {
      display: flex;
      flex-direction: column;
      color: #fff;
      font-size: 12px;

      .power-list-data-num {
        margin-left: 6px;
      }
    }

    .power-list-data-progress {
      margin-top: 4px;
      width: 124px;
    }
  }
}
</style>
