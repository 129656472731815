<template>
  <div class="cpt-nest-list-item wih100">
    <div class="nest-group_box">
      <div class="group-title_box">
        <div class="title-box">
          <span :title="data.name" class="title">{{ data.name }}</span>
        </div>
        <div class="line-box w210">
          ( <span class="cf"> 共 {{ data.online + data.offline }} 架 </span>
          <span class="healthy--un ml5" style="color: #31db24"
            >{{ data.online }} 架在线
          </span>
          <span>/</span>
          <span class="healthy--total" style="color: #cad8d9"
            >{{ data.offline }} 离线</span
          >)
        </div>
      </div>
    </div>

    <div class="nest-children" :class="{ collapse: data.collapse }">
      <template v-if="data && data.nestList && data.nestList.length">
        <div class="nest-device-list">
          <div
            v-for="device in data.nestList"
            :key="`device_${device.id}`"
            class="nest-device-item"
            :class="{ online: device.online === 1 }"
          >
            <!-- 最前面的选项框 -->
            <div class="title-box">
              <el-tooltip
                :content="device.online === 1 ? '在线' : '离线'"
                placement="top"
                :enterable="false"
              >
                <el-checkbox
                  :disabled="device.online !== 1"
                  :value="device.changestatus"
                  @change="(e) => handClick(e, device)"
                >
                </el-checkbox>
              </el-tooltip>
              <span :title="device.name" class="title">{{ device.name }}</span>
              <span class="li" v-if="device.online != 1">(离线)</span>
              <span class="zai" v-else>(在线)</span>
              <span
                :title="device.comment || '异常'"
                v-if="device.state == 2"
                style="color: red"
                class="status-icon iconfont icon-yichang1"
              ></span>
              <span
                :title="device.comment || '维修'"
                v-if="device.state == 3"
                class="status-icon iconfont icon-weixiu"
              ></span>
              <span
                :title="device.comment || '保养'"
                v-if="device.state == 4"
                class="status-icon iconfont icon-baoyang"
              ></span>
            </div>

            <div class="icon-box">
              <el-popover
                v-if="device.warnList && device.warnList.length > 0"
                placement="right"
                width="400"
                trigger="hover"
              >
                <div style="display: flex; flex-direction: column">
                  <div
                    style="line-height: 20px; color: #fff; padding: 12px"
                    v-for="(warn, index) in device.warnList"
                    :key="index"
                  >
                    {{ warn.content }}
                  </div>
                </div>
                <span
                  slot="reference"
                  style="margin-right: 12px"
                  class="iconfont icon-jiankang"
                ></span>
              </el-popover>

              <span
                @click="itemLocation(device)"
                class="iconfont fr icon-dingwei1"
              ></span>
              <span class="type fr">{{ typeName(device.status) }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="nest-child_group_box" v-if="data.child && data.child.length">
        <Item
          v-for="item in data.child"
          :data="item"
          :key="`device_child_${item.id}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      device: {},
    };
  },
  computed: {
    curr() {
      return this.current();
    },
  },
  methods: {
    typeName(val) {
      let name = "";
      if (val == 6) {
        name = "充电中";
      } else if (val == 5) {
        name = "飞行中";
      } else if (val == 2 || val == 3 || val == 4) {
        name = "出仓中";
      } else if (val == 1) {
        name = "回收中";
      } else if (val == 0) {
        name = "待机中";
      }
      return name;
    },
    handClick(e, device) {
      // this.device.status=false
      // device.status = e
      // this.device = device
      // 清除轨迹模型
      // 关键词：飞控鹰巢任务结束
      Bus.$emit("closeIconShow");

      this.itemClick(e, device);
    },
  },
  inject: ["itemClick", "itemLocation"],
};
</script>

<style lang="scss" scoped>
.cpt-nest-list-item {
  .nest-group_box {
    width: 100%;

    .group-title_box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 40px;

      .title-box {
        width: calc(100% - 80px);
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;

        .iconfont {
          color: #ccedff;
          transform: rotate(90deg);
          transition: 0.3s;
          cursor: pointer;

          &.collapse {
            transform: rotate(0deg);
          }
        }

        .title {
          font-family: Microsoft YaHei;
          font-size: 14px;
          color: #ccedff;
          font-weight: 400;
          margin-left: 5px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .line-box {
        width: 80px;
        flex-shrink: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #ccedff;
        line-height: 19px;
        font-weight: 400;
        text-align: right;
        margin: 0 44px 0 0;
      }
    }
  }

  .nest-children {
    overflow: hidden;

    &.collapse {
      height: 0;
    }

    .nest-device-list {
      .nest-device-item {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .iconfont {
          font-size: 22px;
          color: #9baaac;
        }

        &.online {
          .title-box {
            .iconfont {
              // color: #fff;
            }

            .title {
              color: #fff;
            }
          }
        }

        .title-box {
          height: 100%;
          white-space: nowrap;
          ::v-deep .el-checkbox {
            &.is-checked {
              .el-checkbox__input {
                .el-checkbox__inner {
                  border-color: #08c2d1;

                  &::after {
                    border-color: #08c2d1;
                  }
                }
              }
            }

            .el-checkbox__input {
              .el-checkbox__inner {
                border-radius: 0;
                width: 18px;
                height: 18px;
                background: #000000;
                // border-color: #08c2d1;
                border-color: #fff;
                box-sizing: border-box;

                &::after {
                  height: 10px;
                  left: 5px;
                  top: 1px;
                  width: 5px;
                }
              }
            }
          }

          .iconfont {
            font-size: 20px;
            color: #ccedff;
          }

          .title {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #9baaac;
            font-weight: 400;
            margin-left: 8px;
          }
          .status-icon {
            margin-left: 12px;
            font-size: 14px;
            color: RGBA(251, 192, 1, 1);
          }
        }

        .icon-box {
          .icon-jiankang {
            font-weight: bold;
            color: #d54a15;

            &.healthy {
              color: #15d589;
            }
          }
        }
      }
    }

    .nest-child_group_box {
      box-sizing: border-box;
      padding-left: 20px;
    }
  }
}
.li {
  font-size: 16px;
  color: #9baaac;
  font-weight: 400;
}
.zai {
  color: #31db24;
  font-weight: 400;
  font-size: 16px;
}
.type {
  font-weight: 400;
  font-size: 10px;
  height: 22px;
  line-height: 22px;
  transform: scale(0.9);
  border: 1px solid #c0aaaa;
  padding: 0 4px;
  border-radius: 4px 4px;
  color: #fff;
  background-color: #224563;
}
/deep/ .el-checkbox__inner {
  background: transparent !important;
}
</style>

<style>
.el-popover .popper__arrow::after {
  border-bottom-color: transparent !important;
}
</style>
