<template>
  <div class="pr cpt-observe-nest-temp_humiBox">
    <div class="h32 pr lh32 cpt-observe-nest-temp_humi_header tc">
      <div class=""><span class="dib w1 h10 line"></span> {{device.deviceName}} <span class="dib h10 w1 line"></span>
      </div>
      <div class="pa right10 top0 cp" @click="flag=!flag"><span class="iconfont icon-fangda" v-if="!flag"></span> <span
          v-if="flag" class="iconfont icon-suoxiao"></span></div>
    </div>
    <div class="cpt-observe-nest-temp_humi  " v-if='flag'>
      <div class="item-box">
        <div class="tc w100">
          <div class="cfc">
            <span class="value">
              {{uavData&&uavData.signal&&uavData.signal.cellId||0}}
            </span>
          </div>
          <div class="title">CELLID</div>
        </div>
        <div class="tc w100">
          <div class="cfc">
            <span class="value"> {{uavData&&uavData.signal&&uavData.signal.rsrp||0}}</span>
            dBm
          </div>
          <div class="title">信号值</div>
        </div>
        <div class="tc w100">
          <div class="cfc">
            <span class="value"> {{uavData&&uavData.signal&&uavData.signal.sinr||0}}</span>
            dBm
          </div>
          <div class="title">干扰值</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      uavData: {
        type: Object,
        default: () => ({})
      },
      device: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        flag: false
      };
    },
    computed: {}
  };
</script>

<style lang="scss" scoped>
  .cpt-observe-nest-temp_humiBox {
    width: 700px;
    position: fixed;
    top: 67px;
    transform: translateX(-50%);
    left: 50%;
    margin: 0 auto;

    .line {
      background: #FFBD36;
      border: 1px solid #FFBD36;
    }

    .cpt-observe-nest-temp_humi_header {
      background-image: linear-gradient(180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.22) 40%,
          #05091a 100%);
      border: 1px solid #70daf9;
      box-shadow: inset 0 0 10px 2px #3f9dff;

      font-size: 14px;
      color: #00f5ff;
    }
  }

  .cpt-observe-nest-temp_humi {
    background: rgba(9, 32, 87, 0.70);
    padding: 14px 11px;
    box-sizing: border-box;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        color: #d8eafa;
        font-size: 14px;
      }

      .value {
        font-family: UniDreamLED;
        font-size: 22px;
      }
    }
  }
</style>