<template>
    <div class="nset_control_box">
        <div class="nset_control_box_header lh34">
            <slot name="nset__left" ></slot>
            <slot name="nset__right" ></slot>
        </div>
        <div class="nset_control_box_area p10 pb30" :class="{changeHeightStyle}">
            <slot></slot>
        </div>
        <div @click='changeHeight' class="wih100 h24 pa bottom0 cp  nset_control_box_bottom tc">
            <div class="w24 h24  dib  "  :class="changeHeightStyle ? 'xbStyle':'' "> <img
                    src="~@/assets/images/observe/fckernel/xb.png" alt=""> </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                changeHeightStyle: false
            }
        },
        methods: {
            changeHeight() {
                this.changeHeightStyle = !this.changeHeightStyle
            },
        },
    }
</script>

<style lang="scss" scoped>
    .nset_control_box {
        min-height: 200px;
        background: rgba(9, 32, 87, 0.70);
        border: 1px solid #70DAF9;
        position: absolute;
        left: calc(50% - 352px);
        top: 70px;
        min-width: 700px;
        .nset_control_box_header {

            display: flex;
            justify-content: space-between;
            height: 32px;

            background-image: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.22) 40%, #05091A 100%);
            border: 1px solid #70DAF9;
            box-shadow: inset 0 0 10px 2px #3F9DFF;
        }

        .nset_control_box_bottom {
            background: rgba(13, 82, 143, 0.60);

        }

        .xbStyle {
            transform: rotateX(141deg);
        }

        .changeHeightStyle {
            height: 300px;
        }
    }
</style>