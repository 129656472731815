<template>
  <div class="cpt-nest-logger">
    <div class="title-box" v-interact>
      <span class="title pl20"> 运行监控流程 </span>
      <!-- <div class="icon-box" @click="$emit('step')">
        <span class="iconfont icon-qingchushuju"></span>
        <span class="icon-text">流程</span>
      </div> -->
      <div class="cf ml20 mr10" @click="$emit('close')">关闭</div>
    </div>

    <div class="ctx-box">
      <div class="list-box">
        <div class="item-box mt7" v-for="(item, index) in list" :key="index">
          <div class="ml20">
            {{ item.start_time }}
          </div>
          <div class="items-box">
            <div v-for="(item2, index2) in item.action_list" :key="index2">
              <div class="text-box pl30">
                <div class="type-box" >
                  <span class="type">执行动作</span>
                </div>
                <span class="text">{{ item2["action-title"] }}</span>
              </div>
              <div class="text-box pl30">
                <div class="type-box" >
                  <span class="type">执行状态</span>
                </div>
                <span class="text">{{ filter(item2["action-status"]) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wih100 mt100 h60 xb"></div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      timer: null,
    };
  },
  methods: {
    handle_clear() {
      this.list = [];
    },
    async getStepList() {
      this.list = [];
      // let res = await API.NEST.getStepList({ deviceId: 22,status: 1});
      let res = await API.NEST.getStepList({
        deviceId: this.data.deviceHardId,
      });
      res.forEach((val) => {
        // val.action_list.push(val.action_list[0])
        this.list.push(val);
      });
      // this.list = res[0].action_list;
    },
    filter(val) {
      if (val == "INITIAL") {
        return "未执行";
      } else if (val == "SUCCESS") {
        return "执行成功";
      } else if (val == "FAIL_CONTINUE") {
        return "等待中...";
      } else if (val == "GAME_OVER") {
        return "执行失败";
      } else if (val == "WAIT_RUN") {
        return "等待继续执行";
      } else if (val == "TIMEOUT") {
        return "执行超时";
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getStepList();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.cpt-nest-logger {
  position: fixed;
  width: 630px;
  height: 250px;
  top: 61%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  overflow-y: auto;
  background: rgba(9, 32, 87, 0.7);
  border: 1px solid #70daf9;
  flex-direction: column;

  .title-box {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.22) 40%,
      #05091a 100%
    );
    border: 1px solid #70daf9;
    box-shadow: inset 0 0 10px 2px #3f9dff;
    height: 30px;

    .title {
      font-family: Microsoft YaHei;
      font-size: 18px;
      text-align: center;
      color: #2edfff;
      font-weight: 400;
      line-height: 54px;
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }

  .ctx-box {
    flex: 1;
    overflow: hidden;

    .list-box {
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;

      .item-box {
        display: flex;
        flex-direction: column;
        // align-items: center;
        color: #fff;

        .items-box {
          display: flex;
          flex-wrap: wrap;

          .text-box {
            display: flex;
            align-items: center;

            .type-box {
              width: 50px;
              height: 20px;
              transform-origin: center;
              transform: scale(0.8);
              padding: 2px 6px;
              border-radius: 30px;
              text-align: center;
              display: flex;
              align-items: center;

              &.ordinary {
                background-color: #298ad3;
              }

              &.emerg {
                background-color: #ff3c3c;
              }

              .type {
                font-size: 10px;
              }
            }

            .text {
              font-size: 12px;
              color: #bacfea;
            }
          }
        }
      }
    }
  }

  .xb {
    transform: rotate(-180deg);
    background: rgba(1, 10, 46, 0.63);
    border-radius: 10px 10px 0 0;
  }
}
</style>