<template>
  <div class="nest_header_box jcsb">
    <div class="nest_header_box_left">
      <div class="date-box">
        <div class="yyyy">{{ date }}</div>
        <div></div>
        <div class="week-box">
          <div class="week">{{ week }}</div>
          <div class="hhmm">{{ time }}</div>
        </div>
      </div>
      <div class="line ml16"></div>
      <img class="w35 ml16" :src="weatherIcon" alt />
      <div class="line ml16"></div>
      <div>
        <div style="color: #00ffff" class="ml16">
          <div>{{ weather_data.windDirection }}</div>
          {{ weather_data.minTemp || 0 }}~{{ weather_data.maxTemp || 0 }}°C
        </div>
      </div>
      <div class="line ml16"></div>
      <div v-if="device">
        <div style="color: #00ffff" class="ml16 tc">
          <div>舱内温度</div>
          {{ nestData.hangarTemp || 0 }}°C
        </div>
      </div>
      <div></div>
      <el-tooltip content="烟感" placement="bottom" v-if="device">
        <div class="symbol-icon-box ml16" style="opacity: 0.7">
          <SymbolIcon :icon="fire[fire_state]" />
        </div>
      </el-tooltip>

      <el-tooltip content="水浸" placement="bottom" v-if="device">
        <div class="symbol-icon-box ml16" style="opacity: 0.7">
          <SymbolIcon :icon="water[water_state]" />
        </div>
      </el-tooltip>
      <!-- 不宜起飞 -->
      <el-tooltip
        v-if="device && device.firmId == 1"
        class="item cfc"
        effect="dark"
        :content="weatherTitle"
        placement="bottom"
      >
        <div class="noFlight ml16" v-if="weatherTitle != '适宜飞行'">
          不宜起飞
        </div>
        <div class="isFlight ml16" v-else>适合起飞</div>
      </el-tooltip>
      <el-tooltip
        v-else
        class="item cfc"
        effect="dark"
        :content="weather_data.flyStatus == 0 ? '不宜起飞' : '适合起飞'"
        placement="bottom"
      >
        <div class="noFlight ml16" v-if="weather_data.flyStatus == 0">
          不宜起飞
        </div>
        <div class="isFlight ml16" v-else>适合起飞</div>
      </el-tooltip>

      <div class="changeBtn lh50 cp tc" @click="$emit('changeF')">
        无人机应用
      </div>
    </div>
    <div class="w300 cp ht100 pa gohome" @click="$router.push('/home')" />
    <div class="icon-box nest_header_box_right mt8 pr">
      <div
        class="changeBtn lh50 cp tc"
        :class="isSelect ? 'isSelect' : ''"
        @click="changeItem"
      >
        视频墙
      </div>
      <div class="jcsb wih100" v-if="device && !wsShow">
        <div class="ml12">
          <el-tooltip :content="gwArr[gwSqe45].info" placement="bottom">
            <SymbolIcon :icon="gwArr[gwSqe45].icon" />
          </el-tooltip>
        </div>
        <!-- <div class="ml12" v-if="zwArr[zwSqe14]">
          <el-tooltip :content="zwArr[zwSqe14].info" placement="bottom">
            <SymbolIcon :icon="zwArr[zwSqe14].icon" />
          </el-tooltip>
        </div> -->

        <el-tooltip content="网络状态" placement="bottom">
          <span class="cfc dib ml10 f14 mt10 mr8">{{ ModeStatus }}</span>
        </el-tooltip>
        <!-- 公专网切换 -->
        <el-dropdown trigger="click" @command="handle_uav_mode">
          <span class="dib">
            <span class="mode_name cp ml12" style="color: #70f6f9">
              {{ priority }}
            </span>
            <i class="el-icon-caret-bottom"></i>
          </span>
          <div class="el-popperBox">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in ModeStatusList"
                :key="item.id"
                :command="item.id"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
        <div class="line"></div>
        <el-tooltip
          class="item ml10 mr10 cfc mt7"
          effect="dark"
          content="搜星数"
          placement="bottom"
        >
          <div class="item-box">
            <div class="icon-box">
              <span class="iconfont icon-souxing">:</span>
              <span class="f14">{{ satelliteCount }}</span>
            </div>
          </div>
        </el-tooltip>
        <el-tooltip
          content="RTK"
          placement="bottom"
          v-if="
            uavData &&
            uavData.rtk &&
            uavData.rtk.type == 19 &&
            uavData.rtk.isMainSensor
          "
        >
          <div class="symbol-icon-box ml12">
            <!-- <SymbolIcon v-if="uavData && uavData.rtk" :icon="signal_rtk[signal_state_rtk]" /> -->
            <img
              v-if="uavData && uavData.rtk"
              :src="signal_rtk[signal_state_rtk]"
            />
            <img v-else src="./img/RTK-0.svg" />
          </div>
        </el-tooltip>
        <el-tooltip content="GPS" placement="bottom" v-else>
          <div class="symbol-icon-box ml12">
            <SymbolIcon
              v-if="uavData && uavData.gps"
              :icon="signal[signal_state]"
            />
            <img v-else src="./GPS0.svg" />
          </div>
        </el-tooltip>
        <div class="line"></div>
        <el-tooltip
          class="item cfc"
          effect="dark"
          :content="FIX || '未知'"
          placement="bottom"
        >
          <div class="item-box ml5">
            <div class="icon-box">
              <span
                style="color: #43f0df"
                :class="FIX == 'LOCK_3D_RTK_GPS_LOCK_FIXED' ? '' : 'noStyle'"
                class="iconfont icon-FIX f28"
              ></span>
            </div>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item cfc"
          effect="dark"
          :content="RKT ? 'RTK已启用' : 'RTK未启用'"
          placement="bottom"
        >
          <div class="item-box mr5">
            <div class="icon-box">
              <span
                style="color: #43f0df"
                :class="RKT ? '' : 'noStyle'"
                class="iconfont icon-RTK1 f28"
              ></span>
            </div>
          </div>
        </el-tooltip>
        <div class="line"></div>
        <div class="icon-box mr10 ml10">
          <el-tooltip
            :content="`遥控器${{ 0: '未连接', 1: '已连接' }[remote_state]}`"
            placement="bottom"
          >
            <div class="symbol-icon-box">
              <SymbolIcon :icon="remote[remote_state]" />
            </div>
          </el-tooltip>
        </div>

        <div class="mode_nameBox cp">
          <el-dropdown trigger="click" @command="handle_change_uav_mode">
            <span class="dib">
              <span class="mode_name">{{ FlightMode }}</span>
              <i class="el-icon-caret-bottom"></i>
            </span>
            <div class="el-popperBox">
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in modeList"
                  v-show="!item.disabled"
                  :key="item.id"
                  :command="item.data"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </div>
          </el-dropdown>
        </div>
      </div>
      <!-- 大疆机库 -->
      <div v-if="firmId == 2" class="right0 pa jcsb">
        <div v-if="gwArr[level]" class="mr30">
          <el-tooltip :content="gwArr[level].info" placement="bottom">
            <SymbolIcon :icon="gwArr[level].icon" />
          </el-tooltip>
        </div>
        <div class="mr30" style="color: #2ddcfc">
          {{ uavData && uavData.platformType == 2 ? "图传" : "公网" }}
        </div>
        <div class="mode_nameBox cp w80">
          <span class="dib">
            <span class="mode_name f12 dib"
              ><span class="ml12">{{ flightModews }}</span></span
            >
          </span>
        </div>
      </div>
    </div>

    <messageBar
      v-if="news.status && device"
      :news="news"
      @close="news.status = 0"
    ></messageBar>

    <el-dialog
      title
      :visible.sync="openshow"
      width="30%"
      :append-to-body="true"
      style="margin-top: 20vh"
    >
      <div class="endrenwu">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">
          请确定是否进行
          <span style="color: red">{{ modelType }}</span
          >切换,目前无人机处于
          <span style="color: red">
            {{ rcChannelStateList[rcChannelState] }} </span
          >状态
        </div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="openshow = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="model_qr(modelData)">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api";
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
import messageBar from "../messageBar";
import dayjs from "dayjs";
// 引用bus
import { mapGetters } from "vuex";
import Bus from "@/assets/ligature.js";
export default {
  props: {
    uavBattery: {
      type: Object,
    },
    nestData: {
      type: Object,
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    isSelectFlag: {
      type: Boolean,
      default: () => true,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: Number,
    },
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      },
    },
  },

  data() {
    return {
      signal_rtk: {
        1: require("./img/RTK-1.svg"),
        2: require("./img/RTK-2.svg"),
        3: require("./img/RTK-3.svg"),
        4: require("./img/RTK-4.svg"),
        5: require("./img/RTK-5.svg"),
      },
      modelData: null,
      openshow: false,
      modelType: null,
      ModeStatusList: [
        { id: 1, data: "1.4G", label: "图传" },
        { id: 3, data: "4G/5G", label: "公网" },
        { id: 0, data: "自动", label: "自动" },
      ],
      zwArr: [
        {
          // icon: "xinhaoshaixuan",
          icon: "a-14-4beifen21",
          info: "基站专网信号数据断开",
        },
        {
          icon: "a-14-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-14-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-14-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-14-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-14-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-14-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      gwArr: [
        {
          icon: "xinhaoshaixuan",
          info: "基站信号数据断开",
        },
        {
          icon: "a-5g-6",
          info: "覆盖非常好",
        },
        {
          icon: "a-5g-5",
          info: "覆盖好,室外能够发起各种业务,可获得高等数据业务,室内能够发起各种业务，可获得中等数据业务",
        },
        {
          icon: "a-5g-4",
          info: "覆盖较好,室外能够发起各种业务,可获得中等数据业务,室内能够发起各种业务，可获得低数据业务",
        },
        {
          icon: "a-5g-3",
          info: "覆盖一般，室外能够发起各种业务，可获得中等数据业务,室内呼叫成功率低",
        },
        {
          icon: "a-5g-2",
          info: "覆盖率差，户外语音业务能够起呼，但成功率较低",
        },
        {
          icon: "a-5g-1",
          info: "覆盖较差，业务基本无法起呼",
        },
      ],
      setIntervalTime: "",
      isSelect: false,
      rcChannelStateList: {
        0: "摇杆中位",
        "-98": "摇杆外场权限",
        "-99": "摇杆未连接",
        1: "俯仰偏前",
        "-1": "俯仰偏后",
        "-2": "横滚偏左",
        2: "横滚偏右",
        3: "油门偏高",
        "-3": "油门偏低",
        4: "航向偏右",
        "-4": "航向偏左",
      },
      mode: "离线状态",
      weatherIcon: null,
      now: null,
      curr_val: 0,
      remote: {
        0: "yaokongqi-duankailianjie",
        1: "yaokongqiliang",
      },
      week_map: {
        0: "天",
        1: "一",
        2: "二",
        3: "三",
        4: "四",
        5: "五",
        6: "六",
      },
      ws: null,

      fire: {
        0: "yanganan",
        1: "yanganhong",
        2: "yanganliang",
      },
      water: {
        0: "shuijinan",
        1: "shuijinhong",
        2: "shuijinliang",
      },
      power: {
        1: "dianliangbaifenzhiershi",
        2: "dianliangbaifenzhisishi",
        3: "dianliangbaifenzhiliushi",
        4: "dianliangbaifenzhibashi",
        5: "chongdianmoshi5",
      },
      weather: {},
      weatherTitle: "暂未选择鹰巢",
      news: {},
      weather_data: {
        weather: "晴",
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: "",
        windSpeed: "",
        minTemp: "",
        maxTemp: "",
        windDirection: "东北",
      },
      flightModews: "离线状态", //非公司鹰巢
      modeList: [
        { id: 1, data: "MANUAL", label: "手动模式", disabled: true },
        { id: 2, data: "ALTITUDE", label: "定高模式", disabled: false },
        { id: 3, data: "POSITION", label: "定点模式", disabled: false },
        { id: 4, data: "AUTO_MISSION", label: "航线模式", disabled: false },
        { id: 5, data: "ACRO", label: "特技模式", disabled: true },
        { id: 6, data: "OFF_BOARD", label: "版外模式", disabled: true },
        { id: 7, data: "STABILIZED", label: "自稳模式", disabled: true },
        { id: 8, data: "HOLD", label: "保持模式", disabled: false },
        { id: 9, data: "LAND", label: "降落模式", disabled: true },
        { id: 10, data: "TAKE_OFF", label: "起飞模式", disabled: true },
        { id: 11, data: "AUTO_RTL", label: "返航模式", disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: "跟随模式",
          disabled: true,
        },
      ],
      modeListws: [
        // { id: 1, data: "11", label: "安全降落", disabled: false },
        { id: 2, data: "12", label: "返航模式", disabled: false },
        { id: 3, data: "13", label: "航线模式", disabled: false },
        { id: 4, data: "17", label: "gps模式", disabled: false },
      ],
      signal: {
        1: "GPS1",
        2: "GPS2",
        3: "GPS3",
        4: "GPS4",
        5: "GPS5",
      },
    };
  },
  watch: {
    "device.deviceHardId": {
      handler(value) {
        if (this.setIntervalTime) {
          clearInterval(this.setIntervalTime);
          this.setIntervalTime = "";
        }
        this.getNestWeatherFn(value);
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.curr_val = value;
      },
      deep: true,
      immediate: true,
    },
    uavData: {
      handler(val) {
        if (val && this.wsShow) {
          this.flightModews = val.flightMode;
        } else {
          this.flightModews = "离线状态";
        }
        this.$store.commit("fckernel/SET_YC_VIDEO_STATUS", this.ModeStatus);
      },
    },
    isSelectFlag: function (newValue) {
      this.isSelect = newValue;
    },
    ModeStatus: function (newval) {
      this.$emit("ModeStatus", newval);
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
    firmId() {
      // 1 代表ktb 2 代表大疆
      let data = this.$store.state.fckernel.active_nestData;
      if (data) {
        return data.firmId;
      }
      return 1;
    },
    priority() {
      let { uavData } = this;
      let name = "离线";
      if (uavData && uavData.priority == 0) {
        name = "自动";
      } else if (uavData && uavData.priority == 1) {
        name = "图传";
      } else if (uavData && uavData.priority == 2) {
        name = "专网";
      } else if (uavData && uavData.priority == 3) {
        name = "公网";
      }
      return name;
    },
    // 网络判断 公网|专网
    ModeStatus() {
      let { uavData } = this;
      let name = null;
      if (uavData && uavData.link) {
        for (let i = 0; i < uavData.link.length; i++) {
          const k = uavData.link[i];
          if (k.priority == 2 && k.using) {
            return "专网";
          } else if (k.priority == 3 && k.using) {
            return "公网";
          } else if (k.priority == 1 && k.using) {
            return "图传";
          }
        }
      }
      return name || "离线";
    },
    rcChannelState() {
      let { uavData } = this;
      return uavData && uavData.rcChannelState;
    },
    zwSqe14() {
      let { uavData } = this;
      if (uavData && uavData.signal && uavData.signal.sqe14) {
        if (uavData.signal.sqe14 === -1) {
          return 0;
        }
        return uavData.signal.sqe14;
      }
      return 6;
    },
    gwSqe45() {
      let { uavData } = this;
      if (uavData && uavData.signal && uavData.signal.sqe45) {
        if (uavData.signal.sqe45 === -1) {
          return 0;
        }
        return uavData.signal.sqe45;
      }
      return 6;
    },
    gzSignal() {
      let { uavData } = this;
      return 0;
    },
    handle_change_uavws_mode(item) {
      this.$emit("control_ws", item);
    },
    FlightMode() {
      let { uavData, modeList } = this;
      let flightMode = modeList.find((e) => e.data == uavData?.flightMode);
      // 用vuex将当前的模式存起来 飞控 鹰巢
      this.$store.commit("shoudong/compose_mode", flightMode?.label);
      return flightMode?.label || "离线状态";
    },
    fire_state() {
      let { nestData } = this;
      return nestData?.fireWarn ? nestData.fireWarn : 0;
    },

    RKT() {
      let { uavData } = this;
      return (uavData && uavData.isRtkEnable == true) || 0;
    },
    FIX() {
      let { uavData } = this;
      if (
        uavData &&
        uavData.rtk &&
        uavData.rtk.type == 19 &&
        uavData.rtk.isMainSensor
      ) {
        return uavData?.rtk?.fixType;
      } else {
        return uavData?.gps?.fixType;
      }
    },
    water_state() {
      let { nestData } = this;
      return nestData?.waterWarn ? nestData.waterWarn : 0;
    },
    is_charger() {
      let { nestData } = this;
      return nestData && nestData.chargerPower == 1;
    },
    power_state() {
      let { uavBattery } = this;
      if (uavBattery && uavBattery.chargeRemaining) {
        return parseInt(uavBattery && uavBattery.chargeRemaining / 20 + 1);
      } else {
        return 1;
      }
    },
    remote_state() {
      let { uavData } = this;
      return uavData ? (uavData.isRcConnecting ? 1 : 0) : 0;
    },
    voltage() {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.voltage || 0 : 0;
    },
    power_num() {
      let { uavBattery } = this;
      return uavBattery ? uavBattery.chargeRemaining : 0;
    },
    date() {
      let { now } = this;
      return now ? `${now.format("YYYY/MM/DD")}` : "0000/00/00";
    },
    week() {
      let { now, week_map } = this;
      return now ? `周${week_map[now.day()]}` : "周N/A";
    },
    time() {
      let { now } = this;
      return now ? `${now.format("HH:mm")}` : "00:00";
    },
    satelliteCount() {
      let { uavData } = this;
      if (
        uavData &&
        uavData.rtk &&
        uavData.rtk.type == 19 &&
        uavData.rtk.isMainSensor
      ) {
        return uavData?.rtk?.satelliteCount || 0;
      } else {
        return uavData?.gps?.satelliteCount || 0;
      }
    },
    signal_state() {
      let { uavData } = this;
      if (uavData && uavData.satelliteCount) {
        let count = parseInt(uavData.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.satelliteCount ? count : 1;
      }
      return 5;
    },
    signal_state_rtk() {
      let { uavData } = this;
      if (uavData && uavData.gps.satelliteCount) {
        let count = parseInt(uavData.gps.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavData.gps.satelliteCount ? count : 1;
      }
      return 1;
    },
  },
  components: { SymbolIcon, messageBar },
  mounted() {
    this.getWeather();
    setInterval(() => {
      this.now = dayjs();
    }, 500);
  },
  methods: {
    async getWeather() {
      let organId = { organId: this.user_info["departmentId"] };
      let res = await API.WEATHER.getNewWeather(organId);
      if (res.length > 0) {
        this.weather_data = res[0];
      }
      // console.log(this.weatherList,'this.weatherListthis.weatherList');
      const date = new Date();
      let time = date.getHours();
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${
        time >= 18 ? this.weather_data.nightIcon : this.weather_data.dayIcon
      }.png`);
    },
    handle_uav_mode(id) {
      let { uavData } = this;
      if (id == 0) {
        this.$emit("fn", { type: 770, data: id });
        return this.$message.success("改为自动切换模式");
      } else if (id == 1) {
        // 图传
        if (uavData?.link.length > 0) {
          let item = uavData?.link.filter((item) => item.priority == 1);
          if (item.length > 0) {
            this.$emit("fn", { type: 770, data: id },"wrj");
          } else {
            return this.$message.warning("暂无图传环境");
          }
        } else {
          return this.$message.warning("暂未链接无人机");
        }
      } else if (id == 3) {
        // 公网
        if (uavData?.link.length > 0) {
          let item = uavData?.link.filter((item) => item.priority == 3);
          if (item.length > 0) {
            this.$emit("fn", { type: 770, data: id },"wrj");
          } else {
            return this.$message.warning("暂无公网环境");
          }
        } else {
          return this.$message.warning("暂未链接无人机");
        }
      }
    },
    toVideos() {
      window.open(`#/videos`, "_blank");
    },
    handle_change_uav_mode(data, flag) {
      if (this.FlightMode == "离线状态")
        return this.$message.warning("处于离线状态，不可切换模式！");
      let mode = this.modeList.find((item) => item.data === data);
      this.modelData = data;
      if (
        mode.label == "定高模式" ||
        mode.label == "定点模式" ||
        mode.label == "保持模式"
      ) {
        if (
          (this.rcChannelState !== 0 && flag !== 1) ||
          (this.rcChannelState !== "-99" && flag !== 1)
        ) {
          this.modelType = mode.label;
          this.openshow = true;
        } else {
          this.model_qr(data);
        }
      } else {
        this.model_qr(data);
      }
    },
    model_qr(data) {
      let mode = this.modeList.find((item) => item.data === data);
      if (mode) {
        this.$emit("fn", { type: 513, data: mode.data }, "wrj");
        if (mode.data == "AUTO_RTL") {
          this.$message.success("进入返航模式");
        }
      }
      this.openshow = false;
    },
    changeItem() {
      this.$router.push("/fckernelVideos");
      // this.isSelect = !this.isSelect;
      // this.$forceUpdate();
      // try {
      //   this.$emit("nest_chenge", this.isSelect);
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async getNestWeatherFn(deviceId) {
      if (deviceId) {
        let res = await API.FCKERNEL.getNestWeather({ deviceId });
        this.news = res;
        if (res.status == 0) {
          this.weatherTitle = "适宜飞行";
        } else {
          this.weatherTitle = "不宜飞行";
        }
        this.setIntervalTime = setInterval(async () => {
          let res = await API.FCKERNEL.getNestWeather({ deviceId });
          // this.news = res
          if (res.status == 0) {
            this.weatherTitle = "适宜飞行";
          } else {
            this.weatherTitle = "不宜飞行";
          }
        }, 10000);
      }
    },
  },
  created() {
    this.now = dayjs();
    // 将右上角的模式修改为定点模式
    // 匹配关键字：修正模式
    Bus.$on("quick_search", (data) => {
      this.handle_change_uav_mode(data, 1);
    });
    // 需求返航模式
    // 关键字：鹰巢返航模式
    Bus.$on("superModel", (data) => {
      // console.log(data,'返航模式')
      this.handle_change_uav_mode(data, 1);
    });
  },
  inject: ["g_weather"],
};
</script>

<style lang="scss" scoped>
.right0 {
  align-items: center;
}
.nest_header_box {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  background: center url("~@/assets/images/observe/fckernel/nest/header.png");
  background-size: 100%;
  background-size: cover;
  .gohome {
    left: 50%;
    transform: translateX(-50%);
  }

  .nest_header_box_right {
    margin-top: 8px;
    height: 50px;
    min-width: 435px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .changeBtn {
      position: absolute;
      top: -3px;
      right: calc(50% + 370px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/right.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #70f6f9;
    }

    .changeBtn:hover {
      background: center
        url("~@/assets/images/observe/fckernel/nest/rightD.png");
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isSelect {
      background: center
        url("~@/assets/images/observe/fckernel/nest/btnActiveRight.png");
      background-size: contain;
      background-position-y: -8px;
      background-repeat: no-repeat;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }
  }

  .nest_header_box_left {
    margin-top: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    height: 42px;
    padding-left: 117px;

    .changeBtn {
      position: absolute;
      top: 5px;
      left: calc(50% - 350px);
      width: 150px;
      height: 50px;
      background: center url("~@/assets/images/observe/fckernel/nest/leftD.png");
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #70f6f9;
      letter-spacing: 0;
    }

    .changeBtn:hover {
      background: center url("~@/assets/images/observe/fckernel/nest/left.png");
      // background-size: contain;
      background-repeat: no-repeat;
      background-position-y: -8px;
      font-size: 14px;
      color: #ffe9ae;
      letter-spacing: 0;
      font-weight: 700;
    }

    .isFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #6aff64;
      letter-spacing: 0;
      font-weight: 400;
    }

    .noFlight {
      font-family: YouSheBiaoTiHei;
      font-size: 28px;
      color: #ffc15b;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  .nav-list {
    width: 590px;
    height: 77px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;

    .nav-item {
      width: 223px;
      height: 77px;
      box-sizing: border-box;
      background: center url("~@/assets/images/observe/fckernel/nav-item.png")
        no-repeat;
      background-size: cover;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover,
      &.selected {
        background: center
          url("~@/assets/images/observe/fckernel/nav-item-selected.png")
          no-repeat;

        .text-box {
          color: #3df1ff;
        }
      }

      .text-box {
        line-height: 77px;
        font-family: Microsoft YaHei Bold;
        font-size: 20px;
        color: #aab8d1;
        font-weight: 700;
        transition: 0.3s;
      }

      &._2 {
        transform: scaleX(-1);

        .text-box {
          transform: scaleX(-1);
        }
      }
    }
  }

  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0;
    height: 60px;
    box-sizing: border-box;

    .yyyy {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 3px;
    }

    .week-box {
      display: flex;
      justify-content: space-between;

      .week {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .hhmm {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mode_nameBox {
  width: 116px;
  height: 28px;

  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat;
  background-size: 100% 100%;
  margin-right: 5px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }

  .el-dropdown {
    color: #3df1ff;
    font-weight: 400;
    font-size: 14px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .el-icon-caret-bottom {
      color: #fff !important;
      line-height: 24px;
    }
  }
}

.el-dropdown-menu {
  // left: 1797px !important;
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(9, 32, 87, 0.5) !important;
  .el-dropdown-menu__item {
    width: 115px;
    color: #3df1ff !important;
    box-sizing: border-box;
    text-align: center;
  }
}

.noStyle {
  opacity: 0.3;
}
.el-dropdown-selfdefine {
  margin-right: 0;
}

.line {
  width: 1px;
  height: 32px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 47%,
    rgba(255, 255, 255, 0) 100%
  );
}
.el-dropdown {
  display: flex;
  align-items: center;
}
/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}

/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}

/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important ;
}
// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9 !important;
  background: rgba(9, 32, 87, 0.7) !important;
}

.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}
.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}

.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}
.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}

.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}
</style>
