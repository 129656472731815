<template>
    <div class="w142 h48 mountBox" :style="wsShow ? 'top: 0':''">

        <div v-if="mounts.length>0" class="list">
            <div class="mount-item pr mt6" v-for="(item, index) in mounts" :key="index">
                <div class="icon-box" @click="selected_mount = item">
                    <img class="cp dib w30 h30" :src="item.icon" />
                </div>
            </div>
        </div>
        <template v-if="selected_mount">
            <component :keyFlag="keyFlag" :device='device' :selected_mount='selected_mount' :moveType='moveType' :containerStyle="{  left: '-306px', top: '143px'}" :is="selected_mount.component" @directive="handle_directive"
                @close="selected_mount = null" :taskId="taskId" @take_photo="take_photo" @record="record" :PoliceKeyCode="nest_this.nest.data.deviceList[0].deviceHardId" :payload="
                selected_mount && uavMountsPayload
                  ? uavMountsPayload[selected_mount.name]
                  : []
              " />
        </template>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
    export default {
        name: 'Mount',
        data() {
            return {
                selected_mount: null,
                moveType:"yc"
            }
        },
        props: {
            wsShow: {
                type: Boolean,
                default: () => {
                    false;
                }
            },
            iconShow: {
                'type': Boolean,
                default: false
            },
            uavMounts: {
                type: Array,
                default: () => [],
            },
            uavMountsPayload: {
                type: Object,
                default: () => ({}),
            },

            mount: {
                type: Object,
                default: () => ({}),
            },
            taskId: {
                type: Boolean,
                default: ""
            },
            device: {
                type: Object,
                default: () => ({})
            },
            keyFlag:{
                type:Boolean,
                default: () => false
            }
        },
        watch: {
            selected_mount: {
                immediate: true,
                handler(){
                    this.$store.commit('fckernel/SET_SELECT_MOUNT', this.selected_mount);
                }
            }
        },
        inject: ["nest_this"],
        methods: {
            handle_directive(buffer) {
                let { selected_mount } = this;
                if (selected_mount) {
                    if(!this.wsShow){
                        this.$emit("fn", {
                            type: 514,
                            data: {
                                mountId: selected_mount.mountId,
                                payload: buffer,
                            },
                        }, 'wrj');
                    }else{
                        let buff = buffer.join(",");
                        let data = {
                            type: 200,
                            systemCode: "mmc",
                            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
                            username: this.user_info.username,
                            height: 0,
                            idlingTime: 0,
                            data: {
                            cmdControlType: 7000, //520
                            cmdFunction: null,
                            cmdState: null,
                            cmdValue: null,
                            cmdBuffer: buff,
                            cmdName: this.selected_mount.name
                            },
                            deviceHardId: this.nest_this.nest.data.deviceList[0].deviceHardId
                        };
                        this.$emit("fun", data);
                    }
                }
            },
            async take_photo(data){
      // if(!this.taskId || this.taskId == "") return this.$message.error("暂无任务！")
      if(data.data){
        // data.data.data.taskID = this.taskId
        console.log(data,"photo拍照");
        this.$emit("fn",data,'wrj')  
        this.$message.success("拍照成功！") 
      }
    //   if(this.taskId){
    //     let dataRun = {
    //       taskId: this.taskId,
    //       typeId: 2,
    //       deviceId: nest_this.nest.data.deviceList[0].id
    //     }
    //     let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
    //   }
    },
    async record(data){
      // if(!this.taskId  || this.taskId == "") return this.$message.error("暂无任务！")
      // if(data.data){
      //   data.data.data.taskID = this.taskId
      // }
      if(data.data.data.recordControl || data.data.data.status){
        this.$message.success("开始录像！")
        // if(this.taskId){
        //   let dataRun = {
        //   taskId: this.taskId,
        //   typeId: 25,
    //       deviceId: nest_this.nest.data.deviceList[0].id
        // }
        // let run = await API.FCKERNEL.saveDeviceRunMonitorLog(dataRun)
        // }
      }else{
        this.$message.success("停止录像！")
      }
        console.log(data,"record录像");
      this.$emit("fn",data,'wrj')
     }
        },
        computed: {
    ...mapGetters(["user_info"]),
            mounts() {
                let { uavMounts, mount } = this;
                if (uavMounts && uavMounts.length) {
                    if(!this.wsShow){
                        let mounts = [];
                        uavMounts.forEach((item) => {
                            let m = mount.list.find((i) => i.name === item.gimbalName);
                            if (m) {
                                m.mountId = item.mountId;
                                mounts.push(m);
                            }
                        });
                        console.log(mounts);
                        return mounts;
                    }else{
                        let mounts = [];
                        uavMounts.forEach((item) => {
                            let m = mount.list.find((i) => i.name === item.mountName);
                            if (m) {
                                m.mountId = item.mountId;
                                mounts.push(m);
                            }
                        });
                        console.log(mounts);
                        return mounts;
                    }
                }
                setTimeout(() => {
                    this.tips = "暂无挂载";
                }, 5000);
                return [];
            },
        },
        created() {
            this.tips = "正在识别使用中的挂载....";
        },
          beforeDestroy() {
            this.$store.commit("device/SET_MOVE_DATA", {})
        },
    }

</script>
<style scoped lang="scss">
    .mountBox {
        background: rgba(9, 32, 87, 0.70);
        border: 1px solid #70DAF9;
        position: absolute;
        top:  100px;
        right: 70px;
        z-index: 10;
        .list {
            display: flex;
            justify-content: space-around;
            .mount-item {
                width: 30px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #70DAF9;
                

            }

        }
    }
</style>
