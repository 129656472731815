export default {
  ws:null,
  data: null,
  offline: true,
  offlineTime: false,
  battery: null,
  mounts: [],
  mounts_payload: {},
  // 飞控通道信息
  channelData: {},
  // 状态
  wrjType: {},
  status: null,
  position: null, //无人机模型定位
  positions: [], //航线坐标数组
  orientation: null,
  video: {
    in: null,
    out: null,
    uav: null
  },
  modeList: [{
      id: 1,
      data: "MANUAL",
      label: "手动模式",
      disabled: true
    },
    {
      id: 2,
      data: "ALTITUDE",
      label: "定高模式",
      disabled: false
    },
    {
      id: 3,
      data: "POSITION",
      label: "定点模式",
      disabled: false
    },
    {
      id: 4,
      data: "AUTO_MISSION",
      label: "航线模式",
      disabled: false
    },
    {
      id: 5,
      data: "ACRO",
      label: "特技模式",
      disabled: true
    },
    {
      id: 6,
      data: "OFF_BOARD",
      label: "版外模式",
      disabled: true
    },
    {
      id: 7,
      data: "STABILIZED",
      label: "自稳模式",
      disabled: true
    },
    {
      id: 8,
      data: "HOLD",
      label: "保持模式",
      disabled: false
    },
    {
      id: 9,
      data: "LAND",
      label: "降落模式",
      disabled: true
    },
    {
      id: 10,
      data: "TAKE_OFF",
      label: "起飞模式",
      disabled: true
    },
    {
      id: 11,
      data: "AUTO_RTL",
      label: "返航模式",
      disabled: false
    },
    {
      id: 12,
      data: "AUTO_FOLLOW_TARGET",
      label: "跟随模式",
      disabled: true
    }
  ],
}