<template>
  <div class="pr notifications h40  pf mt80 ">
    <div class="  mt10  top0 cff h30 dib icon-xuanchuan1 iconfont " alt="" />
    <div class="w650 h40 pa ofh top0 ml25 mr50">
      <!-- ref="cmdlist" js写的动画速度不均匀 -->
      <div class="ml20 inner"  id="nWidth">
        <div class="dib mr40" >
          {{ news.describeText }}
        </div>
      </div>
    </div>

    <div @click="$emit('close')" class="close cff ml10">
      <span class="icon-guanbi iconfont"></span>
    </div>
  </div>
</template>

<script>
  import API from "@/api";
  export default {
    props:{
      news:{
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {};
    },
    mounted() {

      let box1 = document.getElementById("nWidth");
      let width = getComputedStyle(box1, null).getPropertyValue("width");

      box1.style.setProperty('--width',width === 'auto' ? '-100px' : '-'+width)
    },
    methods: {
    },
  };
</script>

<style lang="scss" scoped>
  .seamless-warp {
    height: 40px;
    // pointer-events: none;
  }

  .notifications {
    width: 700px;
    z-index: 9999;
    border-radius: 15px;
    padding: 0 10px;
    overflow: hidden;
    color: #fff;
    left: 50%;
    // background: rgba(6, 53, 111, 0.6);
    background: rgba(255,91,91,0.8);
    box-shadow: inset 2px 2px 5px 0 rgb(255,91,91);
    margin-left: -350px;
  }

  .close {
    position: absolute;
    cursor: pointer;
    padding-top: 10px;
    right: 10px;
    top: 0px;
    z-index: 99;
  }

  #nWidth {
    z-index: 9 !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: absolute;
    /*  作了修改*/
    top: 0;
    /*  作了修改*/
    left: 700px;
    /* 作了修改*/

    line-height: 40px;
    display: block;
    word-break: keep-all;
    // text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 微软雅黑;
    // color: rgb(0, 228, 255);
    color: #fff;
    font-size: 18px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .inner {
    display: flex;
  }

  li {
    list-style: none;
    height: 40px;
    text-align: center;
    line-height: 40px;
    white-space: nowrap;
    list-style: none;
  }

  // 动画
  .inner {
    // width: fit-content;
    display: inline-block;
    animation: moveAni 10s infinite linear normal;
    a {
      width: fit-content;
    }
  }
  @keyframes moveAni {
    0% {
       left: 650px;
    }
    100% {
       left: var(--width);
    }
  }
</style>