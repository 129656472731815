<template lang="">
  <div class="nest_info_maxBox"  v-if="!wsShow">
      <div class="infoBox cf" >
      <div class="dib title">
        {{ props_obj.relativeAlt ? props_obj.relativeAlt.toFixed(2) : 0 }}
      </div>
      m
      <div class="cf infor">飞行高度</div>
    </div>
    <div class="infoBox  cf" >
      <div class="dib title">
        {{ props_obj.absoluteAlt ? props_obj.absoluteAlt.toFixed(2) : 0 }}
      </div>
      m
      <div class="cf">海拔高度</div>
    </div>
    <div class="infoBox  cf">
      <div class="dib title "> {{
          props_obj.flyDistance ? props_obj.flyDistance.toFixed(2) : 0
          }}</div>m
      <div class="cf f14"> 飞行里程</div>
    </div>
    <div class="infoBox cf" >
      <div class="dib title">
        {{ props_obj.flyTime ? (props_obj.flyTime / 60).toFixed(2) : 0 }}
      </div>
      min
      <div class="cf">飞行时间</div>
    </div>
    <div class="infoBox cf" >
      <div class="dib title">
        {{ props_obj.groundSpeed ? props_obj.groundSpeed.toFixed(2) : 0 }}
      </div>
      m/s
      <div class="cf">飞行速度</div>
    </div>
    </div>
  <div v-else class='nest_info_maxBox'>
    <div class="infoBox cf">
      <div class="dib title">
     <span  v-if='uavData'>   {{setuavData(uavData.height)}}</span>
     <span v-else>0</span>
      </div>
      m
      <div class="cf infor">飞行高度</div>
    </div>
    <div class="infoBox  cf" >
      <div class="dib title">
       <span v-if='uavData' > {{setuavData(uavData.altitude) }}</span>
       <span v-else>0</span>
      </div>
      m
      <div class="cf">海拔高度</div>
    </div>

    <div class="infoBox  cf" >
      <div class="dib title "> 
       <span v-if='uavData'> {{ setuavData(uavData.flightDistance)}}</span>
       <span v-else>0</span>
          </div>m
      <div class="cf f14"> 飞行里程</div>
    </div>
    <div class="infoBox  cf">
      <div class="dib title "> 
       <span v-if='uavData'> {{ setuavData(uavData.distanceToHome)}}</span>
        <span v-else>0</span>
          </div>m
      <div class="cf f14">起点距离</div>
    </div>

    <div class="infoBox cf" >
      <div class="dib title">
       <span v-if='uavData'> {{ setuavData(uavData.flightTime,'time')}}</span>
       <span v-else>0</span>

      </div>
      min
      <div class="cf">飞行时间</div>
    </div>
   
    <div class="infoBox cf" >
      <div class="dib title">
        <span v-if='uavData'> {{ setuavData(uavData.groundSpeed)}}</span>
       <span v-else>0</span>
      </div>
      m/s
      <div class="cf">飞行速度</div>
    </div>
    <div class="infoBox cf" >
      <div class="dib title">
        <span v-if='uavData' :style="up ? 'color: #ff2626':'color: #00f5ff'"> {{ setuavData(uavData.climbRate)}}</span>
       <span v-else>0</span>
      </div>
      m/s
      <div class="cf">爬升率</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    uavData: {
      type: Object,
      default: () => ({})
    },
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      }
    }
  },
  data() {
    return {
      // stationType:0
      up: false
    };
  },
  watch: {
    uavData: function(val, old) {
      if (!val) {
        return;
      }

      if (val.velocityZ > 0 || val.climbRate > 0) {
        this.up = true;
      } else {
        this.up = false;
      }
    }
    // device: function(val){
    //     this.stationType = val.stationType
    // }
  },
  methods: {
    setuavData(text, type) {
      if (type == "time") {
        if (Number(text)) {
          return (Number(text) / 60).toFixed(2) || 0;
        }
        return 0;
      }
      if (text) {
        return Number(text).toFixed(2) || 0;
      }
      return 0;
    }
  },
  mounted() {
    // this.stationType = this.device.stationType
  },
  computed: {
    props_obj() {
      let { uavData } = this;
      //

      if (
        uavData &&
        uavData.attitude &&
        uavData.locationCoordinate3D &&
        uavData.gps
      ) {
        let sleep = 0;
        let x = uavData.velocityX * uavData.velocityX;
        let y = uavData.velocityY * uavData.velocityY;
        let z = uavData.velocityZ * uavData.velocityZ;
        sleep = Math.sqrt(x + y + z);

        return {
          ...uavData.attitude,
          ...uavData.gps,
          sleep,
          groundSpeed: uavData.groundSpeed,
          ...uavData.locationCoordinate3D,
          flyDistance: uavData.flyDistance,
          flyTime: uavData.flyTime / 1000,
          velocityX: uavData.velocityX,
          velocityY: uavData.velocityY,
          velocityZ: uavData.velocityZ
        };
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.nest_info_maxBox {
  width: 729px;
  height: 91px;
  display: flex;
  justify-content: space-around;
  .infoBox {
    text-align: center;
    margin-top: 22px;
    width: 100px;
    white-space: nowrap;

    .title {
      font-family: UniDreamLED;
      font-size: 30px;
      color: #00f5ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  .infoBox {
    text-align: center;
    margin-top: 22px;
    width: 100px;

    .title {
      font-family: UniDreamLED;
      font-size: 20px;
      color: #00f5ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>
