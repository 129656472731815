var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cpt-observe-nest-tools"},[_c('div',{staticClass:"tools-box"},[_c('div',{staticClass:"tools-item",on:{"click":function($event){return _vm.handle_play('in', {
          left: 500,
          top: 250,
          width: 339,
          height: 224,
        })}}},[_vm._m(0),_c('div',{staticClass:"text-box"},[_vm._v("舱内视频")])]),_c('div',{staticClass:"tools-item",on:{"click":function($event){return _vm.handle_play('out', {
          left: 500,
          top: 474,
          width: 339,
          height: 224,
        })}}},[_vm._m(1),_c('div',{staticClass:"text-box"},[_vm._v("舱外视频")])]),_c('div',{staticClass:"tools-item",on:{"click":function($event){return _vm.handle_play('uav', {
          left: 832,
          top: 474,
          width: 339,
          height: 224,
        })}}},[_vm._m(2),_c('div',{staticClass:"text-box"},[_vm._v("无人机视频")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-box"},[_c('span',{staticClass:"iconfont icon-dandianshipin"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-box"},[_c('span',{staticClass:"iconfont icon-dandianshipin"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-box"},[_c('span',{staticClass:"iconfont icon-dandianshipin"})])}]

export { render, staticRenderFns }