<template>
  <div class="traffic dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon" />
      <div class="dialog-header__title">交通指引</div>
        <div class="dialog-header_close" style="color:#fff" @click="()=>$emit('close')">关闭</div>
    </div>
    <div class="dialog-content">
      <div class="step active">
        <div class="step-box">
          <div class="step-icon">1</div>
          <div class="step-desc">无人机飞往交通事故现场进行拍摄取证</div>
        </div>
        <div class="btn-box1027">
          <div class="btn1027" @click="onTakePhoto">取证</div>
        </div>
        <div class="imgList mt8" v-if="xcimg">
          <el-image
            style="width: 110px;height: 88px"
            :src="imgApi + xcimg"
            fit="cover"
            :preview-src-list="[imgApi + xcimg]"
          ></el-image>
        </div>
      </div>
      <div class="step">
        <div class="step-box">
          <div class="step-icon">2</div>
          <div class="step-desc">激活事故绘制软件并上传事故图</div>
        </div>
        <div class="btn-box1027">
          <div class="btn1027" @click="onActiveApp">激活软件</div>
          <el-upload
            class="dib"
            accept=".png, .jpg, .image, .jpeg"
            :action="upLoadApi + '/upload/v2_uploads'"
            :on-success="onSuccess"
            multiple
            name="uploadFiles"
            :show-file-list="false"
          >
            <div class="btn1027" style="margin-right: 0">上传事故图</div>
          </el-upload>
        </div>
        <div class="imgList mt8" v-if="image">
          <el-image
            style="width: 110px;height: 88px"
            :src="imgApi + image"
            fit="cover"
            :preview-src-list="[imgApi + image]"
          ></el-image>
        </div>
      </div>
      <div class="step">
        <div class="step-box">
          <div class="step-icon">3</div>
          <div class="step-desc">生成交通事故报告</div>
        </div>
        <div class="btn-box1027">
          <div class="btn1027" @click="onExportReport">导出报告</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/assets/ligature.js";
import { saveAs } from "file-saver";
import Moment from "moment";
import API from "@/api";

export default {
  props: {
    uavId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      upLoadApi: process.env.VUE_APP_UPLOADS_URL,
      imgApi: process.env.VUE_APP_IMG_URL,
      imageUrl: [],
      imageName: [],
      baseList: [],
      image: null,
      xcimg: null
    };
  },
  computed: {
    taskId() {
      return this.$store.state.fckernel.taskId;
    },
    user_info() {
      return this.$store.state.user.user_info;
    }
  },
  methods: {
    //拍照
    async onTakePhoto() {
      console.log("uav_take_photo emit");
      Bus.$emit("take_photo", {
        callback: async blob => {
          console.log("uav_take_photo callback");
          const moment = new Moment();
          const dateTime = moment.format("yyyyMMddhhmmss");
          saveAs(blob, `${dateTime}.jpg`);
          let fd2 = new FormData();
          fd2.append("uploadFile", blob, `拍照.png`);
          fd2.append("taskId", this.taskId);
          fd2.append("deviceHardId", this.uavId);
          let res2 = await API.FCKERNEL.Upload(fd2);
          console.log(res2,"urlrel");
          this.xcimg = '/uploads'+ res2.fileKey;
          this.$message.success("取证成功！");
          this.imageUrl.push(this.xcimg);
          this.imageName.push('拍照.png');
          let res1 = await API.ACCIDENT.createTaskWord({
          imageUrl: this.imageUrl,
          imageName: this.imageName,
          taskId: this.taskId,
          baseList: null
        });
        this.imageName = [];
        this.imageUrl = [];
        }
      });
    },
    //激活程序
    onActiveApp() {
      window.open(`TrafficAccident://`, "_blank");
    },
    //导出报告
    onExportReport() {
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/task/exportTaskWord?id=${this.taskId}&FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
      const fileName = url;
      window.open(fileName);
    },
    //图片上传成功
    async onSuccess(res, file, fileList) {
      console.log("onSuccess", res);
      if (res.status == 1) {
        this.imageUrl.push(res.data[0].url);
        this.imageName.push(res.data[0].name);
        this.baseList.push(res.data[0].encode);
        this.image = res.data[0].url;
        let res1 = await API.ACCIDENT.createTaskWord({
          imageUrl: this.imageUrl,
          imageName: this.imageName,
          taskId: this.taskId,
          baseList: this.baseList
        });
        this.imageName = [];
        this.imageUrl = [];
        this.baseList = [];
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.msg || "上传失败");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.traffic {
  width: 268px;
}

.dialog-content {
  padding: 16px !important;
  height: initial !important;
}

.step {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fff;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    .step-icon {
      background: #1439ff;
    }
  }

  .step-box {
    display: flex;
    // justify-content: space-between;
    align-items: baseline;
  }

  .step-icon {
    width: 18px;
    height: 18px;
    background: #515050;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .step-desc {
    height: 40px;
    line-height: 20px;
  }
}

.btn-box1027 {
  padding: 0 !important;
  justify-content: right;
}

.btn1027 {
  padding: 3px 8px;
  min-width: 64px;
}
</style>