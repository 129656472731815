<template>
<video
      style="width: 100%; height: 100%; background-color: #000; object-fit: cover;"
      controls
      src="https://fc-transvideo.baidu.com/cbb56918bd7bb2db358298352b7d286d_1920_1080.mp4"
      preload="auto"
    ></video>
  <!-- <div class="cpt-player-video">
    <video
      ref="player_videojs"
      class="video-js vjs-fluid vjs-default-skin"
      style="width: 100%; height: 100%; background-color: #000; object-fit: cover;"
      controls
      src="https://fc-transvideo.baidu.com/cbb56918bd7bb2db358298352b7d286d_1920_1080.mp4"
      preload="auto"
    ></video>
  </div> -->
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";

class Parser {
  static uavDataParser(metadata) {
    if (!metadata) {
      return metadata;
    }
    let result = null;
    if (typeof metadata === "string") {
      return JSON.parse(metadata);
    }
    return result;
  }
}

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    init(data) {
      this.$nextTick(() => {
        let video = videojs(
          this.$refs["player_videojs"],
          {
            autoplay: false,
            bigPlayButton: false,
            textTrackDisplay: false,
            posterImage: true,
            errorDisplay: false,
            controlBar: {
              pictureInPictureToggle: false,
            },
            flash: {
              swf: `swf/video-js.swf`,
            },
          },
          function () {
            // this.play()
          }
        );

        video.src({
          type: "application/x-mpegURL",
          //src: 'http://live.mmcuav.cn/hls/shouguang01.m3u8'
          src: data.vUrl,
        });

        video.ready(function () {
          this.play();
        });
      });
    },
  },
  watch: {
    data: {
      handler(value) {
        value && this.init(value);
      },
      deep: true,
      immediate: true
    },
  }
};
</script>

<style lang="scss" scoped>
.cpt-player-video {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>