<template>
  <div class="cpt-observe-nest-tools">
    <div class="tools-box">
      <div
        class="tools-item"
        @click="
          handle_play('in', {
            left: 500,
            top: 250,
            width: 339,
            height: 224,
          })
        "
      >
        <div class="icon-box">
          <span class="iconfont icon-dandianshipin"></span>
        </div>
        <div class="text-box">舱内视频</div>
      </div>
      <div
        class="tools-item"
        @click="
          handle_play('out', {
            left: 500,
            top: 474,
            width: 339,
            height: 224,
          })
        "
      >
        <div class="icon-box">
          <span class="iconfont icon-dandianshipin"></span>
        </div>
        <div class="text-box">舱外视频</div>
      </div>
      <div
        class="tools-item"
        @click="
          handle_play('uav', {
            left: 832,
            top: 474,
            width: 339,
            height: 224,
          })
        "
      >
        <div class="icon-box">
          <span class="iconfont icon-dandianshipin"></span>
        </div>
        <div class="text-box">无人机视频</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    play(
      rtsp,
      { left = 268, top = 394, width = 339, height = 224 },
      title = "video"
    ) {
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.href = `MMCPlayer.FFME://&Tile=${title}endTile&Video=${rtsp}endVideo&Left=${left}endLeft&Top=${top}endTop&Width=${width}endWidth&Height=${height}endHeight`;
      a.dispatchEvent(event);
    },
    /**
     * mmc player
     */
    // handle_play (type, args) {
    //   let { video } = this;
    //   if (video) {
    //     console.log("rtsp video:", this.video);
    //     this.play(video[type], args, type);
    //   }
    // },
    handle_play(type, args) {
      let { video } = this;
      if (video) {
        this.$emit("video-play", type, video);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-observe-nest-tools {
  position: fixed;
  top: 88px;
  right: 0;
  .tools-box {
    width: 80px;
    .tools-item {
      width: 100%;
      height: 77px;
      background-color: rgba(6, 33, 73, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #8e8e8e;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
      &.active {
        color: #ffffff;
      }
      .icon-box {
        width: 36px;
        height: 36px;
        overflow: hidden;
        .iconfont {
          font-size: 36px;
        }
      }
      .text-box {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
</style>