<template>
  <div class="wih100 ht100">
    <div class="nest_search pl16 mt16">
      <div class="flexs waikuang" v-if="type != 'VPN' && type != 'SPE'">
        <el-input
          size="mini"
          v-model="search.key"
          class="input-name"
          placeholder="请输入任务名称"
          clearable
        />
      </div>
      <div class="flexs waikuang seat" v-else></div>
      <div class="flexs waikuang shijanSelect">
        <el-date-picker
          size="mini"
          prefix-icon="ccc"
          v-model="pickTime"
          type="datetimerange"
          range-separator="至"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          popper-class="flight-log"
        ></el-date-picker>
      </div>
      <el-button size="mini" class="search__btn" @click="onSearch">搜索</el-button>
    </div>
    <div class="lists" :class="type == 'VPN' || type == 'SPE' ? 'signal' :'nor'" v-if="messageList.length > 0 || signalList.length > 0" >
      <div class="items" v-if="type == 'VPN' || type == 'SPE'">
        <div class="item" v-for="item in signalList" :key="item.id">
          <div
            class="box"
            :class="
              item.signaltype == 1
                ? 'warn'
                : item.signaltype == 2
                ? 'serious'
                : ''
            "
          >
            <div class="content"><span>预警内容：</span><div>{{ item.content || "暂无" }}</div></div>
            <div class="content">飞行高度：<div>{{ item.height || "0" }}</div></div>
            <div class="content">飞行速度：<div>{{ item.speed || "0" }}</div></div>
            <div class="content"><span>无人机位置：</span><div>{{ item.address || "暂无" }}</div></div>
            <div class="content">预警时间：<div>{{ item.createTime || "暂无" }}</div></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info_single" v-for="item in messageList" :key="item.id">
          <div class="name">{{item.exceptionTitle}}</div>
          <div class="task">{{item.taskTile}}</div>
          <div class="time">{{item.addTime}}</div>
        </div>
      </div>
    </div>
    <div v-if="!flag" class="cf tc" style="margin: 100px auto">加载中...</div>
    <div v-else-if="flag && messageList.length == 0 && signalList.length == 0" class="cf tc" style="margin: 100px auto">暂无数据！</div>
    <div class="page">
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="prev, pager, next"
        :total="pagination.totalCount"
      ></el-pagination>
      <!-- <div class="zhuan">
        <span>前往</span>
        <input type="text" v-model="qianVal" />
        <span>页</span>
      </div>-->
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  data() {
    return {
      search: {
        key: null,
        type: "",
        pageNo: 1,
        pageSize: 10,
      },
      pickTime: null,
      qianVal: null,
      signalList: [],
      messageList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 100,
      },
      flag: false,
    };
  },
  props: {
    type: {
      type: String,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    console.log(this.device.deviceList[0]);
    this.search.type = this.type;
    if (this.type == "VPN" || this.type == "SPE") {
      this.getStationSignalLog();
    } else {
      this.onSearch();
    }
  },
  methods: {
    Search() {
      if (this.type == "VPN" || this.type == "SPE") {
      this.getStationSignalLog();
    } else {
      this.onSearch();
    }
    },
    async onSearch() {
      this.search.deviceId = this.device.deviceList[0].deviceId;
      if (this.pickTime) {
        this.search.startTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
      this.flag = false
      let res = await API.FCKERNEL.findAll(this.search);
      this.pagination.totalCount = res.total;
      this.messageList = res.data;
      this.flag = true
    },
    handle_current_change(e) {
      this.search.pageNo = e;
      if (this.type == "VPN" || this.type == "SPE") {
      this.getStationSignalLog();
    } else {
      this.onSearch();
    }
    },
    async getStationSignalLog() {
      if (this.pickTime) {
        this.search.startTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
      let data = {
        startTime: this.search.startTime,
        endTime:this.search.endTime,
        pageNo: this.search.pageNo,
        pageSize: this.search.pageSize,
        deviceName: this.device.deviceList[0].deviceHardId,
        mark: this.type == "VPN" ? "公网" : this.type == "SPE" ? "专网" : "",
      };
      this.flag = false
      let res = await API.FCKERNEL.getStationSignalLog(data);
      this.signalList = res.records
      this.flag = true
      this.signalList.forEach((val) => {
        if (this.type == "SPE") {
          if (val.sinr) {
            if (val.sinr < -8 && val.sinr > -13) {
              val.signaltype = 1;
            } else if (val.sinr <= -13) {
              val.signaltype = 2;
            }
          } else if (val.rsrp) {
            if (val.rsrp < -110 && val.rsrp > -115) {
              val.signaltype = 1;
            } else if (val.rsrp <= -115) {
              val.signaltype = 2;
            }
          } else {
            val.signaltype = 0;
          }
        } else {
          if (val.sinr) {
            if (val.sinr < -5 && val.sinr > -10) {
              val.signaltype = 1;
            } else if (val.sinr <= -10) {
              val.signaltype = 2;
            }
          } else if (val.rsrp) {
            if (val.rsrp < -107 && val.rsrp > -112) {
              val.signaltype = 1;
            } else if (val.rsrp <= -112) {
              val.signaltype = 2;
            }
          } else {
            val.signaltype = 0;
          }
        }
      });
      console.log(res, "res");
    },
  },
};
</script>

<style scoped lang='scss'>
.nest_search {
  display: flex;
  .waikuang {
    width: 170px;
    height: 32px;
    background: #02173d;
    border-radius: 2px;
    border: 1px solid #315ec7;
    align-items: center;
    margin: 0 14px 0 0;
  }
  .search__btn {
    padding: 7px 25px 6px 26px;
    // background: rgba(38, 71, 238, 0.71);
    background: url("~@/assets/images/observe/twobg.png");
    border-radius: 4px;
    border: 1px solid;
    font-size: 14px;
    color: #fff;
  }
  .flexs {
    display: flex;
  }
  .shijanSelect {
    width: 300px;
  }
  // 所有输入框样式
  /deep/ .waikuang .input-name .el-input__inner {
    background: rgba(13, 50, 92, 0) !important;
    border: 0px solid #43deff !important;
    align-items: center !important;
    padding-left: 5px !important;
  }
  /deep/.el-date-editor .el-range-input {
    background: rgba(2, 31, 51, 0.1);
    // width: 390px;
    color: #fff;
  }

  //时间选择器样式
  /deep/ .waikuang .el-range-input {
    height: 32px !important;
  }

  /deep/ .waikuang .el-date-editor {
    padding: 0;
    border: 0px solid;
    height: 32px;
    background: rgba(13, 50, 92, 0) !important;
  }
}
.lists {
  height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  .info_single {
    padding: 12px 16px;
    margin-top: 16px;
    width: 612px;
    height: 58px;
    background: url("~@/assets/images/observe/fckernel/health/listbg.png")
      no-repeat;
    background-size: 100% 100%;
    .name {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffbd36;
      margin-bottom: 18px;
    }
    .task {
      float: left;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ebf9ff;
    }
    .time {
      float: right;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(235, 249, 255, 0.8);
    }
  }
  .items {
    margin: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    color: #fff;
    .item {
      width: 48%;
      height: 160px;
      line-height: 24px;
      .box {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        padding-left: 8px;
        // padding: 10px;
        height: 90%;
        border-radius: 5px;
        .content{
          display: flex;
          span{
            white-space: nowrap;
          }
          div{
            color: #00dfff;
          }
        }
      }
      .warn {
        background: url("~@/assets/images/observe/warn.png") no-repeat;
        background-size: 100% 100%;
      }
      .serious {
        background: url("~@/assets/images/observe/serious.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .item:nth-of-type(2n){
      margin-right: 12px;
    }
  }
}
.nor{
  height: 290px;
}
.signal{
  max-height: 450px;
}
.page {
  padding-right: 16px;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  /deep/.el-pager .number:hover {
    border-radius: 2px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    background: rgba(38, 71, 238, 0.71) !important;
    width: 14px;
    height: 22px;
    line-height: 22px;
  }

  /deep/ .el-pagination--small .el-pager .number {
    background: transparent;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #889fb2;
  }
  /deep/.el-pagination button {
    background: transparent;
    color: #889fb2;
    opacity: 0.3;
  }
  /deep/.el-pagination .el-pager .more {
    background: transparent;
    color: #889fb2;
  }
  /deep/.active {
    color: #ffffff;
    background: rgba(38, 71, 238, 0.71) !important;
  }
  .zhuan {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #b6d4ff;
    input {
      width: 60px;
      height: 24px;
      text-align: center;
      border-radius: 2px;
      border: 1px solid #b6d4ff;
      outline: 0px solid;
    }
  }
}
</style>