<template>
  <Box class="nest_create_Box">
    <div slot="nset__left">
      <div class="nestLeft">
        <div class="dib w3 h16" style="background: #ffbd36" />
        {{ CreateTaskType == "itemC" ? "周期任务" : "定时任务" }}
      </div>
    </div>
    <div></div>
    <div slot="nset__right">
      <div class="nestRight jcsb mr20 cp">
        <div class="dib" @click="addClick">
          <span class="vam cp f12 iconfont cfc icon-zengjia"></span>
          <span class="f12">添加任务</span>
        </div>
        <div class="dib ml20 f18" @click="close_tan">关闭</div>
      </div>
    </div>
    <!-- 定时任务 start-->
    <div class="timing-control-area mt10" v-if="CreateTaskType == 'itemB'">
      <div class="itemList" v-for="(item, i) in TaskItem" :key="i">
        <el-checkbox class="w17 h17" v-model="TaskItem[i].checked" />
        <el-date-picker
          size="mini"
          class="w188"
          :value="TaskItem[i].voyageTime"
          @input="onDateInput($event, i)"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择时间"
          :picker-options="pickerOptions"
        ></el-date-picker>
        <span class="cf">任务名称</span>
        <el-select
          size="mini"
          class="w200 mt10"
          @change="selectTask(TaskItem[i].taskId, i, TaskItem[i])"
          v-model="TaskItem[i].taskId"
          clearable
          placeholder="请选择任务"
        >
          <el-option
            v-for="item in TaskList"
            :key="item.id"
            :label="item.taskTitle"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          disabled
          placeholder="航线名称"
          v-model="TaskItem[i].flightName"
          class="w140"
          size="mini"
        ></el-input>
        <!-- icon-shanchu3 -->
        <el-tooltip content="删除" placement="top">
          <span
            class="iconfont icon-shanchu dib w20 h20 cp"
            style="color: #43deff"
            @click="delFn(i)"
          ></span>
        </el-tooltip>

        <el-tooltip content="预览航线" placement="top">
          <span
            class="icon-chakan1 iconfont dib w30 cp lh30 vas f10"
            style="color: #43deff"
            @click="showRoute(TaskItem[i])"
          ></span>
        </el-tooltip>
      </div>
    </div>
    <div
      class="btn mt37 cf cp lh32 cpp w122 h32"
      v-if="TaskItem.length > 0 && CreateTaskType == 'itemB'"
      @click="handClick"
    >
      确认
    </div>
    <!-- 定时任务 end -->
  </Box>
</template>
<script>
import Box from "@/components/observe/fckernel/nest/control/components/box";
import API from "@/api";
import { Utils } from "@/lib/cesium";
let airline_entitys = [];
let air_line_preview = [];
export default {
  components: {
    Box,
  },
  props: {
    CreateTaskType: {
      type: String,
      default: () => {
        "";
      },
    },
  },
  data() {
    return {
      TaskItem: [],
      TaskList: [],
      startTime: "",
      endTime: "",

      pickerOptions: {
        //    shortcuts: [{
        //   text: '今天',
        //   onClick(picker) {
        //     picker.$emit('pick', new Date());
        //   }
        // }],ppp
        disabledDate(time) {
          // return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
          return time.getTime() < Date.now() - 8.64e7; // 设置选择今天以及今天之后的日
        },
        selectableRange: `${Date().split(" ")[4]} - 23:59:59`,
      },
    };
  },
  inject: ["nest_this"],
  created() {
    this.init();
  },
  beforedestroy() {
    airline_entitys.forEach((item) => {
      window.viewer.entities.remove(item);
    });
    air_line_preview.forEach((item) => {
      window.viewer.entities.remove(item);
    });
  },
  methods: {
    close_tan() {
      airline_entitys.forEach((item) => {
        window.viewer.entities.remove(item);
      });
      air_line_preview.forEach((item) => {
        window.viewer.entities.remove(item);
      });
      this.$emit("exit");
    },
    // 时间修正--第二天时间可以全选
    onDateInput(time, i) {
      this.TaskItem[i].voyageTime = time;
      if (time) {
        let date = new Date(); //当前时间
        let min = date.getMinutes(); //当前时间的分
        date.setMinutes(min + 30);
        let nowDate = this.$moment(date).format("HH:mm:ss"); //当前时分秒
        let st = "";
        if (
          this.$moment(date).format("yyyy-MM-DD") ===
          this.$moment(time).format("yyyy-MM-DD")
        ) {
          let hh1 = this.$moment(time).format("HH:mm:ss");
          if (hh1 <= nowDate) {
            this.TaskItem[i].voyageTime = this.$moment(date).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          st = nowDate;
        } else {
          st = "00:00:00";
        }

        this.pickerOptions.selectableRange = st + "-23:59:59";
        this.pickerOptions = this.pickerOptions;
        console.log(
          "this.pickerOptions.selectableRange",
          this.pickerOptions.selectableRange
        );
      }
    },
    addClick() {
      // 定时任务
      if (this.CreateTaskType == "itemB") {
        this.TaskItem.push({
          checked: false,
          flightName: "",
          voyageTime: "",
        });
      }
    },
    // 预览航线
    showRoute(item) {
      console.log(item, "item");
      this.handleAirLinePreview(item, false);
    },
    // 显示距离
    create_label(before, after) {
      let viewer = window.viewer;
      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
      airline_entitys.push(label_entity);
    },
    async handleAirLinePreview(item, state) {
      console.log(item, state, "item---------");
      try {
        let viewer = window.viewer;
        if (airline_entitys?.length) {
          airline_entitys.forEach((item) => {
            viewer.entities.remove(item);
          });
        }
        let res = {};
        air_line_preview.forEach((item) => {
          viewer.entities.remove(item);
        });
        if (state) {
          res.flightCourseJson = item.flightCourseJson;
        } else {
          if (item.flightLineId) {
            res = await API.TASK.JingQingTrack({
              taskId: item.taskId,
            });
          }
        }

        //     // 有航点高度和距离的代码
        if (res?.flightCourseJson) {
          let positions = [];
          let center = [];
          let polyline = JSON.parse(res.flightCourseJson);
          let label_arr = [];
          let _this = this;

          polyline.points.forEach((item, index) => {
            positions.push(item.lon, item.lat, item.alt);
            label_arr.push(
              Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            );
            let point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: "show_airline_point",
              point: {
                pixelSize: 10,
                color: Cesium.Color.fromCssColorString("#00A9A9"),
              },
              label: {
                text: String(item.alt) + "m",
                scale: 0.6,
                font: "bold 28px Microsoft YaHei",
                // fillColor: Cesium.Color.BLUE,
                fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            });
            airline_entitys.push(point_entity);
            if (label_arr.length > 1) {
              let before = label_arr[label_arr.length - 2];
              let after = label_arr[label_arr.length - 1];

              _this.create_label(before, after);
            }
          });
          positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);
          let redLine = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions,
              width: 4,
              material: Cesium.Color.fromCssColorString("#00A9A9"),
            },
          });

          viewer.flyTo(redLine);
          airline_entitys.push(redLine);
        } else {
          this.$el_message("暂无航线", () => {}, "error");
        }
      } catch (error) {
        console.log(error, "error");
        this.$el_message("暂无航线", () => {}, "error");
      }
    },
    handClick() {
      airline_entitys.forEach((item) => {
        window.viewer.entities.remove(item);
      });
      air_line_preview.forEach((item) => {
        window.viewer.entities.remove(item);
      });
      let TaskItem = [];
      try {
        let _this = this;
        let ids = [];
        if (this.TaskItem.length > 0) {
          let arr = this.TaskItem.map((e, i) => {
            if (e.checked) {
              if (e.voyageTime == "" || e.taskId == "") {
                throw "请填写完整信息";
              } else {
                ids.push(i);
                return API.AIRWAY.GetAirwayInfo(e.flightLineId);
              }
            } else {
              return this.$message.warning("请选择任务");
            }
          });
          Promise.all(arr).then((res) => {
            let endTime = null;
            let taskType = 0;
            // if (this.CreateTaskType == "itemC") {
            //   if (this.endTime == "") {
            //     return this.$message.warning("请选择周期结束日期");
            //   }
            //   endTime = this.endTime;
            //   taskType = 1;
            // }

            res.forEach((data, i) => {
              // waypointList
              TaskItem.push({
                taskType,
                taskName: _this.TaskItem[ids[i]].taskName,
                nestId: this.nest_this.nest.data.deviceHardId,
                fightLine: data.data,
                taskId: this.TaskItem[ids[i]].taskId,
                flightLineId: this.TaskItem[ids[i]].flightLineId,
                voyageTime: this.TaskItem[ids[i]].voyageTime,
              });
            });
            API.AIRWAY.addTimingTask(TaskItem).then(async (res) => {
              if (res.code == "201") {
                this.$message.warning(res.data);
              } else {
                this.$message.success("操作成功");
                // 记录任务
                // let rw = await API.FCKERNEL.addTaskAndUserRelation({
                //   taskId: this.TaskItem[ids[i]].taskId,
                //   sourceType: 1, //鹰巢为1 无人机0
                //   deviceHardId: this.nest_this.nest.data.deviceHardId
                // });

                this.$emit("exit");
                this.TaskItem = [];
                this.TaskList = [];
                this.startTime = "";
                this.endTime = "";
              }
            });
          });
        }
      } catch (error) {
        this.$message.warning(error);
      }
    },

    selectTask(taskId, i, item) {
      let flightData = this.TaskList.filter((e) => e.id == taskId);
      this.TaskItem[i].flightName = flightData[0]?.flightName || "";
      this.TaskItem[i].flightLineId = flightData[0]?.flightLineId || "";
      this.TaskItem[i].taskName = flightData[0]?.taskTitle || "";
      // 直接调预览
      this.showRoute(item);
    },
    delFn(i) {
      this.TaskItem.splice(i, 1);
    },
    async init() {
      let res = await API.AIRWAY.getNestApprovedTask({
        nestId: this.nest_this.nest.data.id,
      });
      this.TaskList = res;
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-time-panel__footer {
    background: #0b1138 !important;
  }

  .el-time-spinner__item.active:not(.disabled) {
    color: #999;
  }

  .el-time-spinner__item:hover:not(.disabled):not(.active) {
    background: #0d224f;
  }

  .el-time-spinner__item {
    color: #fff;
  }

  .el-time-spinner__item:hover {
    background: #0b1138;
  }

  .el-time-panel__btn {
    color: #fff;
  }

  .el-scrollbar {
    background: #0d224f;
    border: 1px solid #00aeff;
  }

  .nest_create_Box {
    .el-input__inner {
      background: #0b1138 !important;
      color: #08ffff;
      border: 1px solid rgba(8, 255, 255, 0.49) !important;
    }

    .el-scrollbar__view {
      background: #0d224f;
      border: 1px solid #00aeff;
    }

    .btn {
      margin: 0 auto;
      text-align: center;
      background-image: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.22) 40%,
        #05091a 100%
      );
      border: 1px solid #70daf9;
      box-shadow: inset 0 0 10px 2px #3f9dff;
    }
  }

  .nestLeft {
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #70daf9;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;

    .nestLeft_icon {
      width: 2px;
      height: 100%;
      background: #ffbd36;
    }
  }

  .nestRight {
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #70daf9;
    letter-spacing: 0;
    font-weight: 400;
  }

  .timing-control-area {
    max-height: 200px;
    overflow-y: auto;

    .itemList {
      justify-content: space-between;
      display: flex;
      margin-bottom: 10px;
      align-items: baseline;
    }
  }
  .el-picker-panel__footer .el-button--text {
    span {
      display: none !important;
    }
    &::after {
      content: "预约时间" !important;
      display: block !important;
    }
  }

  .el-dialog {
    background-image: none !important;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid #70daf9;
  }

</style>