<!-- 鹰巢任务库 -->
<template>
  <!-- w500: taskType == 'itemB',w416: taskType == 'itemA' -->
  <div class="taskListBox w416" :class="{ collapse, yidong: wiezhi == true }">
    <div class="header wih100 h32">
      <div
        class="dib headerLable h32 pr tc lh32 cp"
        :class="taskType == 'itemA' ? 'active' : 'default'"
        @click="changeBtn('itemA')"
      >
        常态飞行
        <!-- <img
          v-show="taskType == 'itemA'"
          class="pa left0 top0"
          src="@/assets/images/observe/fckernel/nest/xz.png"
          alt
        />-->
      </div>
      <div
        class="dib headerLable h32 tc lh32 cp pr"
        :class="taskType == 'itemB' ? 'active' : 'default'"
        @click="changeBtn('itemB')"
      >
        定时飞行
        <!-- <img
          v-show="taskType == 'itemB'"
          class="pa left0 top0"
          src="@/assets/images/observe/fckernel/nest/xz.png"
          alt
        />-->
      </div>
      <div
        class="dib headerLable h32 tc lh32 cp pr"
        :class="taskType == 'itemC' ? 'active' : 'default'"
        @click="changeBtn('itemC')"
      >
        周期飞行
        <!-- <img
          v-show="taskType == 'itemC'"
          class="pa left0 top0"
          src="@/assets/images/observe/fckernel/nest/xz.png"
          alt
        />-->
      </div>
    </div>

    <div v-show="taskType == 'itemA'" class="jianju pt38">
      <div style="display: flex; align-items: center">
        <span class="cf fontOne">任务库</span>
        <el-tooltip :content="TaskListIndex" placement="top">
          <el-select
            size="mini"
            class="flexOne"
            v-model="flyTypeId"
            placeholder="请选择任务"
            :disabled="taskCate == 3 ? true : false"
          >
            <el-option
              v-for="item in TaskList"
              :key="item.id"
              :label="item.taskTitle"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-tooltip>
      </div>
      <div style="display: flex; align-items: center; margin: 16px 0px 0 0">
        <div class="cf fontOne">
          航
          <i style="opacity: 0">任</i>线
        </div>
        <el-tooltip content="航线名称" placement="top">
          <el-input
            v-if="flightFlag"
            disabled
            v-model="flight[0].flightName"
            placeholder="请选择航线"
            class="flexOne"
            size="mini"
          ></el-input>
          <el-button v-else @click="selectLine" class="kbt_button"
            >选择航线</el-button
          >
          <!-- <el-select
            v-else
            size="mini"
            class="flexOne"
            v-model="flightId"
            placeholder="请选择航线"
          >
            <el-option
              v-for="item in flightList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>-->
        </el-tooltip>
      </div>
      <div
        style="display: flex; align-items: center; margin: 16px 0px 0 0"
        v-if="wsShow"
      >
        <div class="cf fontOne">返航高度</div>
        <el-input-number
          @change="(val) => handle_change_field('gimbalPitch', val)"
          v-model="returnHeight"
          :disabled="taskCate == 3 ? true : false"
          :min="20"
          :max="150"
          label="描述文字"
          oninput.native="value=value.replace(/[^0-9.]/g,'')"
        ></el-input-number>
      </div>
      <div class="anniu" :class="wsShow ? 'mt24' : ''">
        <div style="flex: 1"></div>
        <div class="btn cf ml18 cp" @click="handClickTask">一键任务</div>
        <div style="flex: 1; text-align: end">
          <span
            v-if="!flightFlag"
            style="color: #43deff"
            class="lh30 mr10 dib cp f8"
            @click="$emit('craeteRoute')"
          >
            <span class="iconfont icon-zengjia f8"></span> 创建航线
          </span>
        </div>
      </div>
    </div>
    <div v-show="taskType == 'itemB' || taskType == 'itemC'">
      <div class="taskList h183 p5">
        <div class="jcsb mb8 mt5 tc rwtitle">
          <span class="w144">名称</span>
          <span class="w190">时间</span>
          <span class="w100">状态</span>
          <span class="w80">操作</span>
        </div>
        <div
          class="jcsb mb16 tc rwinfo f14"
          v-for="(item, index) in rwList"
          :key="index"
        >
          <el-tooltip :content="item.taskTitle" placement="top">
            <span class="w144 Titlehideen">{{ item.taskTitle }}</span>
          </el-tooltip>
          <el-tooltip placement="top" :enterable="false">
            <div slot="content">
              {{ item.voyageTime }}
              <br />
              {{ item.endTime }}
            </div>
            <span class="w190 Titlehideen">{{ item.voyageTime }}</span>
          </el-tooltip>
          <span
            class="w100"
            style="color: #43deff"
            v-show="item.taskStatus == 3"
            >执行中</span
          >
          <span
            class="w100"
            style="color: #ffbd36"
            v-show="item.taskStatus == 1"
            >执行完成</span
          >
          <span
            class="w100"
            style="color: #ffbd36"
            v-show="item.taskStatus == 0"
            >待执行</span
          >
          <div class="jcsb">
            <el-tooltip content="查看" placement="top">
              <span
                class="icon-chakan1 iconfont dib w30 cp lh30 vas f10"
                style="color: #ffffff"
                @click="showRoute(item)"
              ></span>
            </el-tooltip>
            <el-tooltip content="历史" placement="top" v-if="!wsShow">
              <img
                class="mt7"
                style="width: 15px; height: 15px"
                src="./history.png"
                alt
                @click="history(item)"
              />
            </el-tooltip>
            <el-tooltip content="禁用" v-if="!item.enable" placement="top">
              <img
                class="mt6 ml5"
                style="width: 17px; height: 17px"
                src="./enable.png"
                alt
                @click="changeEnable(item, 1)"
              />
            </el-tooltip>
            <el-tooltip content="启用" v-if="item.enable" placement="top">
              <img
                class="mt6 ml5"
                style="width: 17px; height: 17px"
                src="./able.png"
                alt
                @click="changeEnable(item, 0)"
              />
            </el-tooltip>
            <el-tooltip content="删除" placement="top">
              <span
                class="icon-shanchu iconfont dib w30 cp lh30 vas f18"
                style="color: #ffffff"
                @click="shanchu(item)"
              ></span>
            </el-tooltip>
          </div>
        </div>
      </div>

      <div class="btn cf cp" @click="createFn">
        <span v-show="taskType == 'itemB'">创建定时任务</span>
        <span v-show="taskType == 'itemC'">创建周期任务</span>
      </div>
    </div>
    <!-- 周期任务 start -->
    <div class="week">
      <div class="pr taskDialog" v-if="dialogVisible">
        <div class="header jcsb" v-interact>
          <div class="ml10">
            <span class="dib w3 h15" style="background: #ffbd36"></span>
            创建周期任务
          </div>
          <div class="mr20 cp" @click="dialogVisible = false">关闭</div>
        </div>
        <el-form
          :model="zqTask"
          ref="zqTaskForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="执行日期">
            <el-date-picker
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              size="mini"
              class="w188"
              v-model="zqTask.startDate"
              type="date"
              placeholder="请选择开始日期"
            ></el-date-picker>
            <el-date-picker
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              size="mini"
              class="w188 ml20"
              v-model="zqTask.endDate"
              type="date"
              placeholder="请选择结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="执行时间">
            <el-time-picker
              value-format="HH:mm:ss"
              class="w188 dib"
              size="mini"
              v-model="zqTask.startTime"
              placeholder="请选择开始时间"
            ></el-time-picker>
            <el-time-picker
              value-format="HH:mm:ss"
              class="w188 ml20"
              size="mini"
              v-model="zqTask.endTime"
              placeholder="请选择结束时间"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="任务名称">
            <el-select
              class="w396"
              size="mini"
              v-model="zqTask.ZQtaskId"
              clearable
              placeholder="请选择任务"
              @change="yulan(zqTask.ZQtaskId, zqTaskList)"
            >
              <el-option
                v-for="item in zqTaskList"
                :key="item.id"
                :label="item.taskTitle"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="jcsb btnBox">
            <div class="w122 h32 lh32 cp tc btnConfrim cf" @click="handClickZq">
              确认
            </div>
            <div
              class="w122 h32 lh32 cp tc closeBtn cf"
              @click="dialogVisible = false"
            >
              取消
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 周期任务 end-->
    <LineList
      v-if="lineshow"
      :nestId="nest_this.nest.data.id"
      @changeLine="changeLine"
      @close="lineshow = false"
    ></LineList>
    <!-- 历史飞行记录 -->
    <HistoryList
      v-if="historyshow"
      :todayshow="todayshow"
      :today="today"
      :nestTaskId="nestTaskId"
      @close="close"
    ></HistoryList>
  </div>
</template>
<script>
import API from "@/api";
import methods from "./methods";
import LineList from "../lineList";
import HistoryList from "../historyList";
// 引用bus
import Bus from "@/assets/ligature.js";
import axios from "axios";
export default {
  components: { LineList, HistoryList },
  data() {
    return {
      whatTaskNum: 2, //执行哪种任务
      historyshow: false, //周期历史飞行记录
      nestTaskId: null, //周期鹰巢任务
      todayshow: false, //是否显示今天
      today: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
        selectableRange: `${Date().split(" ")[4]} - 23:59:59`,
      },
      // 周期任务
      zqTask: {
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        ZQtaskId: "",
      },
      // END周期任务
      taskType: "itemA",
      rwtime: null,
      rwList: [],
      TaskList: [],
      zqTaskList: [],
      TaskListIndex: "任务库",
      flyTypeId: "",
      Form: {},
      dialogVisible: false,
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
      //   },
      // },
      TaskItem: {
        voyageTime: "",
        time: "",
        taskId: "",
      },
      flight: [
        {
          flightName: "",
        },
      ],
      // 航线数据
      flightList: [],
      flightId: "",
      // 航线是否可选
      flightFlag: true,
      // 航线数据
      flightLinefkData: "",
      lsit: {},
      lineshow: false,
      wiezhi: false,
      taskCate: 1, //任务状态
      returnHeight: 120, //返航高度
    };
  },
  inject: ["nest_this"],
  props: {
    wsShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    "$store.state.device.yc_line_load"(val) {
      if (val) {
        this.getNestTaskInProgressFn();
      }
    },
    "$store.state.fckernel.nest_task_srtatus"(val) {
      let deviceHardId = this.active_nestData.deviceHardId;
      let data = this.$store.state.fckernel.nest_task_refresh;
      if (val && data.deviceHardId == deviceHardId) {
        if (data.TimingType == 0) {
          //定时任务
          this.changeBtn("itemB");
        } else {
          //周期任务
          this.changeBtn("itemC");
        }
        this.$store.commit("fckernel/set_state", {
          key: "nset_fk_location",
          value: new Date().getTime(),
        });
      }
    },
    data: {
      handler: async function (val) {
        this.$parent.nest.controlMenuFlag = false;
        this.flyTypeId = "";
        let res = await API.AIRWAY.getNestApprovedTask({
          nestId: this.nest_this.nest.data.id,
        });
        this.TaskList = [
          {
            taskTitle: "选择航线自动生成任务",
            id: "z1322",
          },
          ...res,
        ];
        this.$parent.nest.controlMenuFlag = true;
      },
    },
    flyTypeId: function (value) {
      this.$store.commit("fckernel/SET_TASK_ID", value);
      if (value) {
        this.clearAirLine();
        if (value == "z1322") {
          this.flightFlag = false;
          this.flightId = "";
          this.TaskListIndex = "任务库";
        } else {
          this.flightFlag = true;
          this.flight = this.TaskList.filter((e) => e.id == value);

          this.TaskListIndex = this.flight[0].taskTitle;

          // 航线id
          if (this.flight[0] && this.flight[0].flightLineId) {
            this.saveFlightLineId(this.flight[0].flightLineId);
            this.showRoute(this.flight[0]);
            // eslint-disable-next-line no-empty
          } else {
          }
        }
      }
    },
    flightId: function (value) {
      this.clearAirLine();
      if (value) {
        this.getFlightLine(value);
      }
      // this.handleAirLinePreview(this.flight[0], true);
    },
    "uavData.gps.fixType": function (value) {
      if (value == "LOCK_3D_RTK_GPS_LOCK_FIXED") {
        this.$message.success("RTK已就绪");
        this.fixType = true;
      } else {
        this.fixType = false;
      }
    },
    // 监听创建定时任务弹框，弹框消失时消除航线
    dialogVisible: function (val, old) {
      if (val == false) {
        this.clearAirLine();
      }
    },
  },
  computed: {
    collapse() {
      return this.$store.state.fckernel.nestCollapse;
    },
    active_nestData() {
      // 1 代表ktb 2 代表大疆
      let data = this.$store.state.fckernel.active_nestData;
      return data || null;
    },
  },
  async created() {
    // 搜索关键字：飞控鹰巢左侧任务框位置判定
    Bus.$on("ren_task", (status) => {
      this.wiezhi = status;
    });
    await this.init();
    await this.whatTask();
  },
  beforeDestroy() {
    // 飞控 鹰巢 任务库关闭隐藏航线
    // this.clearAirLine();
  },
  methods: {
    ...methods,
    close() {
      this.historyshow = false;
      this.$emit("close", true);
    },
    selectLine() {
      this.lineshow = true;
      this.$emit("closeflyLog");
    },
    changeLine(id) {
      this.flightId = id;
    },
    // 周期任务航线预览
    yulan(items) {
      let a = null;
      // 根据ID获取下标
      this.zqTaskList.forEach((item, index) => {
        if (item.id == items) {
          a = index;
        }
      });
      let b = this.zqTaskList[a];
      this.showRoute(b);
    },
    // 周期任务
    async handClickZq() {
      let { zqTask } = this;
      if (zqTask.ZQtaskId == "") return this.$message.warning("请选择任务");
      if (zqTask.startDate == "")
        return this.$message.warning("请选择开始日期");
      if (zqTask.startTime == "")
        return this.$message.warning("请选择开始时间");
      if (zqTask.endDate == "") return this.$message.warning("请选择结束日期");
      if (zqTask.endTime == "") return this.$message.warning("请选择结束时间");
      // 获取开始时间戳破
      let b = this.zqTask.startDate + " " + this.zqTask.startTime;
      let a = new Date(b);
      let c = Date.parse(a);
      // 获取结束时间戳
      let d = this.zqTask.endDate + " " + this.zqTask.endTime;
      let e = new Date(d);
      let f = Date.parse(e);
      // 获取当前时间戳
      let g = new Date();
      let h = Date.parse(g);
      // 首先让当前时间与开始时间对比,开始时间不能小于当前时间
      if (c > h) {
        // console.log('正常');
        // 结束时间不能小于开始时间
        if (f > c) {
          // console.log('通过');
        } else {
          return this.$message.warning("结束时间不能小于开始时间");
        }
      } else {
        return this.$message.warning("开始时间不能小于当前时间");
      }
      let flightData = this.TaskList.filter((e) => e.id == zqTask.ZQtaskId);
      let fightLine = await API.AIRWAY.GetAirwayInfo(
        flightData[0].flightLineId
      );
      let TaskItem = {
        taskType: 1,
        voyageTime: zqTask.startDate + " " + zqTask.startTime,
        endTime: zqTask.endDate + " " + zqTask.endTime,
        fightLine: fightLine.data,
        nestId: this.nest_this.nest.data.deviceHardId,
        taskId: zqTask.ZQtaskId,
        flightLineId: flightData[0].flightLineId,
      };
      API.AIRWAY.addTimingTask([TaskItem]).then(async (res) => {
        if (res.code == 201) {
          this.$message.info(res.data);
        } else if (res.code == 200) {
          // 记录任务
          let rw = await API.FCKERNEL.addTaskAndUserRelation({
            taskId: zqTask.ZQtaskId,
            sourceType: 1, //鹰巢为1 无人机0
            deviceHardId: this.nest_this.nest.data.deviceHardId,
          });
          this.dialogVisible = false;
          this.$refs.zqTaskForm.resetFields();
          this.zqTask = {
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            ZQtaskId: "",
          };
          this.getTimingTaskListByNestId();
        }
      });
    },
    shanchu(item) {
      this.$el_confirm("确定删除此任务吗？", async () => {
        let res = await API.FCKERNEL.deleteTaskById({ id: item.taskId });
        this.$message.success(res);
        this.getTimingTaskListByNestId();
      });
    },
    async whatTask() {
      // this.$emit("changeLableName","河道巡检")
      if (this.data.deviceList[0].deviceHardId) {
        let res = await API.FCKERNEL.getNestTaskingType(
          this.data.deviceList[0].deviceHardId
        );
        this.whatTaskNum = res;
        if (res == 2) {
          await this.initTask();
        } else if (res == 1) {
          await this.changeBtn("itemC");
        } else if (res == 0) {
          await this.changeBtn("itemB");
        }
      }
    },
    // 正在执行的任务
    async initTask() {
      if (this.data?.deviceHardId) {
        let res = await API.FCKERNEL.getTaskAndUserRelation({
          sourceType: 1,
          deviceHardId: this.data.deviceHardId,
        });
        if (res.code == 200 && res.list) {
          this.list = res.list;
          console.log("触发无人机定位");
          // this.$emit("airway-display");
          this.$store.commit("fckernel/set_state", {
            key: "nset_fk_location",
            value: new Date().getTime(),
          });
          this.TaskList.push({
            id: res.list.taskId,
            flightName: res.list.flightName,
            flightLineId: res.list.flightId,
            flightCourseJson: res.list.flightCourseJson,
            userId: res.list.userId,
            taskStatus: res.list.taskStatus,
            taskTitle: res.list.taskTitle,
          });
          this.showRoute(res.list);
          this.flyTypeId = res.list.taskId;
          this.taskCate = res.list.taskStatus;

          if (res.list.flightId) {
            this.saveFlightLineId(res.list.flightId);
          }
        } else {
          sessionStorage.removeItem("waypointList");
          this.flight[0].flightName = null;
          this.taskCate = 1;
          (this.TaskListIndex = "任务库"), (this.flyTypeId = null);
        }
      }
    },
    operation(item) {
      switch (item) {
        case 0:
          return this.$emit("airway-display"); //轨迹
        case 1:
          return this.$emit("uav-location"); //定位
        case 2:
          return this.unlock(); //解锁摇杆
        case 3:
          return this.upload__airway(); //上传航线
        case 4:
          return this.handle_airway_change();
        case 5:
          return this.handleAirLinePreview(this.flight[0], true);
        default:
          console.log(11);
      }
    },
    async handClickTask() {
      // if(!this.wsShow){
      //  Bus.$emit("onetask");
      this.saveFlightLineId(this.flight[0].flightLineId);
      // 航线
      if (this.flightFlag && this.flyTypeId) {
        // 判断是否有任务
        let nestData = await API.FCKERNEL.getNestTaskByTime({
          nestId: this.nest_this.nest.data.deviceHardId,
        });
        if (nestData.code == 201) {
          return this.$message.error(nestData.message);
        }
        // let NestWeather = await API.FCKERNEL.getNestWeather({
        //   deviceId: this.nest_this.nest.data.deviceHardId,
        // });
        // console.log(NestWeather, "NestWeather");
        // //
        // if (NestWeather != "适宜飞行！") {
        //   return this.$message.error(NestWeather);
        // }
        // 局部预警
        let checkBeforeTakeOff = await API.DEVICE.checkBeforeTakeOff({
          taskId: this.flyTypeId,
        });
        if (!checkBeforeTakeOff.code) {
          this.$emit("Lsdom", checkBeforeTakeOff);
          return;
        }
        this.$confirm("请确认是否进行一键任务操作?", "安全确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false,
        })
          .then(async () => {
            this.getTrue();
            // 一键任务显示模型和轨迹
            // 关键词：一键任务
            // 1.点击一键任务，调用onetask方法：目的：让oneClicktask变量变为true
            Bus.$emit("onetask");
            // this.$emit('airway-display')
            this.$store.commit("fckernel/set_state", {
              key: "nset_fk_location",
              value: new Date().getTime(),
            });
            // this.$emit("airway-display");
            // this.$emit("uav-location");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else {
        this.$message.error("请选择任务");
      }
      // }else if(this.data.deviceList[0].deviceHardId == "00000_40607e"){
      //   let data = {
      //     target_latitude:33.319282,
      //     target_longitude:120.172578,
      //     target_height:100,
      //     security_takeoff_height:120,
      //     rth_altitude:120,
      //     rc_lost_action:2,
      //     exit_wayline_when_rc_lost:1,
      //     commander_mode_lost_action:0,
      //     commander_flight_height:120,
      //     commander_mode_lost_action:0,
      //     max_speed:14,
      //     rth_mode:1,
      //   }
      //   console.log(data,"00000_40607e");
      //         axios.post(`http://32.128.6.51:6789/control/api/v1/devices/4SEDL7M0012DEH/jobs/takeoff-to-point`,data).then(res=>{

      //         })
      //       }
    },
    async getTrue() {
      // 记录任务
      let res = await API.FCKERNEL.addTaskAndUserRelation({
        taskId: this.flyTypeId,
        sourceType: 1, //鹰巢为1 无人机0
        deviceHardId: this.data.deviceHardId,
      });
      // 生成架次號
      let getFlightSortic = await API.DEVICE.getFlightSortic({
        taskId: this.flyTypeId,
        // deviceHardId: "1581F5BMD238S001C748",
        deviceHardId: this.nest_this.nest.data.deviceList[0].deviceHardId,
      });
      // let data = {
      //   cmdControlType: 100004,
      //     uavDeviceId: "1581F5BMD238S001C748",
      //     wayLineObj: {
      //       ...this.flightLinefkData,
      //       taskId: this.flyTypeId,
      //       flightSortiesID: getFlightSortic,
      //     }
      // }
      // console.log(data,1236544);
      // return
      // 告诉飞控开始任务,并且把架次号和 任务id传过去
      this.startFlight({
        taskId: this.flyTypeId,
        flightSortiesID: getFlightSortic,
      });
      let obj = {
        cmdControlType: 100004,
        uavDeviceId: this.nest_this.nest.data.deviceList[0].deviceHardId,
        wayLineObj: {
          ...this.flightLinefkData,
          taskId: this.flyTypeId,
          flightSortiesID: getFlightSortic,
        },
      };
      // if(this.returnHeight && this.wsShow){
      //   obj.wayLineObj.returnHeight = this.returnHeight
      // }
      // console.log(obj,"objobj");
      // return
      // 一键任务指令
      this.$emit("fn", obj, "yc");

      // 更改任务状态 status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
      this.task_update(3);
      this.taskCate = 3;
      this.$parent.loggerFlag = false;
      this.$message.success("操作成功");
      this.$emit("iconShow");
    },
    async saveFlightLineId(flightLineId) {
      let res = await API.AIRWAY.GetAirwayInfo(flightLineId);
      this.flightLinefkData = res.data;
      let lineData = null;

      if (JSON.parse(res.data.flightJson).points[0].ailist) {
        this.flightLinefkData.headingMode = "USING_WAYPOINT_HEADING";
      }
      if (JSON.parse(res.data.flightJson).waypoints) {
        lineData = JSON.parse(res.data.flightJson).waypoints;
        this.flightLinefkData.waypointList = lineData;
      } else {
        lineData = JSON.parse(res.data.flightJson).points;
        this.flightLinefkData.waypointList.forEach((val, i) => {
          lineData.forEach((val2, j) => {
            if (i == j) {
              val.waypointActions = val2.actions;
            }
          });
        });
      }
      delete this.flightLinefkData.flightJson;
      // return
      if (this.flightLinefkData) {
        try {
          if (this.whatTaskNum == 2) {
            sessionStorage.setItem(
              "waypointList",
              JSON.stringify({
                uavDeviceId:
                  this.nest_this.nest.data.deviceList[0].deviceHardId,
                wayLineObj: this.flightLinefkData,
                flyTypeId: this.flyTypeId,
              })
            );
          }
        } catch (error) {
          sessionStorage.removeItem("waypointList");
        }
      }
    },
    async getFlightLine(id) {
      let flightLineData = await API.USER.routesListqq(id);
      this.flight[0] = flightLineData;
      // 预览
      this.showRoute(flightLineData);
      // 自动生成任务
      let task = await API.AIRWAY.addNestAutoTask({
        lineId: id,
        type: 2,
      });
      if (task) {
        let res = await API.AIRWAY.getNestApprovedTask({
          nestId: this.nest_this.nest.data.id,
        });
        this.TaskList = [
          {
            taskTitle: "选择航线自动生成任务",
            id: "z1322",
          },
          ...res,
        ];
        this.flyTypeId = task.id;
      }
      let flightLinefkData = await API.AIRWAY.GetAirwayInfo(id);
      // console.log(flightLinefkData,'flightLinefkData');
      this.flightLinefkData = flightLinefkData.data;
    },
    async getNestTaskInProgressFn() {
      let id = this.nest_this.nest.data.deviceList[0].deviceHardId;
      let res = await API.AIRWAY.getNestTaskInProgress(id);
      if (res.code == 200 && res.data) {
        this.showRoute(res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.yidong {
  left: 544px !important;
}
.taskListBox {
  position: absolute;
  left: 60px;
  top: 82px;
  min-height: 254px;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px 10px 0 0;
  // border: 1px solid #70daf9;
  transition: 0.3s;
  z-index: 12;
  &.collapse {
    left: 64px !important;
    transform: none;
  }

  .header {
    display: flex;
    .headerLable {
      width: calc(100% / 3);
    }

    .active {
      background: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%
      );
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 32px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // background-image: linear-gradient(
      //   180deg,
      //   #9198ff 0%,
      //   rgba(45, 81, 153, 0.22) 40%,
      //   #05091a 100%
      // );
      // border: 1px solid #70daf9;
      // box-shadow: inset 0 0 10px 2px #3f9dff;
      // font-family: MicrosoftYaHei-Bold;
      // font-size: 14px;
      // color: #70daf9;
      // letter-spacing: 0;
      // font-weight: 700;
    }

    .default {
      // background-image: linear-gradient(
      //   180deg,
      //   #9198ff 0%,
      //   rgba(45, 81, 153, 0.22) 40%,
      //   #05091a 100%
      // );
      // border: 1px solid #70daf9;
      font-family: YouSheBiaoTiHei;
      font-size: 16px;
      color: #ffffff;
      // color: rgba(112, 218, 249, 0.5);
      // letter-spacing: 0;
      // font-weight: 700;
    }
  }

  .taskList {
    overflow-y: auto;

    .rwtitle {
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: #b5e5ff;
      padding: 5px 0;
      letter-spacing: 0;
      font-weight: 700;
      background: rgba(87, 96, 138, 0.2);
      border: 1px solid rgba(207, 234, 255, 0.33);
    }

    .rwinfo {
      color: #ffffff;
      background: url("~@/assets/images/observe/fckernel/nest/listBg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 33px;
      line-height: 33px;
      padding-left: 4px;
    }
  }

  .btn {
    width: 122px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin: 0 auto;
    margin-bottom: 10px;
    background-image: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.22) 40%,
      #05091a 100%
    );
    border: 1px solid #70daf9;
    box-shadow: inset 0 0 10px 2px #3f9dff;
  }

  .Titlehideen {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
/deep/ .el-tooltip {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.week {
  position: fixed;
  top: 80px;
  right: calc(50% - 200px);
  .taskDialog {
    // position: fixed;
    margin-top: 6vh;
    margin-left: -101px;

    .el-input__icon {
      color: #70daf9;
    }

    .placeholder {
      color: #70daf9;
    }
    .el-form {
      padding: 16px 16px 16px 0;
      background: rgba(9, 32, 87, 0.7);
    }
    /deep/.el-form-item__label {
      color: #fff;
    }
    .el-input--mini .el-input__inner {
      background: rgba(112, 218, 249, 0.1);
      border: 1px solid #70daf9;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #70daf9;
      letter-spacing: 0;
      font-weight: 400;
    }

    .el-scrollbar {
      background: #0d224f !important;
      border: 1px solid #00aeff !important;
      color: #fff;
    }

    .header {
      width: 100%;
      font-size: 16px;
      color: #70daf9;
      left: 0;
      height: 32px;
      line-height: 32px;
      letter-spacing: 0;
      font-weight: 700;
      background-image: linear-gradient(
        180deg,
        rgba(145, 152, 255, 0.5) 0%,
        rgba(45, 81, 153, 0.22) 40%,
        rgba(5, 9, 26, 0.5) 100%
      );
      border: 1px solid #70daf9;
      box-shadow: inset 0 0 10px 2px #3f9dff;
      font-family: MicrosoftYaHei-Bold;
    }

    .btnBox {
      display: flex;
      justify-content: center;

      .btnConfrim {
        background-image: linear-gradient(
          180deg,
          rgba(145, 152, 255, 0.5) 0%,
          rgba(45, 81, 153, 0.22) 40%,
          rgba(5, 9, 26, 0.5) 100%
        );
        border: 1px solid #70daf9;
        box-shadow: inset 0 0 10px 2px #3f9dff;
        margin-right: 37px;
      }

      .closeBtn {
        background-image: linear-gradient(
          180deg,
          rgba(255, 145, 145, 0.5) 0%,
          rgba(153, 45, 45, 0.22) 40%,
          rgba(26, 5, 5, 0.5) 100%
        );
        border: 1px solid #f97070;
        box-shadow: inset 0 0 10px 2px #ff3f3f;
      }
    }
  }
}

.time-select-item {
  color: #fff;
}

.time-select-item:hover {
  color: #fff;
  /* background: rgba(9, 32, 87, 1) */
  background: rgba(67, 109, 207, 0.7);
}

.jianju {
  padding-left: 16px;
  padding-right: 16px;
}

.flexOne {
  flex: 1;
}

.fontOne {
  white-space: nowrap;
  margin: 0 10px 0 0;
}

.anniu {
  margin: 44px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.kbt_button {
  height: 30px;
  padding: 0;
}
</style>
