let uav_model = null;
let uav_polyline = null;
let uav_data = null

export default {
  init_ws() {
    let {
      user_info,
      nest
    } = this;
    let _this = this
    let username = user_info.username
    let token = user_info["mmc-identity"] + user_info["FLYINGSESSIONID"]
    let appId = user_info.appid
    let ws_url = process.env.VUE_APP_WS_URL
    let ws = this.uav.ws = new WebSocket(ws_url);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: 100,
          systemCode: "mmc",
          state: 1,
          username,
          token,
          appId,
        })
      );
    };
    ws.onmessage = (e) => {
      // 鹰视监听禁飞区
      let metadata = JSON.parse(e.data)
      if (metadata.msgnum === 4600) {

      }
      if (metadata && metadata.msgnum === 4120) {
        _this.$el_message('admin账号控制中，请稍后控制！', () => {}, 'error')
      }
      if (metadata && metadata.type === 300) {
        // _this.$el_message('执行成功！')
      }
      if (metadata && metadata.type === 300 && metadata.cmdFunction == 9001) {
        // console.log(metadata,nest.data.deviceList[0],"sdsadsad");
        let deviceHardId = nest.data.deviceList[0].deviceHardId
        if (deviceHardId == metadata.deviceHardId) {
          if (metadata.data.NetMode) {
            this.level = metadata.data.RSRPLEVEL
          }
          // if (metadata.data.batteries) {
          //   this.$store.commit(
          //     "fckernel/set_state", {
          //     key: 'drone_battery_maintenance_info',
          //     value: metadata.data.batteries
          //     }
          //   );
          // }
        }
      }
      // 监听无人机上锁
      if (metadata && metadata.type === 810) {
        // let {
        //     nest
        // } = this

        let deviceHardId = nest.data.deviceList[0].deviceHardId
        if (deviceHardId == metadata.deviceHardId) {
          // this.uav.taskStatus = true
        }
      }
      // // 视频推流状态更新 弃用
      // if (metadata && metadata.type === 999) {
      //     if (metadata.pushStatus) {
      //         this.uav_update_device_video_status(metadata.pushStatus, metadata.deviceHardId)
      //     }
      // }

      if (metadata && metadata.msgnum) {
        // 设备上线
        if (metadata.msgnum === 4132) {
          let deviceHardId = metadata && metadata.deviceHardId;
          this.uav_update_device_report_status(1, deviceHardId, 'list')
          this.uav_update_device_report_status(1, deviceHardId, 'mountList')
        }
        // 设备离线
        if (metadata.msgnum === 4121) {
          let deviceHardId = metadata && metadata.deviceHardId;
          this.uav_update_device_report_status(0, deviceHardId, 'list')
          this.uav_update_device_report_status(0, deviceHardId, 'mountList')
        }
      } else {
        // 无人机上报数据
        if (metadata && metadata.deviceData) {
          uav_data = JSON.parse(metadata.deviceData)
          if (uav_data && uav_data.deviceHardId) {
            if (uav_data.data && uav_data.data.uavInfo) {
              if (nest.data.deviceList[0].deviceHardId == uav_data.deviceHardId && this.wsShow) {
                // console.log(uav_data.data.uavInfo,"uav_data.data.uavInfo");
                if (uav_data.data.uavInfo.batteryInfoList) {
                  this.$store.commit(
                    "fckernel/set_state", {
                      key: 'drone_battery_maintenance_info',
                      value: uav_data.data.uavInfo.batteryInfoList
                    }
                  );
                }
                this.uav.data = uav_data.data.uavInfo
                this.uav.battery = uav_data.data.uavInfo
                if (uav_data.data.mountInfo) {
                  uav_data.data.mountInfo.forEach(v => {
                    let index = this.uav.mounts.findIndex(
                      (item) => item.mountName === v.mountName
                    );
                    if (index == -1) {
                      this.uav.mounts.push(v);
                    }
                  });
                }
                // this.uav_update_control(this.uav.data, uav_data.data.uavInfo)
                // this.uav_update_mounts(this.uav.data, uav_data.data.mountInfo);
                // this.uav_update_control_battery(this.uav.data, uav_data.data.uavInfo);
                // this.uav_update_control(uav_data)
                if (uav_data.data.uavInfo.push_status) {
                  let push_status = uav_data.data.uavInfo.push_status
                }

                if (this.wsShow) {
                  this.uav.status = 1;
                  // 收集无人机上线上报的数据
                  let location = uav_data.data.uavInfo;
                  if (location) {
                    let position = Cesium.Cartesian3.fromDegrees(
                      location.longitude * 1,
                      location.latitude * 1,
                      location.height * 1
                    );
                    // pitch 俯仰角 围绕x旋转
                    // yaw 偏航角 围绕x旋转
                    // roll 翻滚角 围绕z旋转
                    let yaw = Cesium.Math.toRadians(Number(location.yaw + 180)),
                      pitch = Cesium.Math.toRadians(location.pitch),
                      roll = Cesium.Math.toRadians(location.roll),
                      hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
                      orientation = Cesium.Transforms.headingPitchRollQuaternion(
                        position,
                        hpr
                      );
                    if (this.Model) {
                      const curPos = this.Model.position.getValue(
                        Cesium.JulianDate.now()
                      );

                      if (curPos) {
                        const curCart = new Cesium.Cartesian3(
                          curPos.x,
                          curPos.y,
                          curPos.z
                        );
                        const afterCart = new Cesium.Cartesian3(
                          position.x,
                          position.y,
                          position.z
                        );
                        if (curCart.equals(afterCart)) {
                          return false;
                        } else {
                          // 航向
                          this.uav.orientation = orientation;
                          // 航线数据
                          this.uav.positions.push(position);
                        }
                      }
                    }
                    // 无人机定位
                    this.uav.position = position;
                  }
                  // this.uav_update_locations(uav_data);
                }
              }
            }

          }
        }
        if (metadata && metadata.type === 800) {
          // this.uav.imgUrl = metadata.url
          // this.uav.imgVisible = true
        }
        let DjInfor = JSON.parse(metadata.deviceData)
        if (DjInfor.deviceHardId == this.active_nestData.deviceList[0].deviceHardId) {
          // console.log(DjInfor, '当前鹰巢飞机数据');
        }
      }
    };

    ws.onerror = (error) => {
      // console.log("error", error);
    };

    ws.onclose = (data) => {};
  },
  send_ws(data) {
    this.uav.ws && this.uav.ws.send(JSON.stringify(data))
  },
  async control_ws(cate) {
    let deviceHardId = this.nest.data.deviceList[0].deviceHardId
    let username = this.user_info.username
    if (cate == 11) {
      // 安全降落
      this.$confirm('请确认是否执行安全降落操作?', '安全确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: "uav_controlPane",
        showClose: false
      }).then(() => {
        this.uav_send_ws({
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username,
          data: {
            cmdFunction: 2116,
          },
          deviceHardId,
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    } else if (cate == 12) {
      // 一键返航
      this.$confirm('请确认是否进行一键返航操作?', '返航确认', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        customClass: "uav_controlPane",
        showClose: false
      }).then(() => {
        this.uav_send_ws({
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username,
          data: {
            cmdFunction: 2112,
          },
          deviceHardId,
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    } else if (cate == 13) {
      // 航线模式
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2115,
        },
        deviceHardId,
      })

    } else if (cate == 17) {
      // 暂停飞行
      this.uav_send_ws({
        type: 200,
        systemCode: 'mmc',
        state: 1,
        username,
        data: {
          cmdFunction: 2117,
        },
        deviceHardId,
      })
    }
  },
  init_params() {
    this.uav.data = null;
    this.uav.battery = null;
    this.uav.mounts = [];
    this.uav.status = 0;
    this.uav.position = null;
    this.uav.positions = [];
    this.uav.orientation = null;
    uav_model = null;
    uav_polyline = null;
  },
  // 飞控 鹰巢 一键任务
  one_click_task() {
    let {
      status,
      position,
      orientation,
      positions
    } = this.uav
    if (!position) {
      // this.$el_message("无人机未就绪", () => {}, "warning");
      return false;
    }

    if (position) {
      let cesium_layer = this.g_cesium_layer();
      let model = viewer.entities.getById(`nest_uav_model`);

      if (!model) {
        this.uav.entity_id = `nest_uav_model`;
        model = this.Model = cesium_layer.add_model_X85(
          viewer.entities,
          this.uav
        );
        viewer.camera.flyTo({
          destination: model.position.getValue(Cesium.JulianDate.now()),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          },
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
      } else {
        this.Model = model;
      }

      let polyline = viewer.entities.getById(`nest_uav_polyline`);

      if (!polyline) {
        cesium_layer.add_polyline(viewer.entities, {
          entity_id: `nest_uav_polyline`,
          positions: positions,
        });
      }
    }
  },
  // 飞控 鹰巢 定位
  location() {
    let {
      status,
      position,
      orientation
    } = this.uav;
    if (!position) {
      this.$el_message("无人机未就绪，无法定位", () => {}, "warning");
      return false;
    }
    if (position) {
      let cesium_layer = this.g_cesium_layer();
      let model = viewer.entities.getById(`nest_uav_model`);

      if (!model) {
        this.uav.entity_id = `nest_uav_model`;
        model = this.Model = cesium_layer.add_model_X85(
          viewer.entities,
          this.uav
        );

        //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
        var ellipsoid = viewer.scene.globe.ellipsoid;
        var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
        var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
        var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
        var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);

        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          },
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
      } else {
        // this.Model = model;
        let model = viewer.entities.getById(`nest_uav_model`);
        viewer.entities.remove(model);
        // 清除航线
        let polyline = viewer.entities.getById(`nest_uav_polyline`);
        viewer.entities.remove(polyline);
      }
    }
  },
  // 鹰巢一键任务定位无人机并展示航线
  nset_fk_locationFn() {
    setTimeout(() => {
      let {
        status,
        positions,
        position
      } = this.uav;
      let cesium_layer = this.g_cesium_layer();
      let polyline = viewer.entities.getById(`nest_uav_polyline`)
      if (!polyline || polyline == undefined) {
        if (position && positions) {
          cesium_layer.add_polyline(viewer.entities, {
            entity_id: `nest_uav_polyline`,
            positions: positions,
          });
          if (position) {
            let model = viewer.entities.getById(`nest_uav_model`);
            if (!model) {
              this.uav.entity_id = `nest_uav_model`;
              model = this.Model = cesium_layer.add_model_X85(
                viewer.entities,
                this.uav
              );

              //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
              var ellipsoid = viewer.scene.globe.ellipsoid;
              var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
              var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
              var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
              var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);

              viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000),
                maximumHeight: 100,
                complete: () => {
                  // 锁定
                  viewer.trackedEntity = model;
                },
              });
              model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
            }
          }
        }
      }
    }, 2000);
  },

  //清除  飞控 鹰巢 模型和轨迹
  closeIconShow() {
    // 清除模型
    // 清除轨迹模型
    // 关键词：飞控鹰巢任务结束
    let model = viewer.entities.getById(`nest_uav_model`);
    viewer.entities.remove(model);
    // 清除航线
    let polyline = viewer.entities.getById(`nest_uav_polyline`);
    viewer.entities.remove(polyline);
    this.uav.position = null; //清除无人机定位
    this.uav.positions = []; //清除航线信息

  },
  // 结束任务时清除任务列表数据
  clearIdFn() {
    // 清除飞行数据
    if (this.$refs.TaskListRef) {
      this.$refs.TaskListRef.closeTask();
      // let model = viewer.entities.getById(`nest_uav_model`);
      // viewer.entities.remove(model)
      // 清除模型
      let model = viewer.entities.getById(`nest_uav_model`);
      viewer.entities.remove(model);
      // 清除航线
      let polyline = viewer.entities.getById(`nest_uav_polyline`);
      viewer.entities.remove(polyline);
      window.viewer.zoomTo(1000);
      // 清除飞行数据
      this.uav.positions = [];
    }
  },
  // 飞控 鹰巢 轨迹定位
  airway() {
    let {
      status,
      positions,
      position
    } = this.uav;
    let cesium_layer = this.g_cesium_layer();
    let polyline = viewer.entities.getById(`nest_uav_polyline`)
    if (!polyline) {
      if (position && positions) {
        cesium_layer.add_polyline(viewer.entities, {
          entity_id: `nest_uav_polyline`,
          positions: positions,
        });
        if (position) {
          let model = viewer.entities.getById(`nest_uav_model`);
          if (!model) {
            this.uav.entity_id = `nest_uav_model`;
            model = this.Model = cesium_layer.add_model_X85(
              viewer.entities,
              this.uav
            );

            //cartesian3转wgs84, 通过经纬度高度1000米创建新cartesian3，并设置相机飞入此位置
            var ellipsoid = viewer.scene.globe.ellipsoid;
            var cartesian3 = model.position.getValue(Cesium.JulianDate.now());
            var cartogtraphic = ellipsoid.cartesianToCartographic(cartesian3);
            var lat = Cesium.Math.toDegrees(cartogtraphic.latitude);
            var lng = Cesium.Math.toDegrees(cartogtraphic.longitude);

            viewer.camera.flyTo({
              destination: Cesium.Cartesian3.fromDegrees(lng, lat, 1000),
              maximumHeight: 100,
              complete: () => {
                // 锁定
                viewer.trackedEntity = model;
              },
            });
            model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
          }
        }
      }
    } else {
      // 如果 有航线就去除航线
      let polyline = viewer.entities.getById(`nest_uav_polyline`);
      viewer.entities.remove(polyline);
    }
  },

  video_init(item) {
    this.nest.video = {
      in: {
        vUrl: item.internal,
        deviceName: item.firmId == 2 ? "图传" : "舱内", //1 代表kbt机库 2 代表大疆 机库
        list: item.deviceList,
        typenvr: "in",
        name: item.name
      },
      out: {
        vUrl: item.uav,
        deviceName: item.name,
        list: item.deviceList,
      },
      uav: {
        vUrl: item.external,
        deviceName: "舱外",
        list: item.deviceList,
        typenvr: "uav"
      },
    };
    this.uav.video = {
      in: null,
      out: null,
      uav: null,
    };
  },

  video_play(type) {
    this.uav.video[type] = this.nest.video[type];
  },

  video_close(type) {
    this.uav.video[type] = null;
  },
};