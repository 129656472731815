<!-- 飞控 鹰巢 操作区域 -->
<template>
  <div class="nset_control_box" :style="wsShow ? 'top: 50px' : ''">
    <div class="nset_control_box_header lh34">
      <div class="title ml10">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="font">操作区域</div>
        <!-- <div class="dib w3 h16 mr5" style="background: #ffbd36" /> -->
      </div>
      <div class="exit mr10 cp" @click="$emit('exit')">关闭</div>
    </div>
    <div class="nset_control_box_area p10">
      <div
        class="yc"
        :class="wsShow ? 'ml28' : 'ml16'"
        v-show="controlTypeFlag"
      >
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb14 cf tc cp" @click="handClick(0)">
            <SymbolIcon icon="chucang1" />
            <span class="dib f8">出舱</span>
          </div>
        </div>
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb14 cf tc cp" @click="handClick(1)">
            <SymbolIcon icon="rucang1" />
            <span class="dib f8">入舱</span>
          </div>
        </div>
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb14 cf tc cp" @click="handClick(2)">
            <SymbolIcon icon="kaishichongdian" />
            <span class="dib f8">开始充电</span>
          </div>
        </div>
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb14 cf tc cp" @click="handClick(3)">
            <SymbolIcon icon="jieshuchongdian" />
            <span class="dib f8">结束充电</span>
          </div>
        </div>
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb3 cf tc cp" @click="handClick(4)">
            <SymbolIcon icon="yure" />
            <span class="dib f8">预热</span>
          </div>
        </div>
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb3 cf tc cp" @click="handClick(5)">
            <SymbolIcon icon="xiumian" />
            <span class="dib f8">休眠</span>
          </div>
        </div>
        <div class="itemBox" v-if="!wsShow">
          <div class="w48 h48 item mb3 cf tc cp" @click="$emit('yxrz')">
            <SymbolIcon icon="yunhangrizhi2" />
            <span class="dib f8">运行日志</span>
          </div>
        </div>
        <div
          class="itemBox"
          :class="{ w92: wsShow, 'grayscale preventClick': firmId === 2 }"
        >
          <div class="w48 h48 item mb3 cf tc cp" @click="handClick(6)">
            <SymbolIcon icon="tuichuliucheng" />
            <span class="dib f8">退出流程</span>
          </div>
        </div>
        <div class="itemBox" :class="{ w92: wsShow }">
          <div class="w48 h48 item mb3 cf tc cp" @click="yunxing">
            <SymbolIcon icon="guiji" />
            <span class="dib f8">运行监测</span>
          </div>
        </div>
        <div class="itemBox" v-if="!wsShow">
          <div
            class="w48 h48 item mb3 cf tc cp"
            @click="
              endRenwu = true;
              active = 2;
            "
          >
            <!-- <SymbolIcon icon="guiji" /> -->
            <img src="./fxjj.svg" class="mt6" alt="飞行解禁" />
            <span class="dib f8">飞行解禁</span>
          </div>
        </div>
      </div>
      <div class="wrj" v-show="!controlTypeFlag">
        <div class="w48 h48 item mb3 cf tc cp" @click="$emit('uav-location')">
          <SymbolIcon icon="dingwei" />
          <span class="dib f8">定位</span>
        </div>
        <!-- 飞控轨迹 -->
        <div class="w48 h48 item mb3 cf tc cp" @click="$emit('airway-display')">
          <SymbolIcon icon="guiji" />
          <span class="dib f8">轨迹</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="wrjfn(0)">
          <SymbolIcon icon="jixufeihang1" />
          <span class="dib f8">继续飞行</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="wrjfn(1)">
          <SymbolIcon icon="zantingfeihang1" />
          <span class="dib f8">暂停飞行</span>
        </div>
        <div
          class="w48 h48 item mb3 cf tc cp"
          @click="
            endRenwu = true;
            active = 1;
          "
        >
          <SymbolIcon icon="renwujieshu1" />
          <span class="dib f8">任务结束</span>
        </div>
        <div class="w48 h48 item mb3 cf tc cp" @click="zdfx">
          <SymbolIcon icon="tiaozhuandaozuobiao" />
          <span class="dib f8">指点飞行</span>
        </div>
      </div>
    </div>
    <!-- 结束任务确认弹框 -->
    <el-dialog
      title
      :visible.sync="endRenwu"
      width="30%"
      :modal-append-to-body="false"
    >
      <div class="endrenwu" v-if="active == 1">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">您确定要结束任务吗？</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="endRenwu = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="queding">确定</div>
        </div>
      </div>
      <div class="endrenwu" v-else>
        <div class="tishiyu">温馨提示</div>
        <div class="queding">您确定要飞行解禁？</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="endRenwu = false">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="untie">确定</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title
      :visible.sync="zdydShow"
      width="30%"
      :append-to-body="true"
      :show-close="false"
      :close-on-click-modal="false"
      style="margin-top: 20vh"
    >
      <div class="endrenwu zdfx">
        <div class="tishiyu">引导飞行确认</div>
        <div class="queding">位置获取成功，请确认是否进行指点模式飞行</div>
        <div class="fleSpeed">
          指点飞行速度：
          <el-input
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="flySpeed"
          ></el-input>
          <span style="opacity: 0">1</span>(米/秒)
        </div>
        <div class="red" v-if="maxSpeed">指点飞行最大速度为10米/秒</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="zdydClose">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="zdydThen">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
let Point = null;
import API from "@/api";
let handler = null;
import SymbolIcon from "@/components/observe/fckernel/common/symbol-icon";
// 引用bus
import Bus from "@/assets/ligature.js";
import { mapGetters } from "vuex";
export default {
  components: { SymbolIcon },
  props: {
    username: {
      type: String,
      default: () => {
        "";
      },
    },
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      },
    },
    controlTypeFlag: {
      type: Boolean,
      default: () => {
        false;
      },
    },
    jcData: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    jcData: function (value) {},
    deep: true,
    immediate: true,
  },
  data() {
    return {
      flySpeed: 6, //指点飞行设置
      zdlat: "",
      zdlng: "",
      maxSpeed: false,
      zdydShow: false,
      endRenwu: false, //结束任务确认弹框
      active: 2,
      chargerPower: true,
      lifts: true,
      zdfcFlag: true,
      list: [
        {
          label: "出舱",
          data: {
            cmdControlType: 2059,
          },
        },
        {
          label: "入舱",
          data: {
            cmdControlType: 2073,
          },
        },
        {
          label: "开始充电",
          data: {
            cmdControlType: 2060,
          },
        },
        {
          label: "结束充电",
          data: {
            cmdControlType: 2071,
          },
        },
        {
          label: "预热",
          data: {
            cmdControlType: 2072,
          },
        },
        {
          label: "休眠",
          data: {
            cmdControlType: 2061,
          },
        },
        {
          label: "退出流程",
          data: {
            cmdControlType: 100001,
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
    firmId() {
      // 1 代表ktb 2 代表大疆
      let data = this.$store.state.fckernel.active_nestData;
      if (data) {
        return data.firmId;
      }
      return 1;
    },
  },
  beforeDestroy() {
    if (Point) {
      window.viewer.entities.remove(Point);
    }
  },
  methods: {
    // 运行检测开关
    yunxing() {
      this.$emit("exit");
      // 运行状态检测
      // 打开
      console.log("001处");
      Bus.$emit("yunxing", true);
    },
    //飞行解禁
    async untie() {
      console.log(this.device, "jcData");
      let res = await API.FCKERNEL.templgnoreWeatherCheck(
        this.device.deviceHardId
      );
      this.$message.success("解禁成功！");
      this.endRenwu = false;
    },
    // 结束任务确认框，确定结束任务
    async queding() {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (waypointList) {
        if (!this.wsShow) {
          // 结束任务
          API.DEVICE.task_update({
            id: waypointList.flyTypeId,
            status: 4,
            deviceHardId: waypointList.uavDeviceId,
          });
          // 修改鹰巢绑定任务
          API.FCKERNEL.updateTaskAndUserRelation({
            taskId: waypointList.flyTypeId,
          });
          // this.$emit("fn", { type: 525 }, "wrj");
          // 飞控结束任务
          this.$emit(
            "fn",
            {
              type: 769,
            },
            "wrj"
          );

          setTimeout(() => {
            this.$emit(
              "fn",
              {
                cmdControlType: 2073,
              },
              "yc"
            );
          }, 2000);
        } else {
          // 结束任务
          API.DEVICE.task_update({
            id: waypointList.flyTypeId,
            status: 4,
            deviceHardId: waypointList.uavDeviceId,
          });
          // 绑定任务
          API.FCKERNEL.updateTaskAndUserRelation({
            taskId: waypointList.flyTypeId,
          });
          // this.$emit("fun", {
          //   type: 200,
          //   systemCode: "mmc",
          //   state: 1,
          //   username: this.username,
          //   data: {
          //     cmdFunction: 2280,
          //     taskId: waypointList.flyTypeId + "",
          //   },
          //   deviceHardId: waypointList.uavDeviceId,
          // });
        }
        // this.$emit("clearId");
        // sessionStorage.removeItem("waypointList");

        // this.$message.success("操作成功");
      } else {
        this.$message.warning("暂无飞行任务");
      }
      this.$emit("clearId");
      sessionStorage.removeItem("waypointList");
      this.endRenwu = false;
    },
    addModeelPoint(viewer, position, name) {
      return viewer.entities.add({
        position,
        point: {
          pixelSize: 12,
          color: Cesium.Color.fromCssColorString("#ee0000"),
          outlineColor: Cesium.Color.fromCssColorString("#fff"),
          outlineWidth: 1,
          show: true,
        },
        label: {
          text: name,
          scale: 0.5,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          outlineWidth: 0,
          pixelOffset: new Cesium.Cartesian2(26, -20),
        },
      });
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading;
      var pitch = viewer.scene.camera.pitch;
      var roll = viewer.scene.camera.roll;
      var info = { head: head, pitch: pitch, roll: roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; //with longitude and latitude expressed in radians and height in meters.
      //以下方式也可以获取相机位置只是返回的坐标系不一样
      // var position = viewer.scene.camera.position //cartesian3 空间直角坐标系
      // var ellipsoid = scene.globe.ellipsoid;
      // var position =ellipsoid.cartesianToCartographic(viewer.scene.camera.position)//
      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return { lng: longitude, lat: latitude, h: height, mat: info };
    },
    async zdfx() {
      let viewer = window.viewer;
      if (!this.zdfcFlag) {
        return false;
      }
      // 指点飞行 wgs84
      this.$message("请点击地图获取目标位置");
      this.zdfcFlag = false;
      let position = null;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        let earthPosition = viewer.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid
        );
        let cartographic = Cesium.Cartographic.fromCartesian(
          earthPosition,
          viewer.scene.globe.ellipsoid,
          new Cesium.Cartographic()
        );
        let lng = Cesium.Math.toDegrees(cartographic.longitude);
        let lat = Cesium.Math.toDegrees(cartographic.latitude);
        this.zdlat = lat;
        this.zdlng = lng;
        if (Point) {
          window.viewer.entities.remove(Point);
        }
        // let position = window.viewer.scene.camera.pickEllipsoid(
        //   movement.position
        // );
        (position = Cesium.Cartesian3.fromDegrees(lng, lat, 20)),
          (Point = this.addModeelPoint(window.viewer, position, "目标点"));

        let zt = this.getcameraPosInfo();

        // 指点飞行
        this.zdydShow = true;
        // this.$confirm(
        //   "位置获取成功，请确认是否进行指点模式飞行?",
        //   "引导飞行确认",
        //   {
        //     cancelButtonText: "取消",
        //     confirmButtonText: "确定",
        //     customClass: "uav_controlPane",
        //     showClose: false,
        //   }
        // )
        //   .then(() => {
        //     this.$emit(
        //       "fn",
        //       {
        //         data: {
        //           latitude: lat,
        //           longitude: lng,
        //           speed: 6,
        //         },
        //         type: 518,
        //       },
        //       "wrj"
        //     );
        //     handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        //     this.zdfcFlag = true;
        //   })
        //   .catch(() => {
        //     if (Point) {
        //       window.viewer.entities.remove(Point);
        //     }
        //     handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        //     this.$message({
        //       type: "info",
        //       message: "已取消操作",
        //     });
        //     this.zdfcFlag = true;
        //   });
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    zdydThen() {
      if (this.flySpeed > 10) {
        this.maxSpeed = true;
      } else {
        if (!this.wsShow) {
          this.$emit(
            "fn",
            {
              data: {
                latitude: this.zdlat,
                longitude: this.zdlng,
                speed: Number(this.flySpeed),
              },
              type: 518,
            },
            "wrj"
          );
        } else {
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2219,
              latitude: this.zdlat,
              longitude: this.zdlng,
              speed: Number(this.flySpeed),
            },
            deviceHardId: this.device.deviceList[0].deviceHardId,
          });
        }
        handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        this.zdfcFlag = true;
        this.zdydShow = false;
        this.maxSpeed = false;
        this.flySpeed = 6;
      }
    },
    zdydClose() {
      if (Point) {
        window.viewer.entities.remove(Point);
      }
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      this.$message({
        type: "info",
        message: "已取消操作",
      });
      this.zdfcFlag = true;
      this.zdydShow = false;
      this.maxSpeed = false;
      this.flySpeed = 6;
    },
    // change(item) {
    //     if (item.chargerPower == 2) {
    //         this.chargerPower = true
    //     }
    //     if (item.chargerPower == 1) {
    //         this.chargerPower = false
    //     }
    //     if (item.lifts == 1) {
    //         this.lifts = true
    //     }
    //     if (item.lifts == 2) {
    //         this.lifts = false
    //     }
    // },
    wrjfn(item) {
      if (item == 0) {
        if (!this.wsShow) {
          this.$emit("fn", { type: 524 }, "wrj");
        } else {
          //航线模式
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2118,
            },
            deviceHardId: this.device.deviceList[0].deviceHardId,
          });
        }
      } else if (item == 1) {
        if (!this.wsShow) {
          this.$emit("fn", { type: 523 }, "wrj");
        } else {
          //暂停飞行
          this.$emit("fun", {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.username,
            data: {
              cmdFunction: 2117,
            },
            deviceHardId: this.device.deviceList[0].deviceHardId,
          });
        }
      }
      this.$message.success("操作成功");
    },
    handClick(item) {
      // if (item == 1) {
      //   this.queding();
      // }
      this.$emit("fn", this.list[item].data, "yc");
      // this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.nset_control_box {
  min-height: 100px;
  background: rgba(9, 32, 87, 0.7);
  // border: 1px solid #70daf9;
  position: absolute;
  right: 71px;
  top: calc(81% - 14px);
  bottom: auto !important;
  width: 416px;
  border-radius: 10px;

  .nset_control_box_header {
    display: flex;
    justify-content: space-between;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .exit {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .nset_control_box_area {
    .yc {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .itemBox {
        // margin-left: 29px;
        width: 75px;
        // flex: 1 1 20%;

        .item {
          // display: inline-block;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px;
          background-image: linear-gradient(
            179deg,
            #1773b6 0%,
            #3484c3 36%,
            #2a7abd 56%,
            #084681 100%
          );
          box-shadow: 0 0 5px 0 #0c1c47;
          border-radius: 4.5px;
          span{
            margin-top: 5px;
          }
        }
      }
    }

    .wrj {
      display: flex;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
        background-image: linear-gradient(
          179deg,
          #1773b6 0%,
          #3484c3 36%,
          #2a7abd 56%,
          #084681 100%
        );
        box-shadow: 0 0 5px 0 #0c1c47;
        border-radius: 4.5px;
        span{
          margin-top: 5px;
        }
      }
    }
  }
}
// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  background: rgba(9, 32, 87, 0.7) !important;
  border: 1px solid #70daf9 !important;
}

.zdfx {
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .fleSpeed {
    margin-top: 16px;
    color: #fff;
    display: flex;
    white-space: nowrap;
    align-items: center;
    /deep/.el-input__inner {
      background: transparent;
      color: #fff;
    }
  }
  .red {
    margin-top: 8px;
    color: red;
  }
  .btn {
    margin-top: 16px !important;
  }
}
.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}
.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}
.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}
.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}
.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}
/deep/ .el-dialog__header {
  margin-left: 0;
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}
/deep/ .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}
</style>
