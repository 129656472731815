<template>
  <div class="nest_controlBox">
    <PositionBall class="pa left-31" :uav-data="uavData" :wsShow="wsShow" />
    <ymfz :channelData="data"></ymfz>
    <img class="img2" src="@/assets/img/ball2.png" />
    <img class="img3" src="@/assets/img/ball2.png" />
    <div class="w929 h78 nest_control">
      <InfoDJ class="w550 ml95 h78" v-if="wsShow" :uav-data="uavData" :wsShow="wsShow"></InfoDJ>
      <Info :offline="offline" class="w630 ml100 h78" v-else :uav-data="uavData"></Info>
      <div class="line mr1" v-if="!wsShow"></div>
      <battery class="" :uav-battery="uavBattery" :wsShow="wsShow"></battery>
      <div class="bizhang">
      <obstacle :uav-data="uavData" v-if="wsShow"></obstacle>
    </div>
    </div>

    
  </div>
</template>
<script>
import PositionBall from "./components/ball/index";
import Info from "./components/info/index";
import InfoDJ from "./components/infoDJ/index";
import battery from "./components/battery/index";
import ymfz from "./components/ymfz/index";
import obstacle from "./components/obstacle";

export default {
  data() {
    return {};
  },
  props: {
    wsShow: {
      type: Boolean,
      default: () => {
        false;
      },
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    uavBattery: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    offline: {
      type: Boolean,
      default: () => {
        true;
      },
    },
  },
  components: { PositionBall, Info, battery, ymfz, InfoDJ, obstacle },
  computed: {
    props_obj() {
      let { data } = this;
    },
  },
};
</script>
<style scoped lang="scss">
.nest_controlBox {
  width: 860px;
  height: 200px;
  position: absolute;
  display: flex;
  justify-content: start;
  justify-content: center;
  align-items: center;
  left: calc(53% - 425px);
  bottom: -40px;
  z-index: 13;
  pointer-events: none;
  .nest_control {
    display: flex;
    background: rgba(9, 32, 87, 0.7);
    .line {
      width: 0px;
      height: 75px;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(112, 218, 249, 0),
          rgba(112, 218, 249, 1),
          rgba(112, 218, 249, 0)
        )
        2 2;
    }
  }
  .bizhang {
    box-sizing: border-box;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: -70px;
    top: 14px;
  }
  .img2 {
    position: absolute;
    left: -36px;
    top: calc(35%);
    width: 8px;
    height: 9px;
    z-index: 9999;
    transform: rotate(-75deg);
  }
  .img3 {
    position: absolute;
    left: -40px;
    top: calc(34%);
    width: 8px;
    height: 9px;
    z-index: 9999;
    transform: rotate(115deg);
  }
}
</style>