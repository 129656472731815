import * as mountCtrlList from '@/components/observe/mount/utils.js';

let lastMountCtrl = null;
export default {
  data(){
    return {
      wsJoystick : null,
      control_speed: 3, //控制速度
      mountCtrlList,
      lastDirection: 'stop',  //最后一次操控方向
      lastMount: null,
      uavModel:false,//无人机遥感连接状态，
      iStatusValue:null,
      Mdata:{}
    }
  },
  computed: {
    selectMount(){
      /* if(!this.lastMount){
        this.lastMount = this.$store.state.fckernel.selectMount;
      }
      return this.lastMount; */
      return this.$store.state.fckernel.selectMount;
    },
    mountCtrl(){
      return this.mountCtrlList[this.selectMount?.name];
    }
  },
  inject: [],
  watch: {
  },
  beforeDestroy() {
    this.closeJoystick();
  },
  methods: {
    /**
     * 初始化操纵杆连接
     */
    initJoystick(device){
      this.wsJoystick  && this.wsJoystick.close();
      if (device) {
        let topic = `${device.type}/OBTAIN/${device.deviceHardId}`;
        this.wsJoystick = new WebSocket("ws://127.0.0.1:8802");
        this.wsJoystick.onopen = () => {
          console.log('src/components/observe/fckernel/nest/control/controlMenu/mixins/joystick.js 连接上 ws://127.0.0.1:8802')
          this.wsJoystick.send(
            JSON.stringify({
              type: 2003,
              data: {
                mqttAddr: process.env.VUE_APP_FCMQ_URL,
                mqttIport: 1883,
                mqttTopic: topic,
                vehicleType: device.type,
              },
            })
          );
          this.uavModel = true
          this.unlockType = false;

        };
        setTimeout(() => {
          this.wsJoystick.send(JSON.stringify({ type: 2001, data: {} }));
        }, 2000);
          this.wsJoystick.onmessage = (e) => {
            let reader = new FileReader();
            let _tihs = this;
            reader.onload = (event) => {
              let obj = JSON.parse(event.currentTarget.result)
              if (obj.type == 518) {
                this.iStatusValue = obj.data?.iStatusValue
              }
              let data = JSON.parse(event.currentTarget.result).data;
              window.$log('src/components/observe/fckernel/uav/control/controlMenu/mixins/joystick.js message', data)
              if(data.channel03){
                let y = data.channel03 //油门值 0-1000
                // console.log(data.channel03,data,"yyyyyyyy");
                if(y || y == 0){
                    this.$store.commit("device/SET_YG_VAlUE", y);
                  }
                  // 判断是否在摇杆中位
                  if(y>=470&&y<=530){
                    this.$store.commit("device/SET_YG_IS_CENTER_FLAG", true);
                  }else{
                    this.$store.commit("device/SET_YG_IS_CENTER_FLAG", false);
                  }
              }
              if (data && this.wsShow) {
                const yaw = data.channel01//偏航-1000-1000
                const x = data.channel02//俯仰-1000-1000
                const y = data.channel03 //油门值 0-1000
                const z = data.channel04 //横滚 -1000-1000
                this.Mdata = {
                  yaw: yaw,
                  x: x,
                  y: y,
                  z: z
                }
              }
  
              //飞行模式
              // if (data.channel08) {
              //   let str = null
              //   if (data.channel08 == 1) {
              //     //定点
              //     str = "POSITION"
              //   } else if (data.channel08 == 2) {
              //     //航线
              //     str = "AUTO_MISSION"
              //   } else if (data.channel08 == 3) {
              //     //返航
              //     str = "AUTO_RTL"
              //   } else if (data.channel08 == 4) {
              //     //保持
              //     str = "HOLD"
              //   }
              //   this.mqtt_fn({
              //     type: 513,
              //     data: str
              //   }, 'wrj', this.device.deviceList[0].deviceHardId)
              // }
              //拍照
              // if (data.channel06) {
              //   let buffer = this.mountCtrl.take_photo()
              //   this.send_mqtt(buffer)
              // }
              //录像
              // if (data.channel05) {
              //   let buffer = null
              //   if (data.channel05 == 1) {
              //     buffer = this.mountCtrl.record(true)
              //   } else if (data.channel05 == 2) {
              //     buffer = this.mountCtrl.record(false)
              //   }
              //   this.send_mqtt(buffer)
              // }
              //云台模式
              /* if (data.channel07) {
                let buffer = null
                if (data.channel07 == 1) {
                  buffer = this.mountCtrl?.gimbal_mode_ctrl(0)
                } else if (data.channel07 == 2) {
                  buffer = this.mountCtrl?.gimbal_mode_ctrl(2)
                }
                this.send_mqtt(buffer)
              } else {
                let buffer = this.mountCtrl?.gimbal_mode_ctrl(1)
                this.send_mqtt(buffer)
              } */
              //变焦
              if (data.channel09) {
                let buffer = null
                if (data.channel09 == 1) {
                  buffer = this.mountCtrl.zoom(2)
                } else if (data.channel09 == 2) {
                  buffer = this.mountCtrl.zoom(0)
                }
              }
              //channel10 云台俯仰 0：没按 1：上 2：下
              if(data.channel10){
                const direction = {
                  1: 'up',
                  2: 'down'
                }
  
                const ctr = direction[data.channel10];
                console.log('摇杆按下了channel10', ctr);
                this.cameraMountCtr(ctr)
              }
              //channel11 云台偏航 0：没按 1：左 2：右
              if(data.channel11){
                const direction = {
                  1: 'left',
                  2: 'right'
                }
  
                const ctr = direction[data.channel11];
                console.log('摇杆按下了channel11', ctr);
                this.cameraMountCtr(ctr)
              }
  
              if(data.channel10 == 0 && data.channel11 == 0){
                this.cameraMountCtr('stop')
              }
            };
            reader.readAsText(e.data);
          };
      }
    },
    /**
     * 关闭操纵杆
     */
    closeJoystick(){
      if(!this.wsJoystick){
        return;
      }
      this.cameraMountCtr('stop', this.lastMountCtrl);
      this.wsJoystick.send(JSON.stringify({ type: 2010, data: {} }));
      this.wsJoystick && this.wsJoystick.close()
      this.wsJoystick = null;
    },
    /**
     * 相机挂载控制
     * @param {String} type up: 上，left：打，right：右，down：下
     */
    cameraMountCtr(type) {
      // this.device.deviceList[0].deviceHardId
      /* if(type !== 'stop'){
        console.log('cameraMountCtr', !this.wsJoystick, !this.mountCtrl || !type, this.lastDirection === type)
        console.log('this.mountCtrl', this.mountCtrl, type)
      } */

      if(!this.wsJoystick ){
        return;
      }

      if(!this.mountCtrl || !type){
        return;
      }

      if(this.lastDirection === type){
        return;
      }
      
      
      let buffer = null;
      switch (type) {
        case "stop":
          if(['up', 'down'].includes(this.lastDirection)){
            buffer = this.mountCtrl.gimbal_pitch_ctrl(0, this.control_speed);
          }else if(['left', 'right'].includes(this.lastDirection)){
            buffer = this.mountCtrl.gimbal_yaw_ctrl(0, this.control_speed);
          }
          // buffer = this.mountCtrl.gimbal_pitch_ctrl(0, this.control_speed);
          // buffer = this.mountCtrl.gimbal_yaw_ctrl(0, this.control_speed);
          break;
        case "up":
          buffer = this.mountCtrl.gimbal_pitch_ctrl(1, this.control_speed);
          break;
        case "left":
          buffer = this.mountCtrl.gimbal_yaw_ctrl(-1, this.control_speed);
          break;
        case "right":
          buffer = this.mountCtrl.gimbal_yaw_ctrl(1, this.control_speed);
          break;
        case "down":
          buffer = this.mountCtrl.gimbal_pitch_ctrl(-1, this.control_speed);
          break;
      }

      this.lastDirection = type;
      /* this.fn({
        type: 514,
        data: {
            mountId: this.selectMount.mountId,
            payload: buffer,
        },
      }, 'wrj') */
      this.send_mqtt(buffer)
    },
    send_ws() {
      if(this.Mdata && this.Mdata.y){
        this.$emit("fun", {
          type: 200,
          systemCode: "mmc",
          state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
          username: this.user_info.username,
          data: {
            cmdFunction: 2120,
            cmdChannel: this.Mdata
          },
          deviceHardId: this.device.deviceList[0].deviceHardId
        })
      }
    },
    send_mqtt(buffer) {
      this.mqtt_fn({
        type: 514,
        data: {
          mountId: this.selectMount.mountId,
          payload: buffer,
        },
      }, 'wrj', this.device.deviceList[0].deviceHardId)
    }
  }
}