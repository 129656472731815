<template>
  <div>
    <FloatPlayer
      ref="player"
      @directive="
        (data) => {
          $emit('directive', data);
        }
      "
      @fun="
        (data) => {
          $emit('fun', data);
        }
      "
      :device="device"
      @close="$emit('close')"
      :type="dataList.type || 3"
      :data="dataList"
    />
    <el-select
      v-if="!$store.state.mapmanage.mapisStatus.flag"
      class="video_type mr24"
      v-model="streamSelect"
      placeholder="切换源"
    >
      <el-option
        v-for="item in streamOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-tooltip class="cp pa right40 cf ai" content="AI识别" placement="bottom">
      <div @click="aiVisible = !aiVisible">
        <img src="@/assets/newImage/car.png" />
      </div>
    </el-tooltip>
    <div class="faceAndcar cp pa right130 cf" v-if="aiVisible">
      <el-tooltip
        v-for="(item, index) in aiIdentifyList"
        :key="index"
        :content="item.title"
        placement="bottom"
      >
        <div
          class="cp pa cf"
          :class="item.class"
          @click="aiIdentifyType(item.aiType)"
        >
          <img class="img_src" :src="item.Img" />
        </div>
      </el-tooltip>
      <!-- <el-tooltip content="人脸识别" placement="bottom">
        <div class="plan" @click="handle('a')">
          <img src="~@/assets/images/observe/faceAI2.png" />
        </div>
      </el-tooltip>
      <el-tooltip content="车牌识别" placement="bottom">
        <div class="plan" @click="handle('b')">
          <img src="~@/assets/images/observe/carAI2.png" />
        </div>
      </el-tooltip>
      <el-tooltip content="人流识别" placement="bottom">
        <div class="plan" @click="frame()">
          <img src="~@/assets/images/observe/trafficAI.png" />
        </div>
      </el-tooltip> -->
    </div>
    <el-tooltip content="拍照" placement="bottom">
      <div class="cp pa right40 cf plate" @click="photojz">
        <img src="@/assets/newImage/ai.png" />
      </div>
    </el-tooltip>
    <FaceAI v-if="faceAiShow" :uavId="pid" @closeface="faceAiShow = false" />
    <CarAI
      v-if="carAiShow"
      :uavId="device.deviceList[0].deviceHardId"
      :list="carList"
      @closecar="carAiShow = false"
    />
    <Traffic
      v-if="trafficShow"
      :trafficData="trafficData"
      @close="trafficShow = false"
    />
  </div>
</template>
<script>
import API from "@/api";

import FaceAI from "@/components/observe/uav/faceai";
import CarAI from "@/components/observe/uav/carai";
import Traffic from "@/components/observe/uav/traffic";
import FloatPlayer from "@/components/common/float-player";
import Bus from "@/assets/ligature.js";
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";
import axios from "axios";
import fkutils from "@/pages/observe/fckernel/utils";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    nxFlagData: {
      type: Object,
      default: () => null,
    },
    uavData: {
      type: Object,
      default: () => {},
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    lineLableName: {
      type: String,
      default: () => "",
    },
    scheduleData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    taskId() {
      return this.$store.state.fckernel.taskId;
    },
  },
  data() {
    return {
      aiIdentifyList: [
        {
          title: "河边垂钓",
          aiType: 12,
          class: "right26 top40",
          Img: require("@/assets/newImage/河边垂钓.svg"),
        },
        {
          title: "人员河边玩耍",
          aiType: 9,
          class: "right-11",
          Img: require("@/assets/newImage/人员河边玩耍.svg"),
        },
        {
          title: "岸线垃圾",
          aiType: 7,
          class: "right-48 top40",
          Img: require("@/assets/newImage/岸线垃圾.svg"),
        },
        {
          title: "河道漂浮物",
          aiType: 6,
          class: "right-11 top40",
          Img: require("@/assets/newImage/河道漂浮物.svg"),
        },
        {
          title: "船只识别",
          aiType: 5,
          class: "right26",
          Img: require("@/assets/newImage/船只识别.svg"),
        },
        {
          title: "游泳检测",
          aiType: 4,
          class: " right-48",
          Img: require("@/assets/newImage/游泳检测.svg"),
        },
      ],
      dataList: {},
      type: 4,
      carList: [], //车牌识别结果
      aiVisible: false, //打开ai
      pid: null,
      faceAiShow: false,
      carAiShow: false,
      trafficShow: false,
      trafficData: {},
      AISetInterval: null,
      aiLable: "",
      aiFlag: false,
      timerList: [],
      timers: [],
      streamOptions: [], //视频源
      streamSelect: "", //当前视频源
    };
  },
  watch: {
    streamSelect(val) {
      console.log(val, "val");
      this.getVideoFn(this.$store.state.fckernel.yc_video_status);
    },
    streamOptions(val) {
      this.streamSelect = val[0].value;
    },
    scheduleData(val) {
      console.log(val, "scheduleData");
      if (val.progress == 1) {
        // this.detectionSwim()
      } else if (val.progress == 2 && val.isFinish == true) {
        clearInterval(this.AISetInterval);
      }
    },
    "$store.state.fckernel.yc_video_status": {
      handler(newVal, oldVal) {
        this.getvideoType();
        this.getVideoFn(newVal);
      },
      deep: true,
    },
    lineLableName(val) {
      console.log(val, "lineLableNamelineLableName");
      // this.detectionSwim(val);
      this.aiFlag = true;
      this.aiLable = val;
    },
    data: {
      handler(newVal, oldVal) {
        console.log("===========================================");
        this.getvideoType();
        this.getVideoFn(this.$store.state.fckernel.yc_video_status);
      },
      deep: true,
      immediate: true,
    },
    uavData: {
      handler(val) {
        // console.log(val,"uavData");
        if (this.aiFlag && val.gps.relativeAlt > 1) {
          clearInterval(this.AISetInterval);
          this.detectionSwim(this.aiLable);
        }
      },
    },
  },
  computed: {
    nest_nx_status() {
      return this.$store.state.fckernel.nest_nx_status;
    },
  },
  components: { FloatPlayer, FaceAI, CarAI, Traffic },
  mounted() {
    setTimeout(() => {
      let streamData = {
        data: {
          messageID: 1058,
          data: {},
        },
        type: 528,
      };
      this.$emit("fn", streamData, "wrj");
    }, 2000);
    //视屏截图
    Bus.$on("take_photo", ({ callback }) => {
      console.log("take_photo on");
      let video = {};
      let scale = 1;
      video = document.getElementsByTagName("video")[0]; //
      video.useCORS = true; // 解决跨域
      video.crossOrigin = "Anonymous"; // 这两个参数必填 解决跨域
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      var img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // 将video中的数据绘制到canvas里
      // const image = canvas.toDataURL("image/png");
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      callback && callback(blob);
    });
  },
  methods: {
    ...fkutils,
    async aiIdentifyType(aiType) {
      const data = {
        deviceHardId: this.data.list[0].deviceHardId,
        mqttType: false,
        aiType,
      };
      this.aiIdentify(data, true);
    },
    async getvideoType() {
      let lable = this.$store.state.fckernel.yc_video_status;
      let deviceHardId = this.data.list[0].deviceHardId;
      // console.log(deviceHardId, "deviceHardId");
      if (deviceHardId) {
        let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({
          deviceHardId: deviceHardId,
        });
        if (lable == "公网") {
          this.streamOptions = [];
          if (data.list.public?.qingLiuUrl) {
            this.streamOptions.push({
              value: "QingLiu",
              label: "原画",
            });
            // this.streamSelect = "QingLiu";
          }

          //WS-FLV
          if (data.list.public?.socketUrl) {
            this.streamOptions.push({
              value: "hlsUrl",
              label: "流畅",
            });
          }

          //WebRtc
          if (data.list.public?.webUrl) {
            this.streamOptions.push({
              value: "WebRtc",
              label: "低延迟",
            });
          }
        } else {
          this.streamOptions = [];
          if (data.list.private?.qingLiuUrl) {
            this.streamOptions.push({
              value: "QingLiu",
              label: "原画",
            });
            // this.streamSelect = "QingLiu";
          }

          //WS-FLV
          if (data.list.private?.socketUrl) {
            this.streamOptions.push({
              value: "hlsUrl",
              label: "流畅",
            });
          }

          //WebRtc
          if (data.list.private?.webUrl) {
            this.streamOptions.push({
              value: "WebRtc",
              label: "低延迟",
            });
          }
        }
      }
    },
    async getVideoFn() {
      this.dataList = JSON.parse(JSON.stringify(this.data));
      let lable = this.$store.state.fckernel.yc_video_status;
      // console.log(lable, "lable");
      let deviceHardId = this.data.list[0].deviceHardId;
      // console.log(deviceHardId, "deviceHardId");
      if (deviceHardId) {
        let data = await API.FCKERNEL.GetDeviceStreamAddressByDeviceId({
          deviceHardId: deviceHardId,
        });
        if (data.list) {
          this.dataList.videos = {
            osd: data.osd,
          };
          if (lable == "公网") {
            let qingLiuUrl = data.list.public.qingLiuUrl;
            let webUrl = data.list.public.webUrl;
            let hlsUrl = data.list.public.socketUrl;
            if (data.list.public && this.streamSelect == "QingLiu") {
              this.dataList.vUrl = qingLiuUrl;
              this.dataList.type = 4;
              // this.$store.commit("fckernel/SET_STREAM", "QingLiu");
            } else if (data.list.public && this.streamSelect == "WebRtc") {
              this.dataList.vUrl = webUrl;
              this.dataList.type = 3;
              // this.$store.commit("fckernel/SET_STREAM", "liveNVR");
            } else {
              this.dataList.vUrl = hlsUrl;
              this.dataList.type = 3;
              // this.$store.commit("fckernel/SET_STREAM", "liveNVR");
            }
          } else {
            let qingLiuUrl = data.list.private.qingLiuUrl;
            let webUrl = data.list.private.webUrl;
            let hlsUrl = data.list.private.socketUrl;
            if (data.list.public && this.streamSelect == "QingLiu") {
              this.dataList.vUrl = qingLiuUrl;
              this.dataList.type = 4;
              // this.$store.commit("fckernel/SET_STREAM", "QingLiu");
            } else if (data.list.public && this.streamSelect == "WebRtc") {
              this.dataList.vUrl = webUrl;
              this.dataList.type = 3;
              // this.$store.commit("fckernel/SET_STREAM", "liveNVR");
            } else {
              this.dataList.vUrl = hlsUrl;
              this.dataList.type = 3;
              // this.$store.commit("fckernel/SET_STREAM", "liveNVR");
            }
          }
        }
        console.log(this.dataList.vUrl, "this.dataList.vUrl");
        this.$forceUpdate();
      }
    },
    //ai识别
    detectionSwim(val) {
      if (val.includes("河道")) {
        this.AISetInterval = setInterval(() => {
          this.swim();
        }, 2000);
      } else if (val.includes("人群")) {
        this.AISetInterval = setInterval(() => {
          // this.setIntervalFrame();
        }, 1000);
      } else {
        clearInterval(this.AISetInterval);
      }
    },
    async photojz() {
      if (!this.nest_nx_status)
        return this.$message.error("拍照失败，板载通信异常！");
      setTimeout(() => {
        let val = "";
        // QingliuFlag
        if (this.nxFlagData?.RtmpFlag) {
          val = "livenvr";
        } else {
          val = "QingLiu";
        }
        this.photoNX(this.device.deviceList[0].deviceId, val, 1);
      }, 1000);
    },
    async photo() {
      this.photoSelf(
        this.device.deviceList[0].deviceId,
        this.device.deviceList[0].deviceHardId
      );
    },
    async setIntervalFrame() {
      let blob = this.screenShot();
      let fd = new FormData();
      fd.append("uploadFiles", blob, `下载.jpeg`);
      let res = await API.MAPMANAGE.fileUpload(fd);
      let data = {
        // imageUrl:"http://32.128.6.52:9000/uploads/2023/05/26/2f183488-ad62-41c2-bb89-736ade5d8f36.png",
        imageUrl: res[0].storage,
        deviceHardId: this.device.deviceHardId,
      };
      let res2 = await API.FCKERNEL.crowdDensity(data);
      if (res2.total && res2.total > 19) {
        clearInterval(this.AISetInterval);
        this.AISetInterval = null;
        this.$emit("fn", { data: "POSITION", type: 513 }, "wrj");
        this.$emit("AIDialog", true, "当前区域人流聚集");
      }
    },
    //人流密度
    async frame() {
      this.flow(this.device.deviceList[0].deviceHardId);
    },
    async swim() {
      this.swimIdentify(this.device.deviceList[0].deviceHardId, 1);
    },
    // 人脸车牌识别
    handle(command) {
      this.getcanvasFaceCar(
        command,
        this.device.deviceList[0].deviceHardId,
        this.device.deviceList[0].deviceId
      );
      // this.getcanvas(command);
    },
    screenShot() {
      console.log(this.$refs.player.$refs["live-nvr"]);
      return this.$refs.player.screenShot();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cpt-common-float-player {
    bottom: -10px;
  }
}

.ai {
  z-index: 99;
  bottom: 110px;
}
.plate {
  bottom: 75px;
  z-index: 99;
}
.faceAndcar {
  z-index: 10;
  width: 55px;
  bottom: 145px;
  display: flex;
  justify-content: space-between;
  .plan {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0 5px;
    background: rgba(9, 32, 87, 0.7);
  }
}
.full-screen-video {
  .ai {
    z-index: 10;
    bottom: 40%;
  }
  .plate {
    bottom: 35%;
    z-index: 10;
  }
  .faceAndcar {
    bottom: 43%;
  }
}
.img_src {
  background: rgba(9, 32, 87, 0.7);
  // width: 35px;
  // height: 35px;
  width: 22px;
  height: 22px;
  padding: 5px;
}
.el-select {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 90%;
  z-index: 100;
  /deep/input {
    height: 30px;
  }
}
</style>
