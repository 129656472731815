

export default {
    client: null,
    client_yg:null,
    decoder: null,
    encoder: null,
    
    Type:{
        PX4:'PX4/RECEIVE/',
        APM:'APM/PUBLISH/'
    }
}
