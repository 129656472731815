import { nanoid } from "nanoid"
import { message } from "@/components/message"

export default {
    init(nest) {
        if (nest) {
            let { VUE_APP_NEST_WS_URL } = process.env
            let ws = this.ws.instance = new WebSocket(VUE_APP_NEST_WS_URL)
            ws.onopen = () => {

                // 登录
                this.ws_send({
                    type: 100,
                    code: nest.code,
                    ["login-type"]: 0,
                })

                // 获取温度
                this.ws_send({
                    type: 200,
                    cmdControlType: 100,
                });

                // 获取视频地址
                this.ws_send({
                    type: 200,
                    code: nest.code,
                    cmdControlType: 99,
                });
            }

            ws.onmessage = e => {
                let data = JSON.parse(e.data)
                console.log(data,'data');
                // 机巢数据
                if (data.type === 600 && data.datatype === 2) {
                    console.log( data.data,' this.nest.data');
                    this.nest.data = data.data
                }

                // 鹰巢温度信息
                if (data.type === 600 && data.datatype === 3) {
                    this.nest.weather = data.data
                }

                // 文本信息
                if (data.type === 600 && data.datatype === 4) {
                    this.nest.msg_list.unshift({
                        ...data.data,
                        uuid: nanoid()
                    })
                }

                // 无人机数据
                if (data.type === 600 && data.datatype === 258) {
                    this.uav.data = data?.data
                    this.uav.status = data.status
                    if (data.status === 1) {
                        // 收集无人机上线上报的数据
                        let location = data.data.locationCoordinate3D
                        let attitude = data.data.attitude

                        if (location && attitude) {
                            let position = Cesium.Cartesian3.fromDegrees(
                                location.longitude * 1,
                                location.latitude * 1,
                                location.altitude * 1
                            );

                            let yaw = Cesium.Math.toRadians(attitude.yaw + 90),
                                pitch = Cesium.Math.toRadians(attitude.pitch),
                                roll = Cesium.Math.toRadians(attitude.roll),
                                hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
                                orientation = Cesium.Transforms.headingPitchRollQuaternion(
                                    position,
                                    hpr
                                );

                            this.uav.position = position
                            this.uav.orientation = orientation
                            this.uav.positions.push(position)
                        }
                    }
                }
                // 无人机电池数据
                if (data.type === 600 && data.datatype === 257) {
                    this.$set(this.uav, "battery", data.data)
                }

                // // 视频数据
                // if (data.type === 200 && data.cmdControlType === 99) {
                //     this.nest.video = data.data
                // }

                // 信息弹窗
                //  || data.msgnum === 4101 邵勇说 4101 不弹窗
                if (data.type === 400 && (data.msgnum === 4112 || data.msgnum === 4150 || data.msgnum === 4148)) {

                    if (data.msgnum === 4148) {
                        message({
                            message: "该机巢已被其他控制端使用！",
                            duration: 2000,
                            type: "warning"
                        })
                    }

                    else if (data.level && data.message) {
                        message({
                            message: data.message,
                            duration: 2000,
                            type: data.level
                        })
                    }
                }

                // 挂载数据
                if (data.type === 600 && data.datatype === 259) {
                    // console.log("挂载数据:", data);
                    let index = this.uav.mounts.findIndex(item => item.gimbalName === data.data.gimbalName)
                    if (index === -1) {
                        this.uav.mounts.push(data.data)
                    }
                    this.$set(this.uav.mounts_payload, data.data.gimbalName, data.data.payload)
                }

                // 航线上传进度
                if (data.type === 600 && data.datatype === 264) {
                    this.airway.upload = data.data

                    if (data.data.uploadedWaypointIndex == data.data.totalWaypointCount) {
                        this.$el_message("航线上传成功")
                    }
                }

                // 无人机故障信息
                if (data.type === 269) {
                    console.log("datadatadatadata:", data);
                }
            }
        } else {
            message({
                message: "请先勾选要操作的鹰巢",
                duration: 2000,
                type: "warning"
            })
        }
    },

    send(data, taskCode) {
        if (this.ws.instance) {
            if (taskCode) {
                let nest = this.nest.selected
                if (nest) {
                    this.ws.instance.send(JSON.stringify({
                        ...data,
                        code: nest.code
                    }))
                }
            } else {
                this.ws.instance.send(JSON.stringify(data))
            }
        }
    },

    destroy() {
        if (this.ws.instance) {
            this.ws.instance.close()
            this.ws.instance = null
            this.nest_init_params()
            this.uav_init_params()
        }
       
    }

}