<template>
  <div class="livenvr pr" :class="className">
    <!-- <div class="pa top0 livenvr_title" v-show="infoflag" style="z-index:999">{{deviceName}}</div> -->
    <!-- <video style="width:100%;height:100%" controls ref="video" src="./1.mp4"></video> -->
    <div class="timeStr"  ref="timeStr" :class="infoflag? 'timeStrStyle':''" v-if="data.list.length > 0" v-show="infoflag">{{timeStr || ""}}</div>
    <LivePlayer
      :dblclick-fullscreen="false"
      aspect="fulllscreen"
      :class="{
        small: !videoFlag,
        'livenvr-player': videoFlag,
        vUrl: isStatus && !videoFlag,
        vUrlDeffault: !isStatus && !videoFlag,
      }"
      ref="livePlayer"
      :videoUrl="data.vUrl"
    />

    <LivePlayer
      v-if="fpvUrl.vUrl"
      :dblclick-fullscreen="false"
      :class="{
        vUrl: isStatus,
        vUrlDeffault: !isStatus,
        small: videoFlag,
        'livenvr-player': !videoFlag,
      }"
      ref="fpvPlayer"
      :controls="false"
      :videoUrl="fpvUrl.vUrl"
    />
    <!-- <div class="wsDataleft" ref="wsDataleft" :class="infoflag? 'leftStyle':''" v-if="data.list.length > 0" v-show="infoflag">
          <div>经度：{{wsData.longitude || 0}}</div>
          <div>纬度：{{wsData.latitude || 0}}</div>
          <div>高度：{{wsData.height ? wsData.height.toFixed(2) : 0}}</div>
          <div>方向：{{wsData.yaw ? wsData.yaw.toFixed(2) : 0}}</div>
        </div> -->
        <div class="wsDataright" ref="wsDataright" :class="infoflag? 'rightStyle':''" v-if="data.list.length > 0"  v-show="infoflag">
          <div>{{org.ername || ""}}</div>
          <div v-if="wsData.sanname">{{org.sanname || ""}}</div>
          <div class="name">{{ data.deviceName }}</div>
        </div>
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
import { newDate } from "@/utils/formatDate";
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";

export default {
  props: {
    deviceName: String,
    data: {
      type: Object,
      default: () => ({}),
    },
    infoflag: Boolean,

    fpvUrl: {
      type: Object,
      default: () => ({}),
    },
    isStatus: {
      type: Boolean,
      default: () => false,
    },
    className: String,

    videoFlag: {
      type: Boolean,
      default: () => true,
    },
    wsDataInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: { LivePlayer },
  data() {
    return {
      org:"",
      wsData:"",
      timer:null,
      timeStr:""
    };
  },
  watch: {
    wsDataInfo: {
      handler(val) {
        this.wsData = JSON.parse(JSON.stringify(val));
        if (
          this.wsData.anotherName &&
          this.wsData.anotherName.indexOf("-") > -1
        ) {
          let arr = this.wsData.anotherName.split("-");
          this.wsData.ername = arr[0];
          this.wsData.sanname = arr[1];
        } else {
          this.wsData.ername = this.wsData.anotherName;
        }
        if (this.wsData.anotherName) {
          this.orgflag = true;
        }
      },
    },
    fpvUrl: {
      handler(value, oldVal) {
        if (value.vUrl == oldVal?.vUrl) {
          return;
        }
        if (value && value.vUrl) {
          this.$nextTick(() => {
            this.$refs["fpvPlayer"].play();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    data: {
      handler(value, oldVal) {
        if (value.vUrl == oldVal?.vUrl) {
          return;
        }
        // console.log('livenvr', value)
        if (value && value.vUrl) {
          this.$nextTick(() => {
            this.$refs["livePlayer"].play();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timeStr = newDate()
    }, 1000);
    window.player = this;
    this.initws()
  },
  methods: {
    initws(){
      this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM);
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      this.ws.onopen = () => {
        this.ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid,
          })
        );
      };

      this.ws.onmessage = (e) => {
        let metadata = JSON.parse(e.data);
        if(metadata.msgnum == 4700 && metadata.type == 500){
          if(this.data.list[0].deviceHardId == metadata.data.deviceHardId){
            this.wsData = metadata.data
            if(this.wsData.anotherName && this.wsData.anotherName.indexOf("-") > -1){
              let arr = this.wsData.anotherName.split("-")
              this.wsData.ername = arr[0]
              this.wsData.sanname = arr[1]
            }else{
              this.wsData.ername = this.wsData.anotherName
            }
            if(this.wsData.anotherName){
            this.orgflag = true
            }
          }
        }
      };
    },
    init(flag) {
      let dom = document.querySelector("." + this.className);
      //  开启全屏
      if (flag) {
        dom.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    screenShot() {
      let video = {};
      let scale = 1;
      // video = this.$refs.video;
      video = this.$refs.livePlayer.$el.querySelector("video");
      if (!video) {
        return null;
      }
      video.useCORS = true; // 解决跨域
      video.crossOrigin = "Anonymous"; // 这两个参数必填 解决跨域
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      var img = document.createElement("img");
      img.src = canvas.toDataURL("image/png");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // 将video中的数据绘制到canvas里
      // const image = canvas.toDataURL("image/png");
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      return blob;
    },
    getFontSize(){
      // 获取需要改变字体大小的元素
      let timeStr = this.$refs.timeStr
      let live = this.$refs.live
      let wsDataleft = null
      if(this.data.device_type != 1){
         wsDataleft = this.$refs.wsDataleft
      }
      let wsDataright = this.$refs.wsDataright


      // 根据窗口宽度设置字体大小
        var fontSize = Math.floor(live.clientWidth / 40); // 根据需要进行调整
        
        // 应用新的字体大小
        timeStr.style.fontSize = fontSize + "px";
        if(wsDataleft){
          wsDataleft.style.fontSize = fontSize + "px";
        wsDataleft.style["-webkit-transform"] = "scale(0.8)"

        }
        wsDataright.style.fontSize = fontSize + "px";
        timeStr.style["-webkit-transform"] = "scale(0.8)"
        wsDataright.style["-webkit-transform"] = "scale(0.8)"

      // 监听窗口大小变化事件
      window.addEventListener("resize", function() {
        // 根据窗口宽度设置字体大小
        var fontSize = Math.floor(live.clientWidth / 40); // 根据需要进行调整
        
        // 应用新的字体大小
        if(wsDataleft){
          wsDataleft.style.fontSize = fontSize + "px";
        wsDataleft.style["-webkit-transform"] = "scale(0.8)"
          
        }
        timeStr.style.fontSize = fontSize + "px";
        wsDataright.style.fontSize = fontSize + "px";
        timeStr.style["-webkit-transform"] = "scale(0.8)"
        wsDataright.style["-webkit-transform"] = "scale(0.8)"
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.livenvr::v-deep {
  .livenvr_title {
    color: #69ceff;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 1px;
    line-height: 26px;
    // width: 100%;
    padding:10px;
    text-align: center;
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
  }
  .livenvr-player {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
  }
  .small {
    width: 300px;
    height: 170px;
    position: absolute;
    right: 0;
  }
  .vjs-volume-panel,
  .vjs-playback-rate,
  .live-player-stretch-btn,
  .vjs-time-divider,
  .vjs-duration,
  .vjs-live-control {
    display: none !important;
  }
  @media all and (min-height: 1000px) {
    .vUrl {
      bottom: 7vh !important;
      right: 0px !important;
    }
  }
  .vUrl {
    bottom: 23vh;
    right: 10px;
  }

  .vUrlDeffault {
    bottom: 0;
  }

  /* 隐藏画中画按钮 */
  .video-js .vjs-picture-in-picture-control .vjs-icon-placeholder {
    display: none !important;
  }

  /* 隐藏全屏按钮 */
  .video-js .vjs-fullscreen-control {
    display: none !important;
  }

  /* 隐藏截图按钮 */
  .video-js .vjs-control-bar .vjs-icon-spinner {
    display: none !important;
  }
  .vjs-control-bar {
    display: none !important;
  }
}
.timeStr{
      position: absolute;
      top: 25px;
      right: 0px;
      font-size: 8px;
      font-family: MicrosoftYaHei;
      z-index: 99;
      color: #ffffff;
    }
.wsDataleft{
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 0;
    color: #fff;
    font-size: 8px;
  }
  .wsDataright{
    font-size: 8px;
    position: absolute;
    bottom: 0px;
    right: 0;
    // z-index: 99;
    color: #fff;
    text-align: right;
  }
  .timeStrStyle{
  top: 50px;
  font-size:38px;
}
.leftStyle{
    bottom: 80px;
    font-size: 38px;
    left: 10px;
  }
  .rightStyle{
    font-size: 38px;
    bottom: 80px;
    right: 10px;
    .name{
      padding: 20px 0;
      font-size: 38px;
    }
  }
</style>