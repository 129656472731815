<template>
  <div class="page-observe-fckernel">
    <!-- <Header v-model="page" v-if="page === 1" /> -->
    <Uav
      ref="uacRef"
      @getHealth="getHealth"
      @getSelf="getSelf"
      @getUavData="getUavData"
      @getBattery="getBattery"
      @getDistanceLine="getDistanceLine"
      @Lsdom="LsdomFn"
      @changeF="changeF"
      @healthWaringShow="healthWaring"
      v-if="page === 1"
    />
    <Nest
      ref="nestRef"
      @Lsdom="LsdomFn"
      @changeF="changeF"
      @healthWaringShow="healthWaring"
      :weather="weather"
      v-if="page === 2"
    />
    <Lsdom
      :LsdomVlue="LsdomVlue"
      v-show="LsdomFlag"
      @close="LsdomFlag = false"
      @isFly="isFly"
    ></Lsdom>
    <HealthLsdom
      :healthWaringList="healthWaringList"
      v-if="healthShow"
      @close="close"
    ></HealthLsdom>
    <!-- 平台起飞检查 -->
    <selfTest
      v-if="selfFlag"
      @closeFlag="selfFlag = false"
      @close="closeSelf"
      :getHealthData="getHealthData"
      :getBatteryData="getBatteryData"
      :getUavDataDevice="getUavDataDevice"
      :distanceLine="distanceLine"
    ></selfTest>
  </div>
</template>

<script>
import Header from "@/components/observe/fckernel/header";
import Uav from "./uav/index";
import Nest from "./nest/index";
import Mount from "@/components/PagerP1";
import Lsdom from "@/components/lsdomV2";
import HealthLsdom from "./healthLsdom";
import selfTest from "./selfTest";

export default {
  components: { Header, Uav, Nest, Mount, Lsdom, HealthLsdom, selfTest },
  data() {
    return {
      page: 1,
      weather: {},
      LsdomVlue: { title: "提示", waring: "" },
      LsdomFlag: false,
      healthWaringList: [],
      healthShow: false,
      getHealthData: {},
      getBatteryData: null,
      getUavDataDevice: null,
      distanceLine: null,
      selfFlag: false,
      executeId: 1,
    };
  },
  created() {
  },
  methods: {
    closeSelf() {
      this.selfFlag = false;
      if (this.executeId == 1) {
        this.$refs.uacRef.$refs.TaskListRef.handClickTask2();
      } else {
        this.$refs.uacRef.$refs.ControlMenu.start();
      }
    },
    close() {
      this.healthShow = false;
      this.$refs.uacRef.healthWaring = [];
      this.$refs.nestRef.healthWaring = [];
    },
    getDistanceLine(val) {
      // console.log(val,"getDistanceLine---eeeeeeee")
      this.distanceLine = val;
    },
    getSelf(val) {
      this.selfFlag = true;
      this.executeId = val;
    },
    getHealth(data) {
      this.getHealthData = data;
    },
    getBattery(val) {
      this.getBatteryData = val;
    },
    getUavData(val) {
      this.getUavDataDevice = val;
    },
    healthWaring(val, flag) {
      // console.log(val,flag,"valflag");
      this.healthWaringList = [...new Set(val)];
      this.healthShow = flag;
    },
    LsdomFn(value) {
      this.LsdomVlue = value;
      this.LsdomFlag = true;
    },
    isFly() {
      if (this.page == 1) {
        this.$refs.uacRef.$refs.TaskListRef.getTrue();
      } else if (this.page == 2) {
        this.$refs.nestRef.$refs.TaskListRef.getTrue();
      }
      this.LsdomFlag = false;
    },
    cesium_layer_change_mode(fn, modes) {
      // fn(modes.GLOBE);
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          118.380875,
          31.09155,
          80814.66412965539
        ),
        orientation: {
          heading: 6.1625038986350775,
          pitch: -1.1874810066391963,
          roll: 1.8829382497642655e-13,
        },
        time: 5,
      });
    },
    changeF(item) {
      if (this.page == 1) {
        this.page = 2;
      } else {
        this.page = 1;
      }
    },
  },
  mounted() {
    document.title = "飞控中心";
    let { page } = this.$route.query;
    console.log(page, "page");
    if (page == 2) {
      this.changeF();
      // this.page = page;
    }
  },
};
</script>
<style></style>
