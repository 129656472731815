<!-- 飞控中心无人机应slot -->
<template>
  <div>
    <div slot="nav__left" class="ml20 cp jz">
      <div class="h13 fr ml12 cf mr17 f14" v-if="wsList && wsList.length"></div>
    </div>
    <div class="h700 list">
      <template v-if="wsList && wsList.length">
        <!-- <div class="all">全部</div> -->
        <div class="pl10 pb30 pr8">
          <Item
            v-for="item in wsList"
            :key="item.id"
            :data="item"
            :level="1"
            @refresh="$emit('refresh')"
          />
        </div>
      </template>
    </div>
  </div>
  <!-- </Dialog> -->
</template>

<script>
import Dialog from "@/components/observe/fckernel/common/dialog";
import Item from "./item";
import UavSearch from "@/components/observe/search";
import API from "@/api";
export default {
  data() {
    return {
      videoFlag: false,
      components_start: true, //组件当前的状态
      wsList: [],
      num:0
    };
  },
  props: {
    containerStyle: {
      type: String | Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: { Dialog, Item, UavSearch },
  inject: ["findList"],
  watch: {
    list: {
      handler(val) {
        this.wsList = val;
      },
    },
  },

  methods: {
    searchFn(data) {
      //  state 1代表鹰巢
      this.findList(data);
    },
    change() {
      this.videoFlag = !this.videoFlag;
      this.$emit("videoChange", this.videoFlag);
    },
    init_ws() {
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid,
          })
        );
      };

      ws.onmessage = async (e) => {
        this.num++
        let _this = this
        if(this.num == 10){
          let metadata = JSON.parse(e.data);
          // console.log(metadata,"meadta----eeeeeee");
          if (metadata.msgnum == 4139 && metadata.type == 500) {
            // console.log(JSON.parse(e.data), "fckernel-----eeee");
            let lists = await API.DEVICE.List({
              cate: 1,
            });
  
            function each(tree, list) {
              // console.log(tree,"tree--eeeeee");
              tree.forEach((item) => {
                // console.log(item,"item-eeeee");
                // console.log(list,"eeeeee");
                list.forEach((item2) => {
                  if (item.id == item2.id) {
                    // item = item2;
                    item.collapse = item2.collapse;
                    // console.log(item,"item----eeeeeee");
                    if (
                      item.devices &&
                      item.devices.length &&
                      item2.devices &&
                      item2.devices.length
                    ) {
                      item.devices.forEach((device) => {
                        item2.devices.forEach((device2) => {
                          device = device2;
                          device._location = device2._location;
                          device._video = device2._video;
                          device._airway = device2._airway;
                          device._control = device2._control;
                          device.org_id = device2.org_id;
                        });
                      });
                    }
                    if (item.child && item.child.length) {
                       each(item.child, item2.child);
                    }
                  }
                });
                _this.wsList = [item]
                    // console.log(item,"eeeeee");
              });
            }
  
            if (lists && lists.length) {
              each(lists, this.wsList);
              // console.log(list, "listlist");
            }
            // if(metadata.data)
          }
          this.num = 0
        }
      };

      ws.onerror = (error) => {
        //console.log("error", error);
      };

      ws.onclose = (data) => {
        // console.log("onclose", data);
      };
    },
  },
  mounted() {
    this.wsList = this.list
    // this.init_ws();
  },
  provide() {
    return {
      fn: (...args) => this.$emit("fn", ...args),
    };
  },
};
</script>

<style lang="scss">
.list {
  overflow-y: auto;
}
.cpt-observe-uav_list {
  height: 100%;
  box-sizing: border-box;
}
.nestlist {
  background: rgba(4, 18, 50, 0.5);
}
.mountItem {
  background: rgba(25, 42, 61, 0.5);
  box-shadow: inset 0 0 38px 0 rgba(0, 219, 255, 0.71);
  border-radius: 10px;
  padding: 10px;
}
.wrjBtn {
  background: rgba(6, 23, 56, 0.6);
  border: 0 solid #00b6ff;
  box-shadow: 1px 1px 2px 0 rgba(3, 16, 50, 0.5), inset 0 0 12px 0 #00b6ff;
  border-radius: 3px;
}
.all {
  margin-top: 10px;
  text-indent: 1em;
  background-image: linear-gradient(
    270deg,
    rgba(12, 134, 242, 0) 0%,
    rgba(37, 151, 251, 0.62) 99%
  );
  width: 100px;
  color: #a6caeb;
  height: 20px;
  border-radius: 4px;
}
.jz {
  height: 100%;
  display: flex;
  align-items: center;
}
.mar {
  margin: 0 0 0 5px;
  color: #70daf9 !important;
  font-weight: 700 !important;
}
</style>