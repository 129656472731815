

export default {
    list: [],
    selected: null,
    data: null,
    weather: null,
    video: null,
    msg_list: [],
    Videoflag:false,
    data_list:[],
    flag:true,
    jcData:{},//机巢数据
    qxzData:{},//气象站数据
    controlMenuFlag:false,
    Model:""
    
}